import React, {useEffect} from 'react';
import fp from 'lodash/fp';
import {useTranslation} from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import {useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useReactRouter from 'use-react-router';
import {useSnackbar} from 'notistack';

import Select from '../../components/Select';
import BuildingModel from '../../model/building';

const ADD_GATEWAY = gql`
  mutation addGateway(
    $iccid: String
    $imei: String
    $serial_number: String!
    $buildingId: ID
    $manufacturer: String!
    $model: String
    $comment: String
    $organizationId: ID
    $file_prefix: String
  ) {
    addGateway(
      iccid: $iccid
      imei: $imei
      serial_number: $serial_number
      buildingId: $buildingId
      comment: $comment
      manufacturer: $manufacturer
      model: $model
      organizationId: $organizationId
      file_prefix: $file_prefix
    ) {
      id
    }
  }
`;

const UPDATE_GATEWAY = gql`
  mutation updateGateway(
    $id: ID!
    $iccid: String
    $imei: String
    $serial_number: String!
    $buildingId: ID
    $manufacturer: String!
    $model: String
    $comment: String
    $organizationId: ID
    $file_prefix: String
  ) {
    updateGateway(
      id: $id
      iccid: $iccid
      imei: $imei
      buildingId: $buildingId
      serial_number: $serial_number
      comment: $comment
      manufacturer: $manufacturer
      model: $model
      organizationId: $organizationId
      file_prefix: $file_prefix
    ) {
      id
      iccid
      serial_number
      manufacturer
      model
      comment
      last_reading
      organization {
        id
      }
      building {
        id
        street
        house_number
        city
        postalcode
      }
    }
  }
`;

const GatewayForm = ({
  useMeData,
  getBuildings,
  buildingsLoading,
  buildingsData,
  getGatewayData,
  organizationsData,
  isEditPage,
  gatewayId
}) => {
  const {t} = useTranslation(['gateway']);
  const gateway = getGatewayData ? getGatewayData.singleGateway : null;
  const {history, location} = useReactRouter();
  const searchParams = new URLSearchParams(location.search);
  const organizationIdParam = searchParams.get('organizationId');
  const {enqueueSnackbar} = useSnackbar();

  const [addGateway] = useMutation(ADD_GATEWAY, {
    onCompleted: (data) => {
      if (data?.addGateway) {
        history.push('/gateways');
      } else {
        enqueueSnackbar(t('gateway:Gateway with entered imei already exists'), {
          variant: 'error',
        });
      }
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar(t('gateway:Could not save the gateway'), {
        variant: 'error',
      });
    },
  });
  const [updateGateway] = useMutation(UPDATE_GATEWAY, {
    onCompleted: () => {
      history.push('/gateways');
    },
    onError: (err) => {
      console.log(err);
      enqueueSnackbar(t('gateway:Could not save the gateway'), {
        variant: 'error',
      });
    },
  });

  const defaultValues = {
    building: !!gateway && gateway.building ? gateway.building.id : '',
    iccid: !!gateway ? gateway.iccid : '',
    imei: !!gateway ? gateway.imei : '',
    manufacturer: !!gateway ? gateway.manufacturer : '',
    model: !!gateway ? gateway.model : 'default',
    comment: !!gateway ? gateway.comment : '',
    serial_number: !!gateway ? gateway.serial_number : '',
    organization:
      organizationIdParam || fp.get('organization.id', gateway) || '',
  };

  const {register, setValue, handleSubmit, errors, watch, reset} = useForm({
    defaultValues,
  });

  const {
    organization: organizationValue,
    building: buildingValue,
    manufacturer: manufacturerValue,
    model: modelValue,
  } = watch(['organization', 'building', 'manufacturer', 'model']);

  useEffect(() => {
    getBuildings({
      variables: {
        organizationId: defaultValues.organization,
      },
    });
  }, [defaultValues.organization, getBuildings]);

  const isAdmin = !!useMeData && useMeData.role === 3;
  const buildings =
    buildingsData && buildingsData.buildings
      ? buildingsData.buildings.map((building) => {
          return {
            label: BuildingModel.label(building),
            value: building.id,
          };
        })
      : [];

  const manufacturers = [
    {
      label: 'Engelmann',
      value: 'engelmann',
    },
    {
      label: 'WebdynEasy',
      value: 'webdyn',
    },
    {
      label: 'Generic',
      value: 'generic',
    },
    {
      label: 'Virtual',
      value: 'DSP',
    },
    {
      label: 'Virtual Qundis',
      value: 'DSP-Qundis',
    },
    {
      label: 'Virtual Engelmann',
      value: 'DSP-Engelmann',
    },
    {
      label: 'Brick4U',
      value: 'brick4u',
    },
  ];

  const models = [{
    label: 'default',
    value: 'default',
  }];

  if (!gateway && isEditPage) {
    return <div>{t('gateway:Gateway not found')}</div>;
  }

  const onBuildingChange = (option) => {
    setValue('building', option ? option.value : null);
  };

  const onManufacturerChange = (option) => {
    setValue('manufacturer', option ? option.value : null);
  };

  const onModelChange = (option) => {
    setValue('model', option ? option.value : null);
  }

  const onOrganizationChange = ({value}) => {
    if (value === organizationValue) {
      return;
    }

    if (value === defaultValues.organization) {
      setValue('building', defaultValues.building);
    } else {
      setValue('building', null);
    }

    setValue('organization', value);
    getBuildings({
      variables: {
        organizationId: value,
      },
    });
  };

  const onSubmit = async (data) => {
    if (isEditPage) {
      await updateGateway({
        variables: {
          id: gatewayId,
          buildingId: data.building ? data.building : null,
          iccid: fp.trim(data.iccid) || null,
          imei: fp.trim(data.imei) || null,
          serial_number: data.serial_number,
          manufacturer: data.manufacturer,
          model: data.model || null,
          comment: data.comment,
          organizationId: data.organization,
          file_prefix: data.file_prefix,
        },
      });
      return;
    }

    await addGateway({
      variables: {
        buildingId: data.building ? data.building : null,
        iccid: fp.trim(data.iccid) || null,
        imei: fp.trim(data.imei) || null,
        serial_number: data.serial_number,
        manufacturer: data.manufacturer,
        model: data.model,
        comment: data.comment,
        organizationId: data.organization,
        file_prefix: data.file_prefix,
      },
    });
  };

  const organizations = organizationsData.organizations.map((organization) => {
    return {
      label: organization.name,
      value: organization.id,
    };
  });

  register({name: 'building', type: 'custom'});
  register(
    {name: 'manufacturer', type: 'custom'},
    {required: t('gateway:Manufacturer is required')},
  );
  register({ name: 'model', type: 'custom' });
  if (isAdmin) {
    register(
      {name: 'organization', type: 'custom'},
      {required: t('gateway:Organization is required')},
    );
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      {isAdmin && (
        <Select
          inputId="organization-id"
          placeholder={t('gateway:Choose an organization')}
          options={organizations}
          onChange={onOrganizationChange}
          defaultValue={
            organizations.length > 0 && defaultValues.organization
              ? organizations.find(
                  (organization) =>
                    organization.value === defaultValues.organization,
                )
              : null
          }
          value={fp.find({value: organizationValue}, organizations)}
          TextFieldProps={{
            required: true,
            label: t('gateway:Organization'),
            margin: 'normal',
            name: 'organization',
          }}
        />
      )}
      {errors.organization && errors.organization.message}
      <Select
        isClearable
        inputId="building-id"
        placeholder={
          isAdmin && !organizationValue
            ? t('gateway:Choose an organization')
            : t('gateway:Select building')
        }
        options={buildings}
        value={
          buildingValue
            ? buildings.find((building) => building.value === buildingValue)
            : null
        }
        defaultValue={
          isEditPage && gateway.building
            ? buildings.find(
                (building) => building.value === gateway.building.id,
              )
            : null
        }
        onChange={onBuildingChange}
        isDisabled={buildingsLoading || (isAdmin && !organizationValue)}
        TextFieldProps={{
          disabled: buildingsLoading || (isAdmin && !organizationValue),
          label: t('gateway:Building'),
          margin: 'normal',
          name: 'building',
        }}
      />
      {errors.building && errors.building.message}
      <TextField
        fullWidth
        id="serial_number"
        name="serial_number"
        label={t('gateway:Serial Number')}
        margin="normal"
        defaultValue={isEditPage ? gateway.serial_number : null}
        inputRef={register}
      />
      {errors.serial_number && errors.serial_number.message}
      <TextField
        fullWidth
        id="imei"
        name="imei"
        label={t('gateway:IMEI')}
        margin="normal"
        defaultValue={isEditPage ? gateway.imei : null}
        inputRef={register}
      />
      {errors.imei && errors.imei.message}
      <TextField
        fullWidth
        id="iccid"
        name="iccid"
        label={t('gateway:ICCID')}
        margin="normal"
        defaultValue={isEditPage ? gateway.iccid : null}
        inputRef={register}
      />
      {errors.iccid && errors.iccid.message}
      <Select
        isClearable
        inputId="manufacturer-id"
        placeholder={t('gateway:Select manufacturer')}
        options={manufacturers}
        value={
          manufacturerValue
            ? manufacturers.find(
                (manufacturer) => manufacturer.value === manufacturerValue,
              )
            : null
        }
        defaultValue={
          isEditPage && gateway.manufacturer
            ? manufacturers.find(
                (manufacturer) => manufacturer.value === gateway.manufacturer,
              )
            : null
        }
        onChange={onManufacturerChange}
        TextFieldProps={{
          required: true,
          label: t('gateway:Manufacturer'),
          margin: 'normal',
          name: 'manufacturer',
        }}
      />
      {errors.manufacturer && errors.manufacturer.message}
      <Select
        isClearable
        inputId="model"
        placeholder={t('gateway:Select model')}
        options={models}
        value={modelValue
          ? models.find(
            (model) => model.value === modelValue,
          )
          : null}
        defaultValue={isEditPage && gateway.model
          ? models.find(
            (model) => model.value === gateway.model,
          )
          : null}
        onChange={onModelChange}
        TextFieldProps={{
          label: t('gateway:Model'),
          margin: 'normal',
          name: 'model',
        }}
      />
      {errors.model && errors.model.message}
      <TextField
        fullWidth
        id="comment"
        name="comment"
        label={t('gateway:Comment')}
        margin="normal"
        defaultValue={isEditPage ? gateway.comment : null}
        inputRef={register}
      />
      <TextField
        fullWidth
        id="file_prefix"
        name="file_prefix"
        label={t('gateway:File prefix')}
        margin="normal"
        defaultValue={isEditPage ? gateway.file_prefix : null}
        inputRef={register}
      />
      {errors.comment && errors.comment.message}
      <Button type="submit" variant="contained" color="primary">
        {t('gateway:Save')}
      </Button>
    </form>
  );	
}

GatewayForm.propTypes = {
  useMeData: PropTypes.object,
  getBuildings: PropTypes.func,
  buildingsLoading: PropTypes.bool,
  buildingsData: PropTypes.object,
  getGatewayData: PropTypes.object,
  organizationsData: PropTypes.object,
  isEditPage: PropTypes.bool,
  gatewayId: PropTypes.string
};

export default GatewayForm;