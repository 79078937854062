// @format
import React from 'react';
import useLocalStorage from 'react-use-localstorage';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export default function Settings() {
  const classes = useStyles();
  const [language, setLanguage] = useLocalStorage('language', 'en');
  const {t, i18n} = useTranslation('settings');

  function handleChangeLanguage(event) {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
    window.location.reload();
  }

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel id="language">{t('settings:Language')}</InputLabel>
        <Select onChange={handleChangeLanguage} value={language}>
          <MenuItem value="en">
            <span role="img" aria-label="English">
              🇬🇧
            </span>
            English
          </MenuItem>
          <MenuItem value="de">
            <span role="img" aria-label="Deutsch">
              🇩🇪
            </span>
            Deutsch
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
