import React from 'react';
import fp from 'lodash/fp';
import {useTranslation} from 'react-i18next';
import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import {UserInfoForm, UserApartmentForm} from './User';

const GET_CUSTOMER = gql`
  query getCustomers($id: ID!) {
    customers(ids: [$id]) {
      id
      email
      role
      profile {
        id
        first_name
        last_name
        phone
        street1
        street2
        postal_code
        city
      }
      organization {
        id
        name
      }
      apartmentPermission {
        id
        start_date
        end_date
        apartment {
          id
          location
          external_number
          building {
            id
            street
            house_number
            city
            postalcode
          }
        }
      }
    }
  }
`;

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $id: ID!
    $email: String!
    $password: String
    $firstName: String
    $lastName: String
    $phone: String
    $street1: String
    $street2: String
    $postal_code: String
    $city: String
  ) {
    updateCustomer(
      id: $id
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      street1: $street1
      street2: $street2
      postal_code: $postal_code
      city: $city
    ) {
      id
      email
      role
      organization {
        id
        name
      }
      profile {
        id
        first_name
        last_name
        phone
      }
    }
  }
`;

const Content = ({match}) => {
  const {t} = useTranslation(['user']);
  const {loading, error, data} = useQuery(GET_CUSTOMER, {
    variables: {
      id: match.params.id,
    },
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const user = data ? fp.first(data.customers) : null;
  const isLoading = loading;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>{t('user:Details')}</h2>
        <UserInfoForm isCustomerForm user={user} updateUser={updateCustomer} />
        <React.Fragment>
          <h2>{t('user:Apartment')}</h2>
          <UserApartmentForm user={user} />
        </React.Fragment>
      </Grid>
    </Grid>
  );
};

export default function Customer({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
