import React from 'react';
import fp from 'lodash/fp';
import {useTranslation} from 'react-i18next';

import Select from '../Select';
import UserModel from '../../model/user';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  managerColumn: {minWidth: '20rem'},
}));

const ManagerAssign = (props) => {
  const {t} = useTranslation(['uvis']);
  const managers = (props.managersData || {}).managers || [];

  const classes = useStyles();
  const rowData = props.rowData || {};
  let current = rowData.assignedManagerId;
  if (typeof current === 'undefined') {
    current = (
      (rowData.managers && rowData.managers.find((manager) => manager.id)) ||
      {}
    ).id;
  }

  const users = managers.map((user) => {
    return {
      label: UserModel.userLabel(user),
      value: user.id,
    };
  });
  return (
    <div className={classes.managerColumn}>
      <Select
        menuPlacement="top"
        inputId="user-id"
        options={users}
        onChange={(updated) => {
          props.onRowDataChange({
            ...rowData,
            assignedManagerId: (updated && updated.value) || false,
          });
        }}
        value={fp.find({value: current}, users)}
        TextFieldProps={{
          required: false,
          label: t('uvis:Manager'),
          margin: 'normal',
          name: 'user',
        }}
        isClearable={true}
      />
    </div>
  );
};

export default ManagerAssign;
