import React, {useState} from 'react';
// import moment from 'moment';
// import fp from 'lodash/fp';
import {useQuery, useMutation} from '@apollo/react-hooks';
import fp from 'lodash/fp';
import gql from 'graphql-tag';
import {useTranslation} from 'react-i18next';
import useReactRouter from 'use-react-router';
import {Route, Switch} from 'react-router-dom';
import {MTableToolbar} from 'material-table';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import HomeIcon from '@material-ui/icons/Home';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import ApartmentModel from '../model/apartment';
import ErrorStatus from '../components/ErrorStatus';
import Table from '../components/Table';
import Uvi from './Uvi';
import AnnualData from './UviBuilding/AnnualData';

const useStyles = makeStyles((theme) => ({
  dataBox: {
    margin: '0 0 1rem 0',
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
  tableToolbar: {
    flexWrap: 'wrap-reverse',
  },
}));

const GET_UVI_BUILDINGS = gql`
  query getUviBuildings($ids: [ID]) {
    uviBuildings(ids: $ids) {
      id
      street
      house_number
      city
      alternate_address
      postalcode
      external_id
      apartments {
        id
        external_name
        external_name2
        external_number
        location
        permissions(current: true) {
          id
        }
      }
    }
  }
`;

const PROCESS_UVI_REPORTS = gql`
  mutation processUviReports($buildingId: ID!, $date: DateTime) {
    processUviReports(buildingId: $buildingId, date: $date)
  }
`;

const Content = ({match}) => {
  const {loading, error, data} = useQuery(GET_UVI_BUILDINGS, {
    variables: {ids: [match.params.id]},
  });
  const [processUviReports, {loading: processLoading, error: processError}] =
    useMutation(PROCESS_UVI_REPORTS);
  const [reportsSent, setReportsSent] = useState(false);
  const {history} = useReactRouter();
  const classes = useStyles();
  const isLoading = loading;
  const building = isLoading ? {} : fp.first(data.uviBuildings);

  const {t} = useTranslation(['uvi', 'building']);
  if (error) {
    return <ErrorStatus />;
  }
  return (
    <React.Fragment>
      <div>
        <Alert severity="info">
          {t('uvis:USE_UVI_BUILDING_INFO')}
          <br />
          <b>{t('uvis:USE_UVI_BUILDING_INFO_IMPORTANT')}</b>
        </Alert>
      </div>
      <Box>
        <Table
          title={
            <div>
              <HomeIcon className={classes.titleIcon} />
              {building.street} {building.house_number}
            </div>
          }
          isLoading={isLoading}
          columns={[
            {
              title: t('building:ID'),
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            {title: t('building:Street'), editable: 'never', field: 'street'},
            {
              title: t('building:Number'),
              field: 'house_number',
            },
            {title: t('building:City'), editable: 'never', field: 'city'},
            {
              title: t('building:Zipcode'),
              field: 'postalcode',
            },
            {title: t('building:ID1'), editable: 'never', field: 'external_id'},
            {
              title: t('building:Alternate Address'),
              field: 'alternate_address',
            },
          ]}
          data={loading ? [] : data.uviBuildings}
          options={{
            sorting: false,
            search: false,
            toolbar: true,
            paging: false,
          }}
          components={{
            Toolbar: (props) => (
              <div className={classes.toolbarWrapper}>
                <MTableToolbar
                  {...props}
                  classes={{root: classes.tableToolbar}}
                />
              </div>
            ),
          }}
        />
      </Box>
      <Box>
        {building.id && <Table
          title={
            <div>
              <MeetingRoomIcon className={classes.titleIcon} />
              {t('building:Apartments')}
            </div>
          }
          tableId={`uvi-building-${building.id}-apartments`}
          columns={[
            {
              title: t('building:ID'),
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            {title: t('building:External Number'), field: 'external_number'},
            {title: t('building:Name'), field: 'external_name'},
            {title: t('building:Name2'), field: 'external_name2'},
            {title: t('building:Location'), field: 'location'},
            {
              title: t('uvis:Number of UVI recipients'),
              render: (apartment) => {
                const c = ApartmentModel.countPermissions(apartment);
                if (!c) {
                  return <b>{c}</b>;
                }
                return c;
              },
              customSort: (apartment1, apartment2) =>
                ApartmentModel.countPermissions(apartment1) -
                ApartmentModel.countPermissions(apartment2),
              defaultSort: 'desc',
              editable: 'never',
            },
          ]}
          data={building.apartments}
          options={{
            sorting: true,
            search: true,
            toolbar: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25],
          }}
          onRowClick={(event, rowData) => {
            history.push(`${match.url}/${rowData.id}`);
          }}
        />}
      </Box>
      <div className={classes.dataBox}>
        <AnnualData buildingId={match.params.id} />
      </div>
      <div className={classes.dataBox}>
        {reportsSent && <Alert severity="success">{t('uvi:Scheduled')}</Alert>}
        {processError && <Alert severity="error">{t('uvi:Error')}</Alert>}
        {!reportsSent && !processError && (
          <Button
            variant="contained"
            color="primary"
            disabled={processLoading}
            onClick={async () => {
              await processUviReports({
                variables: {
                  buildingId: match.params.id,
                },
              });
              setReportsSent(true);
            }}>
            {t('uvi:Send reports')}
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

export default function UviBuilding({match}) {
  return (
    <Switch>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/:apartmentId`} component={Uvi} />
    </Switch>
  );
}
