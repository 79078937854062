import { Paper, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import { TabPanel, a11yProps } from '../components/TabPanel';
import SharedFtpConfigs from './SharedFtpConfigs';
import SharedMqttConfigs from './SharedMqttConfigs';

const TYPES = [
  {
    label: 'FTP',
    ConfigTable: SharedFtpConfigs,
  },
  {
    label: 'MQTT',
    ConfigTable: SharedMqttConfigs,
  },
]

const Content = () => {
  const [activeType, setActiveType] = useState(0);

  return (
    <>
      <Paper>
        <Tabs
          value={activeType}
          onChange={(_event, newValue) => {
            setActiveType(newValue);
          }}>
          {TYPES.map(({ label }, index) => (
            <Tab label={label} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </Paper>
      {TYPES.map(({ ConfigTable }, index) => (
        <TabPanel value={activeType} index={index} key={index}>
          <ConfigTable />
        </TabPanel>
      ))}
    </>
  );
};

export default function TransferConfigs({ match }) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
