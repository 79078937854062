import React from 'react';
import {useTranslation} from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BusinessIcon from '@material-ui/icons/Business';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DnsIcon from '@material-ui/icons/Dns';

import Link from '../../components/Link';

const Admin = () => {
  const {t} = useTranslation(['translations', 'layout']);

  return (
    <React.Fragment>
      <ListItem>
        <ListItemIcon>
          <React.Fragment />
        </ListItemIcon>
        <ListItemText secondary={t('layout:Administration')} />
      </ListItem>
      <Link to="/organizations">
        <ListItem button key={t('layout:/organizations')}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/organizations')} />
        </ListItem>
      </Link>
      <Link to="/users">
        <ListItem button key={t('layout:/users')}>
          <ListItemIcon>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/users')} />
        </ListItem>
      </Link>
      <Link to="/shared-transfer-configs">
        <ListItem button key={t('layout:/shared-transfer-configs')}>
          <ListItemIcon>
            <DnsIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/shared-transfer-configs')} />
        </ListItem>
      </Link>

    </React.Fragment>
  );
};
export default Admin;
