// @format
import React from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '../components/Table';
import {useMe} from '../reducers/me';

const GET_FTP_CONFIGS = gql`
  query getFtpConfigs($ids: [ID]) {
    ftpConfigs(ids: $ids) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

const ADD_FTP_CONFIG = gql`
  mutation addFtpConfig(
    $user: String!
    $password: String!
    $host: String!
    $port: Int!
    $setup: String!
    $organizationId: ID!
    $folder: String!
  ) {
    addFtpConfig(
      user: $user
      password: $password
      host: $host
      port: $port
      setup: $setup
      organizationId: $organizationId
      folder: $folder
    ) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

const UPDATE_FTP_CONFIG = gql`
  mutation updateFtpConfig(
    $id: ID!
    $user: String!
    $password: String
    $host: String!
    $port: Int!
    $setup: String!
    $organizationId: ID!
    $folder: String!
  ) {
    updateFtpConfig(
      id: $id
      user: $user
      password: $password
      host: $host
      port: $port
      setup: $setup
      organizationId: $organizationId
      folder: $folder
    ) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

const DELETE_FTP_CONFIG = gql`
  mutation deleteFtpConfig($id: ID!) {
    deleteFtpConfig(id: $id) {
      id
    }
  }
`;

const Content = ({match}) => {
  const {t} = useTranslation(['ftpConfig']);
  const [useMeData] = useMe();
  const {loading, error, data} = useQuery(GET_FTP_CONFIGS, {});
  const [updateFtpConfig] = useMutation(UPDATE_FTP_CONFIG);
  const [addFtpConfig] = useMutation(ADD_FTP_CONFIG, {
    update: (cache, {data: {addFtpConfig}}) => {
      const {ftpConfigs} = cache.readQuery({query: GET_FTP_CONFIGS});

      cache.writeQuery({
        query: GET_FTP_CONFIGS,
        data: {
          ftpConfigs: ftpConfigs.concat(addFtpConfig),
        },
      });
    },
  });
  const [deleteFtpConfig] = useMutation(DELETE_FTP_CONFIG, {
    update: (cache, {data: {deleteFtpConfig}}) => {
      const {ftpConfigs} = cache.readQuery({query: GET_FTP_CONFIGS});

      cache.writeQuery({
        query: GET_FTP_CONFIGS,
        data: {
          ftpConfigs: ftpConfigs.filter(
            (ftpConfig) => ftpConfig.id !== deleteFtpConfig.id,
          ),
        },
      });
    },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('ftpConfig:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {title: t('ftpConfig:User'), field: 'user'},
    {
      title: t('ftpConfig:Password'),
      field: 'password',
      render: () => '*************',
    },
    {title: t('ftpConfig:Host'), field: 'host'},
    {title: t('ftpConfig:Port'), field: 'port'},
    {
      title: t('ftpConfig:Setup'),
      field: 'setup',
      lookup: {
        engelmann: 'engelmann',
        webdyn: 'webdyn',
        DSP: 'DSP',
        'DSP-Qundis': 'DSP-Qundis',
        'DSP-Engelmann': 'DSP-Engelmann',
      },
    },
    {title: t('ftpConfig:Folder'), field: 'folder'},
  ];

  return (
    <React.Fragment>
      <Table
        tableId="ftpConfigs"
        title=""
        columns={columns}
        data={data.ftpConfigs}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addFtpConfig({
              variables: {
                user: newData.user,
                password: newData.password,
                host: newData.host,
                port: Number(newData.port),
                setup: newData.setup,
                folder: newData.folder || '',
                organizationId: useMeData.organizationId,
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            await updateFtpConfig({
              variables: {
                id: oldData.id,
                user: newData.user,
                host: newData.host,
                port: Number(newData.port),
                setup: newData.setup,
                folder: newData.folder || '',
                organizationId: useMeData.organizationId,
                ...(!!newData.password && {password: newData.password}),
              },
            });
          },
          onRowDelete: async (oldData) => {
            await deleteFtpConfig({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default function FtpConfigs({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
