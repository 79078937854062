const REPEAT_VALUE_YEARLY = 'yearly';
const REPEAT_VALUE_MONTHLY = 'monthly';
const REPEAT_VALUE_DAILY = 'daily';
const REPEAT_VALUE_QUARTER_HOURLY = 'quarter_hourly';
const REPEAT_VALUE_TWICE_MONTHLY = 'twice_monthly';

const EXPORT_FREQUENCY_MONTHLY = 1;
const EXPORT_FREQUENCY_DAILY = 2;
const EXPORT_FREQUENCY_QUARTER_HOURLY = 3;
const EXPORT_FREQUENCY_TWICE_MONTHLY = 4;
const EXPORT_FREQUENCY_YEARLY = 5;

const SENSOR_COMPLETION_GT_80 = '>80%';
const SERIAL_COMPLETION_GT_80 = '>80%';

export default {
  withBuildingData: (report) => {
    if (!report.building) {
      return {
        ...report,
        building_address: '',
        building_id1: '',
      };
    }

    const {
      building: { street, house_number, external_id },
    } = report;

    return {
      ...report,
      building_address: `${street} ${house_number}`,
      building_id1: external_id,
    };
  },
  STATUS_ACTIVE: [0, 1, 5, 10, 20],
  STATUS_DELETED: [30],
  REPEAT_VALUE_YEARLY,
  REPEAT_VALUE_TO_LABEL_KEY: {
    [REPEAT_VALUE_YEARLY]: 'report:Yearly',
    [REPEAT_VALUE_MONTHLY]: 'report:Monthly',
  },
  REPEAT_VALUE_TO_LABEL_KEY_UNSAFE: {
    [REPEAT_VALUE_TWICE_MONTHLY]: 'report:Twice Monthly',
    [REPEAT_VALUE_DAILY]: 'report:Daily',
    [REPEAT_VALUE_QUARTER_HOURLY]: 'report:Quarter-hourly',
  },
  EXPORT_FREQUENCY_TO_REPORT_REPEAT: {
    [EXPORT_FREQUENCY_YEARLY]: REPEAT_VALUE_YEARLY,
    [EXPORT_FREQUENCY_MONTHLY]: REPEAT_VALUE_MONTHLY,
    [EXPORT_FREQUENCY_DAILY]: REPEAT_VALUE_DAILY,
    [EXPORT_FREQUENCY_QUARTER_HOURLY]: REPEAT_VALUE_QUARTER_HOURLY,
    [EXPORT_FREQUENCY_TWICE_MONTHLY]: REPEAT_VALUE_TWICE_MONTHLY,
  },
  SENSOR_COMPLETION_GT_80,
  SENSOR_COMPLETION_FILTERS: [
    ['less than 80%', 'Red'],
    [SENSOR_COMPLETION_GT_80, 'Yellow'],
    ['>90%', 'Green'],
  ],
  SERIAL_COMPLETION_GT_80,
  SERIAL_COMPLETION_FILTERS: [
    ['less than 50%', 'Red'],
    [SERIAL_COMPLETION_GT_80, 'Yellow'],
    ['>90%', 'Green'],
  ],
};
