import React from 'react';
import fetch from 'unfetch';
import {useTranslation} from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import useForm from 'react-hook-form';
import useReactRouter from 'use-react-router';
import {Link} from 'react-router-dom';

import logo from '../assets/smarterbuilding_logo_web.png';
import {useMe, useMeQuery} from '../reducers/me';
import UserModel from '../model/user';
import config from '../config';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: 300,
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const onSubmit = (t, setError, clearError, refetchMe) => async (data) => {
  const response = await fetch(`${config.apiUri}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    credentials: 'include',
  });

  if (!response.ok) {
    const {code, errors} = await response.json();

    if (errors) {
      errors.forEach((error) => {
        setError(error.param, 'loginError', error.msg);
      });
      return;
    }

    if (code === '0001') {
      setError('email', 'loginError', t('errors:Invalid e-mail'));
    } else if (code === '0002') {
      setError('password', 'loginError', t('errors:Invalid password'));
    }
    return;
  }

  clearError(['email', 'password']);
  await refetchMe();
};

const PATH_DEFAULT = '/dashboard';
const PATH_DEFAULT_MANAGER = '/uvis';

export default function Login() {
  const [, meDispatch] = useMe();
  const {loading, data, refetch} = useMeQuery(meDispatch);
  const classes = useStyles();
  const {t} = useTranslation(['errors', 'login']);
  const {register, handleSubmit, errors, setError, clearError} = useForm();
  const {history} = useReactRouter();

  if (loading) {
    return <LinearProgress />;
  }

  if (data && data.me && data.me.email) {
    if (UserModel.roleUser(data.me.role)) {
      if (window.location.pathname !== PATH_DEFAULT) {
        history.push(PATH_DEFAULT);
      }
      return null;
    }
    if (UserModel.roleManager(data.me.role)) {
      if (window.location.pathname !== PATH_DEFAULT_MANAGER) {
        history.push(PATH_DEFAULT_MANAGER);
      }
      return null;
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box mb={1}>
          <img className={classes.logo} src={logo} alt="Logo" />
        </Box>
        <Typography component="h1" variant="h5">
          {t('login:Admin')}
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit(t, setError, clearError, refetch))}>
          <TextField
            required
            fullWidth
            autoFocus
            variant="outlined"
            margin="normal"
            id="email"
            label={t('login:E-mail Address')}
            name="email"
            autoComplete="email"
            inputRef={register({required: t("login:E-mail can't be blank")})}
          />
          {errors.email && errors.email.message}
          <TextField
            required
            fullWidth
            variant="outlined"
            margin="normal"
            name="password"
            label={t('login:Password')}
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={register({
              required: t("login:Password can't be blank"),
              minLength: {
                value: 6,
                message: t('login:Password must have more than 6 characters'),
              },
            })}
          />
          {errors.password && errors.password.message}
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}>
            {t('login:Log in')}
          </Button>
          {errors.login}
          <Link className={classes.link} to='/password_recovery'>Forgot password</Link>
        </form>
      </div>
    </Container>
  );
}
