import React, {useRef, useState} from 'react';
import moment from 'moment';
import fp from 'lodash/fp';
import {useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import useReactRouter from 'use-react-router';
import {MTableToolbar} from 'material-table';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ExpandableSnackback from '../../components/ExpandableSnackbar';
import Table from '../../components/Table';
import config from '../../config';
import GatewayModel from '../../model/gateway';
import { renderBuildingAddress } from '../Gateways/GatewaysInOperation';
import data from '../../lib/data';

const useStyles = makeStyles((theme) => ({
  tableToolbarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tableToolbar: {
    flexGrow: 1,
  },
  newItemButtonWrapper: {
    flex: 1,
  },
}));

const BUILDING_SEARCH = gql`
  mutation buildingSearch(
    $query: SearchQuery!
    $deleted: Boolean
  ) {
    buildingSearch(
      query: $query
      deleted: $deleted
    ) {
      rows {
        id
        street
        house_number
        address
        city
        alternate_address
        postalcode
        external_id
        billing_end_date
        sensors_count
        meeters_count
        serials_count
        updated_at
        latest_report {
          id
        }
        data_source_count
      }
      count
    }
  }
`

const RESTORE_BUILDING = gql`
  mutation restoreBuilding($buildingId: ID!) {
    restoreBuilding(buildingId: $buildingId)
  }
`;

const BuildingsTable = ({match, deleted, upload}) => {
  const {t} = useTranslation(['building']);
  const {enqueueSnackbar} = useSnackbar();
  const [buildingSearch] = useMutation(BUILDING_SEARCH);
  const [restoreBuilding, {loading: restoreLoading}] = useMutation(
    RESTORE_BUILDING,
    {},
  );
  const {history} = useReactRouter();
  const classes = useStyles();
  const [isSendingFile, setSendingFile] = useState(false);
  const isLoading = isSendingFile || restoreLoading;
  const tableRef = useRef();
  const refetch = () => tableRef.current && tableRef.current.onQueryChange();

  const actions = [];
  let onClick = false;
  if (deleted) {
    actions.push((rowData) => ({
      icon: RestoreFromTrashIcon,
      tooltip: 'Restore',
      isFreeAction: false,
      onClick: async () => {
        await restoreBuilding({
          variables: {
            buildingId: rowData.id,
          },
        });
        refetch();
      },
    }));
  } else {
    actions.push((rowData) => ({
      icon: AssignmentIcon,
      tooltip: 'Report',
      isFreeAction: false,
      onClick: () => {
        history.push(`/reports/${rowData.latest_report.id}`);
      },
      hidden: !fp.get('latest_report.id', rowData),
    }));
    onClick = (event, rowData) => {
      history.push(`${match.path}/${rowData.id}`);
    };
  }

  const onFileChange = async ({target}) => {
    if (target.value.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append('file', target.files[0]);

    setSendingFile(true);
    const response = await fetch(`${config.apiUri}/import-routeml`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    setSendingFile(false);

    target.value = '';
    if (!response.ok) {
      const json = await response.json();

      if (fp.get('payload.duplicates', json)) {
        enqueueSnackbar(
          t('building:There are duplicate sensors in this file'),
          {
            variant: 'error',
            persist: true,
            content: (key, message) => (
              <ExpandableSnackback id={key} message={message}>
                <p>
                  <b>{t('building:Apartment guid')}:</b>{' '}
                  {json.payload.apartment}
                </p>
                <p>
                  <b>{t('building:Duplicate sensors guid')}:</b>
                </p>
                <ul>
                  {json.payload.duplicates.map((guid) => (
                    <li key={guid}>{guid}</li>
                  ))}
                </ul>
              </ExpandableSnackback>
            ),
          },
        );
        return;
      }

      enqueueSnackbar(t('building:Could not upload the file'), {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar(t('building:File uploaded successfully'), {
      variant: 'success',
    });
    refetch();
  };

  const columns = [
    { title: t('building:ID1'), field: 'external_id' },
    {
      title: t('building:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
    },
    {
      title: t('building:Address'),
      field: 'address',
      association: 'BuildingStats',
    },
    {title: t('building:City'), field: 'city'},
    {title: t('building:Zipcode'), field: 'postalcode'},
    {
      title: t('building:Alternate Address'),
      field: 'alternate_address',
    },
    {
      title: t('building:Update Date'),
      field: 'updated_at',
      render: (rowData) => moment.utc(rowData.updated_at).format('LL'),
    },
    {
      title: t('building:Set Date'),
      field: 'billing_end_date',
      type: 'datetime',
      render: (rowData) => {
        return fp.get('billing_end_date', rowData)
          ? moment.utc(rowData.billing_end_date).format('MMMM YYYY')
          : '';
      },
    },
    {
      title: t('building:Sensors Count'),
      field: 'sensors_count',
      association: 'BuildingStats',
    },
    {
      title: t('building:Meeters Count'),
      field: 'meeters_count',
      association: 'BuildingStats',
    },
    {
      title: t('building:Serials Count'),
      field: 'serials_count',
      association: 'BuildingStats',
    },
    {
      title: t('building:Data Source Count'),
      field: 'data_source_count',
      render: (rowData) => (
        <Link
          to={`/gateways?${GatewayModel.buildingAddress}=${renderBuildingAddress({ building: rowData })}`}
          onClick={(e) => e.stopPropagation()}
        >
          {rowData.data_source_count}
        </Link>
      ),
      association: 'OrganizationBuildingStats',
    },
  ];
  const columnFieldToColumnMap = columns.reduce(
    (result, column) => ({ ...result, [column.field]: column }),
    {},
  );
  const [pageSize, setPageSize] = useState(10);
  return (
    <Table
      tableId="buildings"
      tableRef={tableRef}
      title=""
      isLoading={isLoading}
      columns={columns}
      data={data(
        columnFieldToColumnMap,
        buildingSearch,
        (response) => response.data.buildingSearch,
        setPageSize,
        [],
        false
        ,
        {deleted}
        
      )}
      options={{
        sorting: true,
        pageSize,
        searchAutoFocus: true,
        columnsButton: true,
        compressed: true,
      }}
      actions={actions}
      onRowClick={onClick}
      components={{
        Toolbar: (props) => (
          <div className={classes.tableToolbarWrapper}>
            {upload && (
              <Box ml={2} className={classes.newItemButtonWrapper}>
                <input
                  hidden
                  type="file"
                  accept="text/xml, .routeml, .xml, application/xml"
                  id="routeml-upload-input"
                  onChange={onFileChange}
                />
                <label htmlFor="routeml-upload-input">
                  <Button component="span" color="primary" variant="contained">
                    {t('building:Upload Routeml')}
                  </Button>
                </label>
              </Box>
            )}
            <MTableToolbar {...props} classes={{root: classes.tableToolbar}} />
          </div>
        ),
      }}
    />
  );
};

BuildingsTable.propTypes = {
  match: PropTypes.objectOf({
    path: PropTypes.string.isRequired,
  }).isRequired,
  deleted: PropTypes.bool.isRequired,
  upload: PropTypes.bool.isRequired,
};

export default BuildingsTable;
