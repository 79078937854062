import React from 'react';
import {useTranslation} from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventIcon from '@material-ui/icons/Event';

import Link from '../../components/Link';

const Manager = () => {
  const {t} = useTranslation(['translations', 'layout']);

  return (
    <React.Fragment>
      <Link to="/uvis">
        <ListItem button key={t('layout:/uvis')}>
          <ListItemIcon>
            <EventIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/uvis')} />
        </ListItem>
      </Link>
    </React.Fragment>
  );
};
export default Manager;
