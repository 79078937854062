// @format
import React from 'react';
import { Link } from 'react-router-dom';
import MaterialButton from '@material-ui/core/Button';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core';
import fp from 'lodash/fp';
import { Box, Popover } from '@material-ui/core';

import CommentsTable from '../../components/Report/CommentsTable';
import Alert from '@material-ui/lab/Alert/Alert';

const GET_REPORT = gql`
  query getReport($id: ID!) {
    reports(ids: [$id]) {
      id
      building {
        id
        external_id
        street
        house_number
      }
      comments {
        report_id
        apartment {
          id
          location
          external_name
          external_number
          external_name2
        }
        sensor {
          id
          external_number
          external_id
          serial_number
          apartment {
            id
            location
            external_name
            external_number
            external_name2
          }
        }
        comment
        comment_operator
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative'
  },
  link: {
    border: "none",
    background: "none",
    cursor: "pointer",
    color: '#3075BB',
    textDecoration: 'underline',
  },
}));

export default function CommentsPreview({ reportId, commentsCount }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [getReport, { loading, error, data }] = useLazyQuery(GET_REPORT);
  const styles = useStyles();
  const pullData = (state) => {
    if (!data && !loading && !error) {
      getReport({
        variables: {
          id: reportId,
        },
      });
    }
    return !state;
  }
  const anchorRef = React.useRef()
  const [anchorEl, setAnchorEl] = React.useState()
  React.useEffect(() => {
    setTimeout(() => setAnchorEl(anchorRef?.current), 1)
  }, [anchorRef])

  return (
    <div className={styles.container} ref={anchorRef}>
      <button className={styles.link} onClick={() => setIsOpen(pullData)}>{commentsCount}</button>
      {anchorEl && <Popover
        anchorEl={anchorEl}
        id={reportId}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setIsOpen(false);
        }}>
        <Box p={2}>
          {error && <Alert severity='error'>{error.message}</Alert>}
          <CommentsTable
            comments={fp.get('reports.0.comments', data)}
            building={fp.get('reports.0.building', data)}
            isLoading={loading}
            reportId={reportId}
          />
        </Box>
      </Popover>
      }
    </div >
  );
}

