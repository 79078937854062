import { useMutation, useQuery } from '@apollo/react-hooks';
import LinearProgress from '@material-ui/core/LinearProgress';
import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../components/Table';

const GET_MQTT_CONFIGS = gql`
  query getMqttConfigs {
    mqttConfigs {
      id
      user
      host
      port
    }
  }
`;

const ADD_MQTT_CONFIG = gql`
  mutation addMqttConfig(
    $user: String!
    $password: String!
    $host: String!
    $port: Int!
  ) {
    addMqttConfig(
      user: $user
      password: $password
      host: $host
      port: $port
    ) {
      id
      user
      host
      port
    }
  }
`;

const UPDATE_MQTT_CONFIG = gql`
  mutation updateMqttConfig(
    $id: ID!
    $user: String!
    $password: String
    $host: String!
    $port: Int!
  ) {
    updateMqttConfig(
      id: $id
      user: $user
      password: $password
      host: $host
      port: $port
    ) {
      id
      user
      host
      port
    }
  }
`;

const DELETE_MQTT_CONFIG = gql`
  mutation deleteMqttConfig($id: ID!) {
    deleteMqttConfig(id: $id) {
      id
    }
  }
`;

export default function SharedMqttConfigs() {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_MQTT_CONFIGS);
  const [updateMqttConfig] = useMutation(UPDATE_MQTT_CONFIG);
  const [addMqttConfig] = useMutation(ADD_MQTT_CONFIG, {
    update: (cache, { data: { addMqttConfig } }) => {
      const { mqttConfigs } = cache.readQuery({ query: GET_MQTT_CONFIGS });

      cache.writeQuery({
        query: GET_MQTT_CONFIGS,
        data: {
          mqttConfigs: mqttConfigs.concat(addMqttConfig),
        },
      });
    },
  });
  const [deleteMqttConfig] = useMutation(DELETE_MQTT_CONFIG, {
    update: (cache, { data: { deleteMqttConfig } }) => {
      const { mqttConfigs } = cache.readQuery({ query: GET_MQTT_CONFIGS });

      cache.writeQuery({
        query: GET_MQTT_CONFIGS,
        data: {
          mqttConfigs: mqttConfigs.filter(
            (mqttConfig) => mqttConfig.id !== deleteMqttConfig.id,
          ),
        },
      });
    },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('ftpConfig:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    { title: t('ftpConfig:User'), field: 'user' },
    {
      title: t('ftpConfig:Password'),
      field: 'password',
      render: () => '*************',
    },
    { title: t('ftpConfig:Host'), field: 'host' },
    { title: t('ftpConfig:Port'), field: 'port' },
  ];

  return (
    <React.Fragment>
      <Table
        tableId="mqttConfigs"
        title=""
        columns={columns}
        data={data.mqttConfigs}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addMqttConfig({
              variables: {
                user: newData.user,
                password: newData.password,
                host: newData.host,
                port: Number(newData.port),
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            await updateMqttConfig({
              variables: {
                id: oldData.id,
                user: newData.user,
                ...(!!newData.password && { password: newData.password }),
                host: newData.host,
                port: Number(newData.port),
              },
            });
          },
          onRowDelete: async (oldData) => {
            await deleteMqttConfig({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
}
