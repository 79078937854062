import React, {useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import gql from 'graphql-tag';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MailIcon from '@material-ui/icons/Mail';
import Box from '@material-ui/core/Box';
import {useMutation, useQuery} from '@apollo/react-hooks';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import Table from '../../components/Table';
import ErrorStatus from '../../components/ErrorStatus';
import FileModel from '../../model/file';
import ReportPreview from './ReportPreview';

const useStyles = makeStyles((theme) => ({
  dataBox: {
    margin: '0 0 1rem 0',
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
  tableToolbar: {
    flexWrap: 'wrap-reverse',
  },
}));

const GET_UVIS = gql`
  query getUvis($apartmentIds: [ID!]!) {
    uvis(apartmentIds: $apartmentIds) {
      id
      date
      sent_at
      user {
        id
        email
        profile {
          id
          first_name
          last_name
          phone
          street1
          street2
          postal_code
          city
        }
      }
    }
  }
`;
const CREATE_UVI_REPORT = gql`
  mutation createUviReport($apartmentId: ID!, $date: DateTime!) {
    createUviReport(apartmentId: $apartmentId, date: $date) {
      id
      date
      sent_at
    }
  }
`;

const BUILD_UVI_REPORT = gql`
  mutation buildUviReport($id: ID!) {
    buildUviReport(id: $id)
  }
`;
const DELETE_UVI_REPORT = gql`
  mutation deleteUviReport($id: ID!) {
    deleteUviReport(id: $id)
  }
`;
const SEND_UVI_REPORT = gql`
  mutation sendUviReport($id: ID!) {
    sendUviReport(id: $id)
  }
`;

const Reports = ({isLoading, apartmentId}) => {
  const classes = useStyles();
  const [mutationLoading, setMutationLoading] = useState(false);
  const [createUviReport] = useMutation(CREATE_UVI_REPORT, {});
  const [buildUviReport] = useMutation(BUILD_UVI_REPORT, {});
  const [deleteUviReport] = useMutation(DELETE_UVI_REPORT, {});
  const [sendUviReport] = useMutation(SEND_UVI_REPORT, {});
  const {loading, error, data, refetch} = useQuery(GET_UVIS, {
    variables: {apartmentIds: [apartmentId]},
  });
  const {t} = useTranslation(['uvi', 'building', 'user']);
  const [report, setReport] = useState(null);
  if (error) {
    return <ErrorStatus />;
  }
  return (
    <React.Fragment>
      <Box>
        <Table
          title={
            <div>
              <EventIcon className={classes.titleIcon} />
              {t('uvis:Reports | UVIs')}
            </div>
          }
          tableId={'uvi-reports'}
          isLoading={isLoading || loading || mutationLoading}
          columns={[
            {
              title: t('uvis:Target Month'),
              field: 'date',
              type: 'date',
              render: (rowData) => {
                return fp.get('date', rowData)
                  ? moment.utc(rowData.date).format('MMMM YYYY')
                  : '';
              },
              defaultSort: 'desc',
              editable: 'onAdd',
            },
            {
              title: t('uvis:Sent at'),
              field: 'sent_at',
              type: 'datetime',
              render: (rowData) => {
                return fp.get('sent_at', rowData)
                  ? moment.utc(rowData.sent_at).format('DD MMMM YYYY, HH:mm')
                  : '';
              },
              editable: 'onUpdate',
            },
            {
              title: t('uvis:Email'),
              field: 'user.email',
              editable: 'never',
            },
            {
              title: t('user:Street 1'),
              field: 'user.profile.street1',
              editable: 'never',
            },
            {
              title: t('user:Street 2'),
              field: 'user.profile.street2',
              editable: 'never',
            },
            {
              title: t('user:ZIP code'),
              field: 'user.profile.postal_code',
              editable: 'never',
            },
            {
              title: t('user:City'),
              field: 'user.profile.city',
              editable: 'never',
            },
          ]}
          data={loading ? [] : data.uvis}
          options={{
            sorting: true,
            search: true,
            toolbar: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25],
          }}
          editable={{
            onRowAdd: async (newData) => {
              await createUviReport({
                variables: {
                  apartmentId,
                  date: newData.date,
                },
              });
              refetch();
            },
            onRowDelete: async (oldData) => {
              await deleteUviReport({
                variables: {
                  id: oldData.id,
                },
              });
              refetch();
            },
          }}
          actions={[
            (rowData) => ({
              icon: InsertDriveFileIcon,
              tooltip: t('uvis:Download Report'),
              isFreeAction: false,
              onClick: async () => {
                setMutationLoading(true);
                try {
                  const reportData = await buildUviReport({
                    variables: {id: rowData.id},
                  });
                  const fileData = reportData.data.buildUviReport;
                  FileModel.save(
                    fileData.file,
                    fileData.fileName,
                    fileData.mimeType,
                  );
                } catch (e) {
                  console.log(e);
                }
                setMutationLoading(false);
              },
              hidden: false,
            }),
            (rowData) => ({
              icon: fp.get('user.email', rowData)
                ? MailIcon
                : MarkunreadMailboxIcon,
              tooltip: fp.get('user.email', rowData)
                ? t('uvis:Send Report')
                : t('uvis:Mark as sent'),
              isFreeAction: false,
              onClick: async () => {
                setMutationLoading(true);
                try {
                  if (
                    !rowData.sent_at ||
                    window.confirm(
                      t('uvis:This report is already sent. Re-send it?'),
                    )
                  ) {
                    await sendUviReport({
                      variables: {id: rowData.id},
                    });
                  }
                } catch (e) {
                  console.log(e);
                }
                setMutationLoading(false);
                refetch();
              },
              hidden: false,
            }),
          ]}
        />
      </Box>

      <ReportPreview
        open={!!report}
        report={report || {}}
        onClose={() => {
          setReport(null);
          setMutationLoading(false);
        }}
      />
    </React.Fragment>
  );
};

Reports.propTypes = {
  isLoading: PropTypes.bool,
  apartmentId: PropTypes.string.isRequired,
};

export default Reports;
