const RATE_NAMES = [];
const RATE_TOTAL_FIELDS = [];
const RATE_UNITS_FIELDS = [];

const RATES = [
  {
    key: 'fixed_price',
    name: 'Fixed Price',
    description: '',
    defaultValue: 0,
  },
  {
    key: 'active_gates',
    name: 'Active Gates',
    description: 'Per Gateway with at least 1 payload sent during the month',
    defaultValue: 0,
  },
  {
    key: 'active_sensors_radio_measured',
    name: 'Active Sensors Radio Measured',
    description: 'Per device with at least 1 radio measurement during the month',
    defaultValue: 0,
  },
  {
    key: 'active_sensors_manual_measured',
    name: 'Active Sensors Manual Measured',
    description: 'Per device with at least 1 manual measurement during the month',
    defaultValue: 0,
  },
  {
    key: 'active_sim_cards',
    name: 'Active SIM Cards',
    description: 'Per SIM card with at least 1 payload sent during the month',
    defaultValue: 10,
  },
].map((rate) => {
  const { key } = rate;
  const totalField = `${key}_rate_total`;
  const unitsField = `${key}_rate_units`;
  RATE_TOTAL_FIELDS.push(totalField);
  RATE_UNITS_FIELDS.push(unitsField);
  RATE_NAMES.push(rate.name);
  return {
    ...rate,
    totalField,
    unitsField
  };
});

export default {
  RATES,
  RATE_NAMES,
  RATE_TOTAL_FIELDS,
  RATE_UNITS_FIELDS
};