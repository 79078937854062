import {useQuery} from '@apollo/react-hooks';
import CloudDownload from '@material-ui/icons/CloudDownload';
import gql from 'graphql-tag';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Table from '../../components/Table';
import downloadCsv from '../../lib/downloadCsv';
import CompanyNames from './company-names.json';

const GET_MANUFACTURER_LIST_AES = gql`
  query getManufacturersList {
    manufacturersList {
      manufacturer
      device_type
      type
      count
    }
  }
`;

export default function ManufacturersList() {
  const {t} = useTranslation(['devices']);
  const {data, loading, error} = useQuery(GET_MANUFACTURER_LIST_AES);
  if (error) {
    return <div>Error</div>;
  }
  const rows = loading ? [] : data.manufacturersList.map(({manufacturer, device_type, type, count}) => ({manufacturer,company_name: CompanyNames[manufacturer], device_type, type, count}));
  return (
    <Table
      tableId="manufacturers-list"
      title={t('devices:Manufacturers List')}
      isLoading={loading}
      columns={[
        {
          title: t('devices:Manufacturer Code'),
          field: 'manufacturer',
        },
        {
          title: t('devices:Company Name'),
          field: 'company_name',
        },
        {
          title: t('devices:Device Type'),
          field: 'device_type',
        },
        {
          title: t('devices:User Device Type'),
          field: 'type',
        },
        {
          title: t('devices:Number of Devices'),
          field: 'count',
          editable: 'never',
        },
      ]}
      data={rows}
      options={{
        filtering: true,
      }}
      actions={[
        {
          icon: () => <CloudDownload />,
          isFreeAction: true,
          tooltip: t('devices:Download CSV'),
          onClick: () =>
            downloadCsv(
              {
                header: ['Manufacturer Code', 'Company Name', 'Device Type', 'User Device Type', 'Number of Devices'],
                rows: rows.map(
                  ({
                    manufacturer,
                    company_name,
                    device_type,
                    type,
                    count,
                  }) => [
                    manufacturer,
                    company_name,
                    device_type,
                    type,
                    count,
                  ],
                ),
              },
              'ManufacturersList_' + new Date().toString() + '.csv',
            ),
        },
      ]}
    />
  );
}
