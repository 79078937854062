Object.defineProperty(exports, '__esModule', {
  value: true,
});
exports.default = void 0;
const {REACT_APP_BUILD_ENV} = process.env;
console.info(`REACT_APP_BUILD_ENV = ${REACT_APP_BUILD_ENV}`);
const DOMAIN = 'dokku.smarterbuilding.de';
const PATH = '/graphql';
const _default = {
  apiUri: (() => {
    switch (REACT_APP_BUILD_ENV) {
      case 'production':
        return `https://admin.${DOMAIN}${PATH}`;
      case 'staging':
        return `https://staging-server.${DOMAIN}${PATH}`;
      case 'development':
      default:
        return `http://localhost:4000${PATH}`;
    }
  })(),
};
exports.default = _default;
