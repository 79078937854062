import React, { useState } from "react";
import { Grid, Button, Typography, Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import gql from "graphql-tag";
import Card from "./Card";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useMutation } from "@apollo/react-hooks";
import BuildingModel from '../../model/building';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

const GET_COVERAGE_ISSUES = gql`
  mutation getCoverageIssues {
    coverageIssues {
      id
      external_id
      street
      house_number
      city
      postalcode
      latest_report {
        sensors_count
      }
      report {
        id
        report_stats {
          date
          sensors_count
          radio_collected_count
        }
        report_sensor {
          sensor {
            serial_number
          }
        }
      }
    }
  }
`

function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value);
}

export default function CoverageIssues({ data }) {
  const { t } = useTranslation(["dashboard"]);
  const [getCoverageIssues] = useMutation(GET_COVERAGE_ISSUES);
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    try {
      const { data } = await getCoverageIssues();

      const formattedData = data.coverageIssues.map(({street, house_number, city, postalcode, external_id, report, latest_report}) => ({
        'Date of Statistic': report.report_stats.date,
        'Building External Number': external_id,
        'Building Address': BuildingModel.fullAddress(street, house_number, city, postalcode),
        'Device Serial Number': report?.report_sensor?.sensor?.serial_number,
        'Total number of devices': report.report_stats.sensors_count,
        'Total number of wireless devices': report.report_stats.radio_collected_count,
        'Total number of devices recieved in latest report': latest_report?.sensors_count,
      }));

      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(formattedData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Coverage Issues');

      // Generate a binary string representation of the workbook
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Convert the binary string to a Blob and trigger the download
      const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(dataBlob, "BuildingsCoverageIssues-"+((new Date()).toISOString())+".xlsx");
    } catch (error) {
      console.error("Error fetching coverage issues data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid item xs={12} md={4}>
      <Card title={t("dashboard:Coverage Issues")}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p={2}>
          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {t("dashboard:Buildings with Coverage below 90%")}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t("dashboard:Number of Buildings")}: {isValidNumber(data.building_coverage_count) ? data.building_coverage_count : 'N/A'}
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownload}
            endIcon={loading ? <CircularProgress size={24} /> : <DownloadIcon />}
            disabled={loading || !isValidNumber(data.building_coverage_count) || data.building_coverage_count === 0}
          >
            {loading ? t("dashboard:loading") : t("dashboard:excel")}
          </Button>
        </Box>
      </Card>
    </Grid>
  );
}
