// @format
import React from 'react';
import moment from 'moment';
import fp from 'lodash/fp';
import {useTranslation} from 'react-i18next';
import {MTableToolbar} from 'material-table';
import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';

import Table from '../components/Table';
import DeleteBuilding from './Building/DeleteBuilding';
import BuildingSensors from './Building/BuildingSensors';
import BuildingApartments from './Building/BuildingApartments';
import ToggleButton from '@material-ui/lab/ToggleButton';

const GET_BUILDING = gql`
  query getBuilding($id: ID!) {
    buildings(ids: [$id]) {
      id
      street
      house_number
      city
      postalcode
      alternate_address
      external_id
      comment
      set_date
      sensors_count
      meeters_count
      serials_count
      import_method
      is_active
      sensors_missing_aes_count
      prices {
        id
        year
        heat_var
        heat_fixed
        water_var
        water_fixed
      }
      sensors {
        id
        name
        original_type
        factor
        serial_number
        external_number
        installed_at
        removed_at
        comment
      }
      apartments {
        id
        external_name
        external_name2
        external_number
        location
        area_heating
        area_water
        comment
      }
    }
  }
`;

const REFRESH_BUILDING = gql`
  mutation refreshBuilding($id: ID!) {
    refreshBuilding(id: $id) {
      id
      street
      house_number
      city
      postalcode
      external_id
      comment
      set_date
      sensors_count
      serials_count
      import_method
      is_active
      prices {
        id
        year
        heat_var
        heat_fixed
        water_var
        water_fixed
      }
      sensors {
        id
        name
        factor
        serial_number
        external_number
        installed_at
        removed_at
        comment
      }
      apartments {
        id
        external_name
        external_name2
        external_number
        location
        area_heating
        area_water
        comment
      }
    }
  }
`;

const UPDATE_BUILDING = gql`
  mutation updateBuilding($id: ID!, $is_active: Boolean!) {
    updateBuilding(id: $id, is_active: $is_active)
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tableToolbar: {
    flexGrow: 1,
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    marginRight: 20,
  },
}));

export default function Building({match}) {
  const {t} = useTranslation(['building']);
  const {loading, error, data} = useQuery(GET_BUILDING, {
    variables: {
      id: match.params.id,
    },
  });

  const [refreshBuilding, {loading: refreshLoading, error: refreshError}] =
    useMutation(REFRESH_BUILDING);

  const [updateBuilding, { loading: updateLoading }] = useMutation(UPDATE_BUILDING, {
    refetchQueries: [
      {
        query: GET_BUILDING,
        variables: {
          id: match.params.id,
        },
      },
    ],
  });

  const classes = useStyles();
  const isLoading = loading || refreshLoading || updateLoading;

  if (error || refreshError) {
    return <div>Error</div>;
  }

  const building = isLoading ? {} : fp.first(data.buildings);

  if (!building) {
    return <div>{t('building:Building not found')}</div>;
  }

  return (
    <div className={classes.root}>
      <Box>
        <Table
          title={
            <div>
              <HomeIcon className={classes.titleIcon} />
              {building.street} {building.house_number}
            </div>
          }
          isLoading={isLoading}
          columns={[
            {
              title: t('building:ID'),
              field: 'id',
              type: 'numeric',
              defaultSort: 'desc',
            },
            {title: t('building:ID1'), field: 'external_id'},
            {title: t('building:Street'), field: 'street'},
            {title: t('building:Number'), field: 'house_number'},
            {title: t('building:City'), field: 'city'},
            {title: t('building:Zipcode'), field: 'postalcode'},
            {
              title: t('building:Alternate Address'),
              field: 'alternate_address',
            },
            {title: t('building:Comment'), field: 'comment'},
            {
              title: t('building:Set Date'),
              field: 'set_date',
              type: 'datetime',
              render: (rowData) => {
                return fp.get('set_date', rowData)
                  ? moment.utc(rowData.set_date).format('MMMM YYYY')
                  : '';
              },
            },
            {title: t('building:Sensors Count'), field: 'sensors_count'},
            {title: t('building:Meeters Count'), field: 'meeters_count'},
            {title: t('building:Serials Count'), field: 'serials_count'},
            {title: t('building:Import method'), field: 'import_method'},
            {
              title: t('building:Sensors Missing AES Count'),
              field: 'sensors_missing_aes_count',
            },
          ]}
          data={loading ? [] : data.buildings}
          options={{
            sorting: false,
            search: false,
            toolbar: true,
            paging: false,
          }}
          components={{
            Toolbar: (props) => (
              <div className={classes.toolbarWrapper}>
                <MTableToolbar
                  {...props}
                  classes={{root: classes.tableToolbar}}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={building.import_method !== 'ftp'}
                  onClick={() => {
                    refreshBuilding({
                      variables: {
                        id: building.id,
                      },
                    });
                  }}>
                  {t('building:Refresh')}
                </Button>
                <ToggleButton
                  onClick={() => updateBuilding({variables: {id: building.id, is_active: !building.is_active}})}
                  className={classes.button}
                  size="small"
                  selected={building.is_active}>
                  {t('building:Active')}
                </ToggleButton>
                <DeleteBuilding building={building} />
              </div>
            ),
          }}
        />
      </Box>

      <Box>
        <BuildingSensors
          classes={classes}
          building={building}
          isLoading={isLoading}
          t={t}
        />
      </Box>

      <Box>
        <BuildingApartments
          classes={classes}
          building={building}
          t={t}
        />
      </Box>
    </div>
  );
}
