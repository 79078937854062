import { useQuery } from "@apollo/react-hooks";
import CloudDownload from '@material-ui/icons/CloudDownload';
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table";
import downloadCsv from '../../lib/downloadCsv';

const GET_SENSORS_MISSING_AES = gql`
  query getSensorsMissingAes {
    sensorsMissingAes {
      id
      serial_number
      external_number
      building {
        external_id
      }
      apartment {
        id
      }
      manufacturer
    }
  }
`;

export default function SensorsMissingAes() {
  const { t } = useTranslation(['devices']);
  const { data, loading, error } = useQuery(GET_SENSORS_MISSING_AES);
  if (error) {
    return <div>Error</div>;
  }
  const rows = loading ? [] : data.sensorsMissingAes;
  return (
    <Table
      tableId="sensors-missing-aes"
      title={t('devices:Sensors Missing AES')}
      isLoading={loading}
      columns={[
        {
          title: t('devices:Serial Number'),
          field: 'serial_number',
        },
        {
          title: t('devices:ID4'),
          field: 'external_number',
        },
        {
          title: t('devices:Manufacturer'),
          field: 'manufacturer',
          defaultSort: 'asc',
        },
        {
          title: t('devices:Building ID1'),
          field: 'building.external_id',
        },
        {
          title: t('devices:Apartment ID'),
          field: 'apartment.id',
        },
      ]}
      data={rows}
      options={{
        filtering: true,
      }}
      actions={[
        {
          icon: () => <CloudDownload />,
          isFreeAction: true,
          tooltip: t('devices:Download CSV'),
          onClick: () => downloadCsv(
            {
              header: [
                'Serial Number',
                'Manufacturer',
              ],
              rows: rows.map((row) => [
                row.serial_number,
                row.manufacturer,
              ]),
            },
            'SensorsMissingAes_' + new Date().toString() + '.csv',
          ),
        },
      ]}
    />
  );
}