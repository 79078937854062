import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { MTableToolbar } from "material-table";
import useLocalStorage from "react-use-localstorage";
import { CircularProgress } from "@material-ui/core";

import { useMe } from "../../reducers/me";
import UserModel from "../../model/user";
import Table from "../../components/Table";
import GatewayModel from "../../model/gateway";
import useSearchQuery from "../../lib/search-query";
import data from "../../lib/data";
import config from "../../config";
import { useSnackbar } from "notistack";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

const GATEWAY_SEARCH = gql`
  mutation gatewaysBacklogSearch(
    $query: SearchQuery!
    $gatewaysBacklogQuery: GatewaysBacklogSearchQuery
  ) {
    gatewaysBacklogSearch(
      query: $query
      gatewaysBacklogQuery: $gatewaysBacklogQuery
    ) {
      rows {
        id
        imei
        serial_number
        manufacturer
        model
        import_method
        status
        organization {
          id
          name
        }
        user {
          id
          email
        }
      }
      count
    }
  }
`;

const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    organizations {
      id
      name
    }
  }
`;

const UPDATE_GATEWAY = gql`
  mutation updateGateway($id: ID!, $organizationId: ID) {
    updateGateway(id: $id, organizationId: $organizationId) {
      id
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  tableToolbarWrapper: {
    display: "flex",
    alignItems: "center",
  },
  filterBox: {
    alignSelf: "start",
  },
  radioRed: {
    "&$radioChecked": {
      color: "red",
    },
  },
  radioYellow: {
    "&$radioChecked": {
      color: "#FFAA00",
    },
  },
  radioGreen: {
    "&$radioChecked": {
      color: "green",
    },
  },
  radioGray: {
    "&$radioChecked": {
      color: "gray",
    },
  },
  labelRed: {
    color: "red",
  },
  labelYellow: {
    color: "#FFAA00",
  },
  labelGreen: {
    color: "green",
  },
  gatewayTypeControl: {
    color: "#3075BB",
  },
  radioChecked: {},
}));

export default function GatewaysBacklog({ match }) {
  const {
    data: organizationsData,
    loading: organizationsLoading,
    error: organizationsError,
  } = useQuery(GET_ORGANIZATIONS, {});

  const [updateGateway] = useMutation(UPDATE_GATEWAY, {
    onCompleted: () => {
      enqueueSnackbar(t("gateway:Gateway updated successfully"), {
        variant: "success",
      });
    },
    onError: (err) => {
      enqueueSnackbar(t("gateway:Could not save the gateway"), {
        variant: "error",
      });
    },
  });
  const [gatewaySearch] = useMutation(GATEWAY_SEARCH);
  const [pageSize, setPageSize] = useState(10);

  const query = useSearchQuery();
  const { t } = useTranslation(["gateway"]);
  const [me] = useMe();
  const [gatewayStatusFilter, setGatewayStatusFilter] = useLocalStorage(
    "gateway-status-filter",
    "null"
  );
  const [gatewayImportMethodFilter, setGatewayImportMethodFilter] =
    useLocalStorage("gateway-import-method-filter", "null");
  const classes = useStyles();
  const { history } = useReactRouter();

  const [refreshInProgress, setRefreshInProgress] = useState(false);
  const [isSendingCsv, setSendingCsv] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const refreshInProgressTimoutRef = useRef(null);

  const [csvError, setCsvError] = useState([]);
  const [csvWarnings, setCsvWarnings] = useState([]);
  const [isHelpOpen, setHelpOpen] = useState(false);

  useEffect(() => {
    return () => {
      // clear timeout when unmounting
      clearTimeout(refreshInProgressTimoutRef.current);
    };
  });
  const tableRef = useRef();
  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
    setRefreshInProgress(false);
  }, [gatewayStatusFilter, gatewayImportMethodFilter, refreshInProgress]);

  const [searchTextChanged, setSearchTextChanged] = useState(null);
  // to prevent remounting toolbar after entering something into search field

  const onFileChange = async ({ target }) => {
    if (target.value.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append("file", target.files[0]);

    setSendingCsv(true);
    const response = await fetch(`${config.apiUri}/upload-csv-gateways`, {
      method: "POST",
      body: formData,
      credentials: "include",
    });
    setSendingCsv(false);

    target.value = "";
    const responseJson = await response.json();
    const { warnings } = responseJson;
    if (warnings) {
      setCsvWarnings(
        warnings.map(({ line, message }) => {
          return `Line ${line}: ${message}`;
        })
      );
    }
    if (!response.ok) {
      const { errors } = responseJson;

      if (errors) {
        setCsvError(
          errors.map(({ line, message }) => {
            return `Line ${line}: ${message}`;
          })
        );
      } else {
        enqueueSnackbar(t("gateway:Could not upload the CSV"), {
          variant: "error",
        });
      }
      return;
    }

    setRefreshInProgress(true);
    enqueueSnackbar(t("gateway:CSV uploaded successfully"), {
      variant: "success",
    });
  };

  const Toolbar = useMemo(
    () => (props) =>
      (
        <div className={classes.tableToolbarWrapper}>
          <Box ml={2} pt={2} className={classes.filterBox}>
            <InputLabel>{t("gateway:Gateway Status")}</InputLabel>
            <RadioGroup
              value={gatewayStatusFilter}
              onChange={({ currentTarget: { value } }) => {
                setGatewayStatusFilter(value);
              }}
            >
              {GatewayModel.GATEWAY_STATUSES.map((status) => {
                const value = GatewayModel.GATEWAY_STATUS_VALUES[status];
                return (
                  <FormControlLabel
                    onClick={() => {
                      if (gatewayStatusFilter === status) {
                        setGatewayStatusFilter(null);
                      }
                    }}
                    key={value}
                    control={
                      <Radio
                        classes={{
                          checked: classes.radioChecked,
                        }}
                      />
                    }
                    value={status}
                    label={t(`gateway:${value}`)}
                  />
                );
              })}
              <FormControlLabel
                onClick={() => {
                  setGatewayStatusFilter("null");
                }}
                key={"null"}
                control={
                  <Radio
                    classes={{
                      checked: classes.radioChecked,
                    }}
                  />
                }
                value={"null"}
                label={t(`gateway:${"Empty"}`)}
              />
            </RadioGroup>
          </Box>
          <Box ml={2} pt={2} className={classes.filterBox}>
            <InputLabel>{t("gateway:Gateway Import Method")}</InputLabel>
            <RadioGroup
              value={gatewayImportMethodFilter}
              onChange={({ currentTarget: { value } }) => {
                setGatewayImportMethodFilter(value);
              }}
            >
              {GatewayModel.GATEWAY_IMPORT_METHODS.map((import_method) => {
                const value =
                  GatewayModel.GATEWAY_IMPORT_METHOD_VALUES[import_method];
                return (
                  <FormControlLabel
                    onClick={() => {
                      if (gatewayImportMethodFilter === import_method) {
                        setGatewayImportMethodFilter(null);
                      }
                    }}
                    key={value}
                    control={
                      <Radio
                        color="primary"
                        classes={{
                          checked: classes.radioChecked,
                        }}
                      />
                    }
                    value={import_method}
                    label={t(`gateway:${value}`)}
                  />
                );
              })}
              <FormControlLabel
                onClick={() => {
                  setGatewayImportMethodFilter("null");
                }}
                key={"null"}
                control={
                  <Radio
                    color="primary"
                    classes={{
                      checked: classes.radioChecked,
                    }}
                  />
                }
                value={"null"}
                label={t(`gateway:${"Empty"}`)}
              />
            </RadioGroup>
          </Box>
          <MTableToolbar {...props} />
          <Box ml={2} className={classes.uploadCsvButtonWrapper}>
            <input
              hidden
              type="file"
              accept=".csv,.xlsx"
              id="csv-upload-input"
              onChange={onFileChange}
            />
            <label htmlFor="csv-upload-input">
              <Button component="span" color="primary" variant="contained">
                {t("gateway:Upload CSV")}
              </Button>
            </label>
          </Box>
          <Box ml={1} pt={1}>
            <HelpOutlineOutlinedIcon
              className={classes.helpIcon}
              onClick={() => setHelpOpen(true)}
            />
            <Dialog aria-labelledby="upload-csv-help" open={isHelpOpen}>
              <DialogTitle id="upload-csv-help">
                {t("gateway:Upload CSV Help")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("gateway:upload-instructions")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setHelpOpen(false)} color="primary">
                  {t("gateway:Understood")}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </div>
      ),
    [gatewayStatusFilter, gatewayImportMethodFilter, isHelpOpen]
  );

  if (refreshInProgress || organizationsLoading) {
    return <CircularProgress></CircularProgress>;
  }

  const columns = [
    {
      title: t("gateway:ID"),
      field: "id",
      type: "numeric",
      defaultSort: "desc",
      editable: "never",
    },
    { title: t("Serial Number"), field: "serial_number", editable: false },
    { title: t("IMEI"), field: "imei", editable: false },
    {
      title: t("Import Method"),
      field: "import_method",
      editable: false,
      render: (rowData) =>
        GatewayModel.GATEWAY_IMPORT_METHOD_VALUES[rowData.import_method],
    },
    { title: t("Manufacturer"), field: "manufacturer", editable: false },
    { title: t("Model"), field: "model", editable: false },
    {
      title: t("Status"),
      field: "status",
      editable: true,
      render: (rowData) => GatewayModel.GATEWAY_STATUS_VALUES[rowData.status],
    },
    {
      title: t("Organization"),
      field: "organization.id",
      editable: "onUpdate",
      // initialEditValue:(rowData) => rowData.organization.id,
      lookup: organizationsData.organizations.reduce((acc, org) => {
        acc[Number(org.id)] = org.name;
        return acc;
      }, {}),
      render: (rowData) => {
        const organization = organizationsData.organizations.find(
          (org) => org.id === rowData.organization.id
        );
        return organization ? organization.name : "";
      },
    },
    {
      title: t("Created By"),
      field: "user.email",
      association: "Users",
      filter: {
        fields: ["$User.email$"],
      },
      editable: false,
      association: "User",
    },
  ];
  const columnFieldToColumnMap = columns.reduce(
    (result, column) => ({ ...result, [column.field]: column }),
    {}
  );

  const handleRowUpdate = async (newData, oldData) => {
    if (oldData["organization"].id !== newData["organization"].id) {
      await updateGateway({
        variables: {
          id: oldData.id,
          organizationId: newData["organization"].id,
        },
      });
    }
  };

  return (
    <React.Fragment>
      {isSendingCsv && (
        <Box>
          <CircularProgress></CircularProgress>
        </Box>
      )}
      <Box
        hidden={csvWarnings && csvWarnings.length === 0}
        style={{
          maxHeight: 300,
          overflow: "scroll",
          padding: 5,
        }}
      >
        <h4>
          <IconButton
            onClick={() => setCsvWarnings([])}
            color="error"
            aria-label="Close warnings"
            component="span"
          >
            <CloseIcon />
          </IconButton>
          CSV Warnings:
        </h4>
        {csvWarnings &&
          csvWarnings.map((error) => (
            <Alert severity="warning" style={{ marginBottom: 3 }}>
              {error}
            </Alert>
          ))}

      </Box>
      <Box
        hidden={csvError && csvError.length === 0}
        style={{
          maxHeight: 300,
          overflow: "scroll",
          padding: 5,
        }}
      >
        <h4>
          <IconButton
            onClick={() => setCsvError([])}
            color="error"
            aria-label="Close errors"
            component="span"
          >
            <CloseIcon />
          </IconButton>
          CSV Errors:
        </h4>
        {csvError &&
          csvError.map((error) => (
            <Alert severity="error" style={{ marginBottom: 3 }}>
              {error}
            </Alert>
          ))}
      </Box>
      <Table
        tableId="gatewaysBacklog"
        title=""
        tableRef={tableRef}
        columns={columns}
        data={data(
          columnFieldToColumnMap,
          gatewaySearch,
          (response) => response.data.gatewaysBacklogSearch,
          setPageSize,
          [],
          false,
          {
            gatewaysBacklogQuery: {
              status:
                gatewayStatusFilter === "null" ? null : gatewayStatusFilter,
              import_method:
                gatewayImportMethodFilter === "null"
                  ? null
                  : gatewayImportMethodFilter,
            },
          }
        )}
        onSearchChange={(searchedText) => {
          // state change is needed to update unknown
          // organization gateways that match search text
          setSearchTextChanged(searchedText);
        }}
        options={{
          sorting: true,
          pageSize,
          searchAutoFocus: true,
          columnsButton: true,
          compressed: true,
        }}
        components={{
          Toolbar,
        }}
        onRowClick={(event, rowData) => {
          history.push(`${match.path}/${rowData.id}`);
        }}
        editable={
          UserModel.roleAdmin(me.role) && {
            onRowUpdate: handleRowUpdate,
          }
        }
      />
    </React.Fragment>
  );
}
