import React, {useState} from 'react';
// import moment from 'moment';
// import _ from 'lodash';
import {useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Paper} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import {useMe} from '../../reducers/me';
import UserModel from '../../model/user';
import ErrorStatus from '../../components/ErrorStatus';
import Table from '../../components/Table';
import FileModel from '../../model/file';

const GET_BUILDING_STATS = gql`
  mutation assumeBuildingStats($buildingId: ID!, $dates: [DateTime!]!) {
    assumeBuildingStats(buildingId: $buildingId, dates: $dates)
  }
`;

const doCSV = (statItems, year) => {
  const records = statItems.filter((item) => item.year === +year);
  const dates = records.map((item) => item.month);

  const sensorReadings = {};
  records.forEach(({items, month}) => {
    items.forEach((item) =>
      Object.values(item).forEach(({totalDelta, entities}) => {
        Object.values(entities).forEach(
          ({Sensor, measurements, normalizedDelta, delta}) => {
            if (!sensorReadings[Sensor.id]) {
              sensorReadings[Sensor.id] = {
                id: Sensor.id,
                type: Sensor.type,
                apartment_id: Sensor.Apartment.id,
                factor: Sensor.factor,
              };
            }
            sensorReadings[Sensor.id][`Records ${month}`] = measurements
              .map((m) => `${m.date} | ${m.value}`)
              .join('\n');
            sensorReadings[Sensor.id][`Delta ${month}`] = delta;
            sensorReadings[Sensor.id][`Factor x Delta ${month}`] =
              normalizedDelta;
          },
        );
      }),
    );
  });

  const rows = [
    [
      'Apartment ID',
      'Sensor ID',
      'Sensor Type',
      'Sensor Factor',
      ...dates.map((date) => `Records ${date}`),
      ...dates.map((date) => `Delta ${date}`),
      ...dates.map((date) => `Factor x Delta ${date}`),
    ],
  ];
  Object.values(sensorReadings).forEach((row) => {
    rows.push([
      row.apartment_id,
      row.id,
      row.type,
      row.factor,
      ...dates.map((date) => row[`Records ${date}`]),
      ...dates.map((date) => row[`Delta ${date}`]),
      ...dates.map((date) => row[`Factor x Delta ${date}`]),
    ]);
  });

  return rows.map((row) => row.map((item) => `"${item}"`).join(',')).join('\n');
};
const doAnnual = (statItems) => {
  const sums = {};
  statItems.forEach(({year, items}) => {
    if (!sums[year]) {
      sums[year] = {};
    }
    items.forEach((item) => {
      Object.keys(item).forEach((type) => {
        if (!sums[year][type]) {
          sums[year][type] = 0;
        }
        sums[year][type] += item[type].totalDelta || 0;
      });
    });
  });
  Object.keys(sums).forEach((year) => {
    if (sums[year]['WWZ']) {
      sums[year]['WWZ'] = 2.5 * sums[year]['WWZ'] * 50;//UviHelper.warmWaterKwh
    }
    Object.keys(sums[year]).forEach((type) => {
      sums[year][type] = Math.round(sums[year][type]);
    });
  });
  return sums;
};
const AnnualData = ({buildingId}) => {
  const {t} = useTranslation(['uvi', 'building']);
  const [useMeData] = useMe();
  const me = useMeData || {status: null};
  const [
    getBuildingStats,
    {loading: buildingStatsLoading, error: buildingStatsError},
  ] = useMutation(GET_BUILDING_STATS);

  const [currentStats, setCurrentStats] = useState(null);
  if (!UserModel.roleUser(me.role)) {
    return '';
  }
  if (buildingStatsError) {
    return <ErrorStatus />;
  }
  const dates = [];
  for (let i = 0; i < 12; i++) {
    dates.push(
      moment(new Date())
        .startOf('year')
        .add(-i - 1, 'month'),
    );
  }
  if (!currentStats && !buildingStatsLoading) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          let date;
          for (date of dates) {
            const resp = await getBuildingStats({
              variables: {
                buildingId,
                dates: [date.format('YYYY-MM-DDTHH:mm:ss.000\\Z')],
              },
            });
            setCurrentStats((prevState) => [
              ...(prevState || []),
              ...resp.data.assumeBuildingStats,
            ]);
          }
        }}>
        {t('uvi:Annual Data')}
      </Button>
    );
  }
  const stats = doAnnual(currentStats || []);
  return (
    <Paper>
      <Table
        title={<div>{t('uvi:Annual Data')}</div>}
        tableId={'annual-data'}
        isLoading={buildingStatsLoading}
        columns={[
          {
            title: t('uvi:Year'),
            field: 'year',
          },
          {title: t('uvi:WWZ'), type: 'numeric', field: 'WWZ'},
          {title: t('uvi:HVE'), type: 'numeric', field: 'HVE'},
          {title: t('uvi:WMZ'), type: 'numeric', field: 'WMZ'},
          {title: t('uvi:HVV'), type: 'numeric', field: 'HVV'},
        ]}
        data={Object.keys(stats).map((year) => ({
          ...stats[year],
          year,
        }))}
        options={{
          sorting: false,
          search: false,
          toolbar: false,
          paging: false,
        }}
        actions={[
          (rowData) => ({
            icon: InsertDriveFileIcon,
            tooltip: t('uvi:Download CSV Details'),
            isFreeAction: false,
            onClick: () => {
              FileModel.save(
                doCSV(currentStats || [], rowData.year),
                `Annual data ${rowData.year}.csv`,
                'text/csv',
              );
            },
          }),
        ]}
      />
    </Paper>
  );
};
export default AnnualData;
