// @format
import fetch from 'unfetch';

import history from './history';

export default async function ifenaFetch(url, options) {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });

  if (!response.ok) {
    if (response.status === 400) {
      history.push('/login');
      return;
    }
  }

  return response;
}
