import React, {useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {MTableToolbar} from 'material-table';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import config from '../config';
import Table from '../components/Table';

const useStyles = makeStyles((theme) => ({
  tableToolbarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tableToolbar: {
    flexGrow: 1,
  },
  uploadCsvButtonWrapper: {
    flex: 1,
  },
  helpIcon: {
    verticalAlign: 'middle',
    marginLeft: 5,
    fontSize: 24,
    cursor: 'pointer',
  },
}));

const GET_SENSOR_KEYS = gql`
  query getSensorKeys($serialNumbers: [Int]) {
    sensorKeys(serialNumbers: $serialNumbers) {
      id
      sensor_serial_number
      aes_key
    }
  }
`;

const UPDATE_SENSOR_KEY = gql`
  mutation updateSensorKey($id: ID!, $serialNumber: Int!, $aesKey: String!) {
    updateSensorKey(id: $id, serialNumber: $serialNumber, aesKey: $aesKey) {
      id
      sensor_serial_number
      aes_key
    }
  }
`;

const ADD_SENSOR_KEY = gql`
  mutation addSensorKey($serialNumber: Int!, $aesKey: String!) {
    addSensorKey(serialNumber: $serialNumber, aesKey: $aesKey) {
      id
      sensor_serial_number
      aes_key
    }
  }
`;

const DELETE_SENSOR_KEY = gql`
  mutation deleteSensorKey($id: ID!) {
    deleteSensorKey(id: $id) {
      id
    }
  }
`;

const IndividualKeysTable = () => {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation(['sensorKey']);
  const [isHelpOpen, setHelpOpen] = useState(false);
  const [isSendingCsv, setSendingCsv] = useState(false);
  const {loading, error, data, refetch} = useQuery(GET_SENSOR_KEYS, {});
  const [updateSensorKey] = useMutation(UPDATE_SENSOR_KEY);
  const [addSensorKey] = useMutation(ADD_SENSOR_KEY, {
    update: (cache, {data: {addSensorKey}}) => {
      const {sensorKeys} = cache.readQuery({query: GET_SENSOR_KEYS});

      cache.writeQuery({
        query: GET_SENSOR_KEYS,
        data: {
          sensorKeys: sensorKeys.concat(addSensorKey),
        },
      });
    },
  });
  const [deleteSensorKey] = useMutation(DELETE_SENSOR_KEY, {
    update: (cache, {data: {deleteSensorKey}}) => {
      const {sensorKeys} = cache.readQuery({query: GET_SENSOR_KEYS});

      cache.writeQuery({
        query: GET_SENSOR_KEYS,
        data: {
          sensorKeys: sensorKeys.filter(
            (sensorKey) => sensorKey.id !== deleteSensorKey.id,
          ),
        },
      });
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('sensorKey:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {title: t('sensorKey:Serial Number'), field: 'sensor_serial_number'},
    {title: t('sensorKey:AES Key'), field: 'aes_key'},
  ];

  const onHelpClick = () => {
    setHelpOpen(true);
  };

  const onHelpDialogClose = () => {
    setHelpOpen(false);
  };

  const onFileChange = async ({target}) => {
    if (target.value.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append('file', target.files[0]);

    setSendingCsv(true);
    const response = await fetch(`${config.apiUri}/upload-csv-aes-keys`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
    setSendingCsv(false);

    target.value = '';
    if (!response.ok) {
      enqueueSnackbar(t('sensorKey:Could not upload the CSV'), {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar(t('sensorKey:CSV uploaded successfully'), {
      variant: 'success',
    });
    refetch();
  };

  const isLoading = isSendingCsv || loading;
  return (
    <Table
      tableId="individual-sensor-keys"
      title=""
      isLoading={isLoading}
      columns={columns}
      data={isLoading ? [] : data.sensorKeys}
      options={{
        sorting: true,
        pageSize: 10,
      }}
      editable={{
        onRowAdd: async (newData) => {
          await addSensorKey({
            variables: {
              serialNumber: Number(newData.sensor_serial_number),
              aesKey: newData.aes_key,
            },
          });
        },
        onRowUpdate: async (newData, oldData) => {
          await updateSensorKey({
            variables: {
              id: newData.id,
              serialNumber: Number(newData.sensor_serial_number),
              aesKey: newData.aes_key,
            },
          });
        },
        onRowDelete: async (oldData) => {
          await deleteSensorKey({
            variables: {
              id: oldData.id,
            },
          });
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
        Toolbar: (props) => (
          <div className={classes.tableToolbarWrapper}>
            <Box ml={2} className={classes.uploadCsvButtonWrapper}>
              <input
                hidden
                type="file"
                accept=".csv"
                id="csv-upload-input"
                onChange={onFileChange}
              />
              <label htmlFor="csv-upload-input">
                <Button component="span" color="primary" variant="contained">
                  {t('sensorKey:Upload CSV')}
                </Button>
              </label>
              <HelpOutlineOutlinedIcon
                className={classes.helpIcon}
                onClick={onHelpClick}
              />
              <Dialog
                onClose={onHelpDialogClose}
                aria-labelledby="upload-csv-help"
                open={isHelpOpen}>
                <DialogTitle id="upload-csv-help">
                  {t('sensorKey:Upload CSV Help')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t('sensorKey:upload-instructions')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onHelpDialogClose} color="primary">
                    {t('sensorKey:Understood')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            <MTableToolbar {...props} classes={{root: classes.tableToolbar}} />
          </div>
        ),
      }}
    />
  );
};

const GET_DEFAULT_SENSOR_KEYS = gql`
  query getDefaultSensorKeys($manufacturers: [String]) {
    defaultSensorKeys(manufacturers: $manufacturers) {
      id
      manufacturer
      aes_key
    }
  }
`;

const ADD_DEFAULT_SENSOR_KEY = gql`
  mutation addSensorKey($manufacturer: String!, $aesKey: String!) {
    addDefaultSensorKey(manufacturer: $manufacturer, aesKey: $aesKey) {
      id
      manufacturer
      aes_key
    }
  }
`;

const UPDATE_DEFAULT_SENSOR_KEY = gql`
  mutation updateDefaultSensorKey(
    $id: ID!
    $manufacturer: String!
    $aesKey: String!
  ) {
    updateDefaultSensorKey(
      id: $id
      manufacturer: $manufacturer
      aesKey: $aesKey
    ) {
      id
      manufacturer
      aes_key
    }
  }
`;

const DELETE_DEFAULT_SENSOR_KEY = gql`
  mutation deleteDefaultSensorKey($id: ID!) {
    deleteDefaultSensorKey(id: $id) {
      id
    }
  }
`;

const GlobalKeysTable = () => {
  const {t} = useTranslation(['sensorKey']);
  const {loading, error, data} = useQuery(GET_DEFAULT_SENSOR_KEYS, {});
  const isLoading = loading;
  const [updateDefaultSensorKey] = useMutation(UPDATE_DEFAULT_SENSOR_KEY);
  const [addDefaultSensorKey] = useMutation(ADD_DEFAULT_SENSOR_KEY, {
    update: (cache, {data: {addDefaultSensorKey}}) => {
      const {defaultSensorKeys} = cache.readQuery({
        query: GET_DEFAULT_SENSOR_KEYS,
      });

      cache.writeQuery({
        query: GET_DEFAULT_SENSOR_KEYS,
        data: {
          defaultSensorKeys: defaultSensorKeys.concat(addDefaultSensorKey),
        },
      });
    },
  });
  const [deleteDefaultSensorKey] = useMutation(DELETE_DEFAULT_SENSOR_KEY, {
    update: (cache, {data: {deleteDefaultSensorKey}}) => {
      const {defaultSensorKeys} = cache.readQuery({
        query: GET_DEFAULT_SENSOR_KEYS,
      });

      cache.writeQuery({
        query: GET_DEFAULT_SENSOR_KEYS,
        data: {
          defaultSensorKeys: defaultSensorKeys.filter(
            (defaultSensorKey) =>
              defaultSensorKey.id !== deleteDefaultSensorKey.id,
          ),
        },
      });
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('sensorKey:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {title: t('sensorKey:Manufacturer'), field: 'manufacturer'},
    {title: t('sensorKey:AES Key'), field: 'aes_key'},
  ];

  return (
    <Table
      tableId="global-sensor-keys"
      title=""
      isLoading={isLoading}
      columns={columns}
      data={isLoading ? [] : data.defaultSensorKeys}
      options={{
        sorting: true,
        pageSize: 10,
      }}
      editable={{
        onRowAdd: async (newData) => {
          await addDefaultSensorKey({
            variables: {
              manufacturer: newData.manufacturer,
              aesKey: newData.aes_key,
            },
          });
        },
        onRowUpdate: async (newData, oldData) => {
          await updateDefaultSensorKey({
            variables: {
              id: newData.id,
              manufacturer: newData.manufacturer,
              aesKey: newData.aes_key,
            },
          });
        },
        onRowDelete: async (oldData) => {
          await deleteDefaultSensorKey({
            variables: {
              id: oldData.id,
            },
          });
        },
      }}
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
    />
  );
};

const Content = () => {
  const [visibleTabIndex, setVisibleTabIndex] = useState(0);
  const {t} = useTranslation(['sensorKey']);

  const onTabChange = (event, tabIndex) => {
    setVisibleTabIndex(tabIndex);
  };

  return (
    <Paper>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={visibleTabIndex}
        onChange={onTabChange}>
        <Tab label={t('sensorKey:Individual keys')} />
        <Tab label={t('sensorKey:Global keys')} />
      </Tabs>

      {visibleTabIndex === 0 ? <IndividualKeysTable /> : null}
      {visibleTabIndex === 1 ? <GlobalKeysTable /> : null}
    </Paper>
  );
};

export default function SensorKeys({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
