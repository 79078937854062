export default function data(
  columnFieldToColumnMap,
  search,
  getSearchResult,
  setPageSize,
  filters,
  shouldSkip,
  kwargs,
) {
  return async (query) => {
    if (shouldSkip && shouldSkip(query)) {
      return;
    }

    const { page, pageSize } = query;
    setPageSize && setPageSize(pageSize);
    const orderByField = query.orderBy?.field;
    const orderByFieldPath = orderByField?.split('.');
    const orderByAttribute = orderByFieldPath && orderByFieldPath[orderByFieldPath.length - 1];
    const association = columnFieldToColumnMap[orderByField]?.association;
    const { rows, count } = getSearchResult(await search({
      variables: {
        query: {
          filters: [
            ...query.filters.reduce((result, filter) => {
              const { column, value } = filter;
              const { lookup } = column;
              if (!lookup || value.length) {
                const columnFilter = column.filter;
                const transform = columnFilter?.transform;
                const values = lookup ? value : [value];
                result.push({
                  fields: columnFilter?.fields ?? [column.field],
                  operator: columnFilter?.operator,
                  values: transform ? values.map(transform) : values,
                });
              }
              return result;
            }, []),
            ...(filters || []),
          ],
          orderBy: [...(association?.split('.') || []), orderByAttribute].filter((value) => !!value),
          orderDirection: query.orderDirection.toUpperCase() || null,
          page,
          pageSize,
          search: query.search,
        },
        ...kwargs,
      },
    }));
    return {
      data: rows,
      page,
      totalCount: count,
    }
  };
}