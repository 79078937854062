import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useForm from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import Select from '../../components/Select';
import BuildingModel from '../../model/building';
import SelectOptionModel from '../../model/select-option';
import OperatorModel from '../../model/operator';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from '@material-ui/core';
import ReportModel from '../../model/report';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  hint: {
    fontSize: "0.9rem",
    marginTop: "0.5rem",
  },
}));


const getRepeat = (report) => report?.repeat ?? 'none';
const ReportForm = ({
  report,
  isEditPage,
  createReport,
  updateReport,
  match,
  buildings,
  operators,
  availableExports,
}) => {
  const { t } = useTranslation(['report']);
  const styles = useStyles();
  const [loading, setLoading] = useState(false);

  const defaultReadingDate = moment.utc().endOf('month').endOf('day');
  const defaultSetDate = moment.utc().subtract(1, 'year').endOf('year');

  const { register, setValue, watch, handleSubmit, errors } = useForm({
    defaultValues: {
      building: report ? report.building.id : '',
      operator: report ? report.operator?.id : '',
      readingDate:
        (report && moment.utc(report.reading_date).endOf('day')) ||
        defaultReadingDate,
      setDate:
        report ? report.set_date && moment.utc(report.set_date).endOf('day') : defaultSetDate,
        // report?.receiver ?? availableExports[0],
      receiver: isEditPage?(report?.receiver == null?'none': availableExports[0]):(report?.receiver ?? availableExports[0]),
      repeat: getRepeat(report),
    },
  });

  const [repeat, setRepeat] = useState(getRepeat(report));
  const {
    readingDate: readingDateValue,
    setDate: setDateValue
  } = watch(['readingDate', 'setDate']);

  useEffect(() => {
    setRepeat(getRepeat(report));
  }, [report]);

  const onBuildingChange = ({ value }) => {
    setValue('building', value);
  };

  const onOperatorChange = (data) => {
    if (data && data.value) {
      setValue('operator', data.value);
      return;
    }
    setValue('operator', null);
  };

  const onReadingDateChange = (value) => {
    setValue('readingDate', value.endOf('month'));
  };

  const onSetDateChange = (value) => {
    setValue('setDate', value.endOf('month'));
  };

  const onReceiverChange = ({ value }) => {
    console.log("value", value);
    setValue('receiver', value);
  };

  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async (data) => {
    if(!loading){
      setLoading(true);
    }else{
      return;
    }
    const repeatValue = repeat === 'none' ? null : repeat;
    const { receiver } = data;

    const receiverValue = receiver && receiver !== 'none' ? receiver : null;
    console.log("data", data);
    if (isEditPage) {
      await updateReport({
        variables: {
          id: match.params.id,
          buildingId: data.building,
          operatorId: data.operator,
          readingDate: data.readingDate.toISOString(),
          setDate: data.setDate?.toISOString(),
          repeat: repeatValue,
          comment: data.comment,
          receiver: receiverValue,
        },
      });
      setLoading(false);
      return;
    }

    try {
      await createReport({
        variables: {
          buildingId: data.building,
          operatorId: data.operator,
          readingDate: data.readingDate.toISOString(),
          setDate: data.setDate?.toISOString(),
          repeat: repeatValue,
          comment: data.comment,
          receiver: receiverValue,
        },
      });
    } catch (error) {
      enqueueSnackbar(t(`report:Error creating report`), { variant: 'error' });
      throw error;
    }finally{
      setLoading(false);
    }
  };

  const receivers = [
    {
      label: t('report:None'),
      value: 'none',
    },
    {
      label: t('report:SK-Soft'),
      value: 'sk_soft',
    },
  ];

  register(
    { name: 'building', type: 'custom' },
    { required: t('report:Select building') },
  );
  register(
    { name: 'operator', type: 'custom' },
  );
  register(
    { name: 'readingDate', type: 'custom' },
    { required: t('report:Select reading date') },
  );
  register({ name: 'setDate', type: 'custom' });
  register({ name: 'receiver', type: 'custom' });

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Select
        inputId="building-id"
        placeholder={t('report:Select building')}
        options={buildings}
        filterOption={
          (option, inputValue) => buildings.length <= 200 || inputValue && option.label.includes(inputValue)
        }
        noOptionsMessage={
          ({ inputValue }) =>
            !buildings.length || inputValue ? t('report:No options') : t('report:Enter value to see options')
        }
        onChange={onBuildingChange}
        isDisabled={isEditPage}
        defaultValue={
          isEditPage
            ? buildings.find(
              (building) => building.value === report.building.id,
            )
            : null
        }
        TextFieldProps={{
          required: true,
          label: t('report:Building'),
          margin: 'normal',
          name: 'building',
          disabled: isEditPage,
        }}
      />
      {errors.building && errors.building.message}
      <Select
        inputId="operator-id"
        placeholder={t('report:Select operator')}
        options={operators}
        onChange={onOperatorChange}
        isClearable={true}
        defaultValue={
          isEditPage && report.operator
            ? operators.find(
              (operator) => operator.value === report.operator.id,
            )
            : null
        }
        TextFieldProps={{
          label: t('report:Operator'),
          margin: 'normal',
          name: 'operator',
        }}
      />
      {errors.operator && errors.operator.message}
      <DatePicker
        fullWidth
        autoOk
        views={['year', 'month']}
        id="readingDate"
        name="readingDate"
        margin="normal"
        label={t('report:Reading date')}
        format="MMMM YYYY"
        value={readingDateValue}
        onChange={onReadingDateChange}
      />
      {errors.readingDate && errors.readingDate.message}
      <DatePicker
        fullWidth
        autoOk
        views={['year', 'month']}
        id="setDate"
        name="setDate"
        margin="normal"
        label={t('report:Set date')}
        format="MMMM YYYY"
        value={setDateValue}
        onChange={onSetDateChange}
      />
      {errors.setDate && errors.setDate.message}
      {/* // TODO: translations */}
      <FormControl margin="normal">
        <FormLabel id="repeat-label">{t('report:Repeat')}</FormLabel>
        <RadioGroup
          aria-labelledby="repeat-label"
          value={repeat}
          onChange={({ currentTarget: { value } }) => {
            if (value === ReportModel.REPEAT_VALUE_YEARLY) {
              setValue('readingDate', setDateValue.clone().add(1, 'month'));
            }
            setRepeat(value)
          }}>
          <FormControlLabel
            value="none"
            control={<Radio color="primary" />}
            label={t('report:None')}
          />
          {Object.entries(ReportModel.REPEAT_VALUE_TO_LABEL_KEY)
            .map(([value, labelKey], index) => (
              <FormControlLabel
                key={index}
                value={value}
                control={<Radio color="primary" />}
                label={t(labelKey)}
              />
            ))}
          <FormLabel className={styles.hint}>
            {t('report:Only with requirement / User permission')}
          </FormLabel>
          {Object.entries(ReportModel.REPEAT_VALUE_TO_LABEL_KEY_UNSAFE)
            .map(([value, labelKey], index) => (
              <FormControlLabel
                key={index}
                value={value}
                control={<Radio color="primary" />}
                label={t(labelKey)}
              />
            ))}
        </RadioGroup>
      </FormControl>
      <TextField
        fullWidth
        id="comment"
        name="comment"
        label={t('report:Comment')}
        margin="normal"
        inputRef={register}
        defaultValue={isEditPage ? report.comment : null}
      />
      <Select
        inputId="Receiver"
        placeholder={t('report:Select receiver')}
        options={receivers}
        onChange={onReceiverChange}
        defaultValue={
          isEditPage
            ? receivers.find((receiver) => receiver.value === (report.receiver == null ? 'none' : report.receiver))
            : receivers.find((receiver) => receiver.value === availableExports[0])
        }
        TextFieldProps={{
          label: t('report:Receiver'),
          margin: 'normal',
          name: 'receiver',
        }}
      />
      <Button type="submit" variant="contained" color="primary">
        Save
      </Button>
    </form>
  );
};

ReportForm.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string,
    repeat: PropTypes.string,
    reading_date: PropTypes.string,
    set_date: PropTypes.string,
    comment: PropTypes.string,
    operator: OperatorModel.propType,
    building: BuildingModel.propType,
    receiver: PropTypes.string,
  }),
  isEditPage: PropTypes.bool.isRequired,
  createReport: PropTypes.func,
  updateReport: PropTypes.func,
  match: PropTypes.object,
  buildings: PropTypes.arrayOf(SelectOptionModel.propType),
  operators: PropTypes.arrayOf(SelectOptionModel.propType),
  availableExports: PropTypes.arrayOf(PropTypes.string),
};

export default ReportForm;
