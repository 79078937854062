import {useMutation, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Table from '../components/Table';
import {Box, makeStyles} from '@material-ui/core';
import SensorsMissingAes from './Devices/SensorsMissingAes';
import ManufacturersList from './Devices/ManufacturersList';

const GET_DEVICE_TYPES = gql`
  query getDeviceTypes {
    deviceTypes {
      id
      internal_device_type_code
      user_device_type
      sensors_count
    }
  }
`;

const SET_DEVICE_TYPE = gql`
  mutation setDeviceType(
    $userDeviceType: String!
    $internalDeviceTypeCode: Int!
  ) {
    setDeviceType(
      user_device_type: $userDeviceType
      internal_device_type_code: $internalDeviceTypeCode
    )
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

export default function DeviceTypes() {
  const {t} = useTranslation(['devices']);
  const {loading, error, data} = useQuery(GET_DEVICE_TYPES);
  const [setDeviceType] = useMutation(SET_DEVICE_TYPE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_DEVICE_TYPES,
      },
    ],
  });
  const classes = useStyles();
  if (error) {
    return <div>Error</div>;
  }
  return (
    <div className={classes.root}>
      <Box>
        <SensorsMissingAes />
      </Box>
      <Box>
        <Table
          title={t('devices:Device Types')}
          tableId={'device-types'}
          isLoading={loading}
          columns={[
            {
              title: t('devices:Standard device type'),
              field: 'internal_device_type_code',
              lookup: {
                0x2: 'Electricity meter',
                0x3: 'Gas meter',
                0x4: 'Heat meter',
                0x6: 'Warm water meter (30°C ... 90°C)',
                0x7: 'Water meter',
                0x8: 'Heat Cost Allocator',
                0x0a: 'Cooling meter (Volume measured at return temperature: outlet)',
                0x0b: 'Cooling meter (Volume measured at flow temperature: inlet)',
                0x0c: 'Heat meter (Volume measured at flow temperature: inlet)',
                0x0d: 'Combined Heat / Cooling meter',
                0x15: 'Hot water meter (≥ 90°C)',
                0x16: 'Cold water meter',
                0x28: 'Waste water meter',
                0x20: 'Breaker (electricity)',
                0x21: 'Valve (gas or water)',
                0x25: 'Customer unit (display device)',
                0x31: 'Communication controller',
                0x32: 'Unidirectional repeater',
                0x33: 'Bidirectional repeater',
                0x36: 'Radio converter (system side)',
                0x37: 'Radio converter (meter side)',
                0x1a: 'Smoke detector',
                0x0: 'Ignore',
                [-0x1]: 'Gateway',
                [-0x2]: 'Webdyn Gateway',
                [-0x3]: 'Engelmann Gateway',
              },
            },
            {
              title: t('devices:User device type'),
              field: 'user_device_type',
              editable: 'never',
            },
            {
              title: t('devices:Sensors count'),
              field: 'sensors_count',
              editable: 'never',
            },
          ]}
          data={loading ? [] : data.deviceTypes}
          editable={{
            onRowUpdate: async (newData) => {
              const {internal_device_type_code} = newData;
              if (!internal_device_type_code) {
                return;
              }
              await setDeviceType({
                variables: {
                  internalDeviceTypeCode: parseInt(
                    internal_device_type_code,
                    10,
                  ),
                  userDeviceType: newData.user_device_type,
                },
              });
            },
          }}
          options={{
            pageSize: 20,
          }}
        />
      </Box>
      <Box>
        <ManufacturersList />
      </Box>
    </div>
  );
}
