// @format

export default {
  save: (file, filename, mimeType) => {
    let data = file;
    if (typeof data === 'string') {
      data = new Blob([data], {type: mimeType});
    }
    if (file.type === 'Buffer') {
      data = new Blob([new Uint8Array(file.data).buffer]);
    }
    if (typeof window.chrome !== 'undefined') {
      // Chrome version
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = filename;
      link.click();
    } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE version
      const blob = new Blob([data], {type: mimeType || 'text/plain'});
      window.navigator.msSaveBlob(blob, filename);
    } else {
      // Firefox version
      const uploadFile = new File([data], filename, {
        type: 'application/force-download',
      });
      window.open(URL.createObjectURL(uploadFile));
    }
  },
};
