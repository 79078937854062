import React, {useReducer, createContext, useContext} from 'react';
import {useLazyQuery, useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

const initialState = {
  id: '',
  email: '',
  role: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SAVE_ME': {
      const {id, email, role, organization} = action.payload;
      return {
        id,
        email,
        role,
        organizationId: organization.id,
      };
    }
    case 'RESET':
    default:
      return initialState;
  }
};

const MeContext = createContext();

export const GET_ME = gql`
  query getMe {
    me {
      id
      email
      role
      organization {
        id
      }
    }
  }
`;

export const useMeQuery = (dispatch, options = {}) => {
  return useQuery(GET_ME, options);
};

export const useMeLazyQuery = (dispatch, options = {}) => {
  return useLazyQuery(GET_ME, options);
};

export const useMe = () => {
  return useContext(MeContext);
};

export const MeProvider = ({children}) => {
  const contextValue = useReducer(reducer, initialState);

  return (
    <MeContext.Provider value={contextValue}>{children}</MeContext.Provider>
  );
};

export const MeConsumer = MeContext.Consumer;
