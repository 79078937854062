import React from 'react';
import useReactRouter from 'use-react-router';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';

import Customer from './Customer';
import Table from '../components/Table';

const GET_CUSTOMERS = gql`
  query getCustomers($ids: [ID]) {
    customers(ids: $ids) {
      id
      email
      role
      profile {
        id
        first_name
        last_name
        phone
      }
    }
  }
`;

const ADD_CUSTOMER = gql`
  mutation addCustomer(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phone: String
  ) {
    addCustomer(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      phone: $phone
    ) {
      id
      email
      role
      profile {
        id
        first_name
        last_name
        phone
      }
    }
  }
`;

const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      id
    }
  }
`;

const Content = ({match}) => {
  const {t} = useTranslation(['user']);
  const {history} = useReactRouter();
  const {loading, error, data} = useQuery(GET_CUSTOMERS, {});
  const [addCustomer] = useMutation(ADD_CUSTOMER, {
    update: (cache, {data: {addCustomer}}) => {
      const {customers} = cache.readQuery({query: GET_CUSTOMERS});

      cache.writeQuery({
        query: GET_CUSTOMERS,
        data: {
          customers: customers.concat(addCustomer),
        },
      });
    },
  });
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER, {
    update: (cache, {data: {deleteCustomer}}) => {
      const {customers} = cache.readQuery({query: GET_CUSTOMERS});

      cache.writeQuery({
        query: GET_CUSTOMERS,
        data: {
          customers: customers.filter((user) => user.id !== deleteCustomer.id),
        },
      });
    },
  });

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('user:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {title: t('user:E-mail'), field: 'email'},
    {
      title: t('user:First Name'),
      field: 'profile.first_name',
    },
    {
      title: t('user:Last Name'),
      field: 'profile.last_name',
    },
    {
      title: t('user:Phone'),
      field: 'profile.phone',
    },
    {
      title: t('user:Password'),
      field: 'password',
      render: () => '*************',
    },
  ];

  return (
    <React.Fragment>
      <Table
        tableId="customers"
        title=""
        isLoading={loading}
        columns={columns}
        data={loading ? [] : data.customers}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        onRowClick={(event, rowData) => {
          history.push(`${match.path}/${rowData.id}`);
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addCustomer({
              variables: {
                email: newData.email,
                password: newData.password,
                firstName: newData.profile.first_name,
                lastName: newData.profile.last_name,
                phone: newData.profile.phone,
              },
            });
          },
          onRowDelete: async (oldData) => {
            await deleteCustomer({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default function Customers({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/:id`} component={Customer} />
    </React.Fragment>
  );
}
