import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import fp from 'lodash/fp';
import gql from 'graphql-tag';
import {Route, Switch} from 'react-router-dom';
import ErrorStatus from '../components/ErrorStatus';
import UviInfo from './Uvi/Info';
import UviTenants from './Uvi/Tenants';
import UviReports from './Uvi/Reports';

const GET_UVI_BUILDINGS = gql`
  query getUviBuildings($ids: [ID], $apIds: [ID]) {
    uviBuildings(ids: $ids) {
      id
      street
      house_number
      city
      alternate_address
      postalcode
      external_id
      apartments(ids: $apIds) {
        id
        external_name
        external_name2
        external_number
        location
        comment
        permissions(activated: true) {
          id
          start_date
          end_date
          user {
            id
            email
            profile {
              id
              first_name
              last_name
              phone
              street1
              street2
              postal_code
              city
            }
          }
        }
      }
    }
  }
`;

const Content = ({match}) => {
  const {loading, error, data, refetch} = useQuery(GET_UVI_BUILDINGS, {
    variables: {
      ids: [match.params.id],
      apIds: [match.params.apartmentId],
    },
  });
  const isLoading = loading;
  const building = isLoading ? {} : fp.first(data.uviBuildings);
  const apartment = isLoading
    ? {}
    : fp.find((item) => {
        return item.id === match.params.apartmentId;
      }, building.apartments) || {};

  if (error) {
    return <ErrorStatus />;
  }
  return (
    <React.Fragment>
      <UviInfo
        building={building}
        apartment={apartment}
        isLoading={isLoading}
      />
      <UviTenants
        permissions={apartment.permissions || []}
        isLoading={isLoading}
        apartmentId={apartment.id}
        onUpdate={(permission) => {
          refetch();
        }}
      />
      {apartment.id && (
        <UviReports apartmentId={apartment.id} isLoading={isLoading} />
      )}
    </React.Fragment>
  );
};

export default function Uvi({match}) {
  return (
    <Switch>
      <Route exact path={match.path} component={Content} />
    </Switch>
  );
}
