// @format
import React from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import {Route} from 'react-router-dom';

import Table from '../components/Table';

const GET_OPERATORS = gql`
  query getOperators($ids: [ID]) {
    operators(ids: $ids) {
      id
      email
    }
  }
`;

const ADD_OPERATOR = gql`
  mutation addOperator($email: String!, $password: String!) {
    addOperator(email: $email, password: $password) {
      id
      email
    }
  }
`;

const UPDATE_OPERATOR = gql`
  mutation updateOperator($id: ID!, $email: String!, $password: String!) {
    updateOperator(id: $id, email: $email, password: $password) {
      id
      email
    }
  }
`;

const DELETE_OPERATOR = gql`
  mutation deleteOperator($id: ID!) {
    deleteOperator(id: $id) {
      id
    }
  }
`;

const Content = ({match}) => {
  const {t} = useTranslation(['operator']);
  const {loading, error, data} = useQuery(GET_OPERATORS, {});
  const [updateOperator] = useMutation(UPDATE_OPERATOR);
  const [addOperator] = useMutation(ADD_OPERATOR, {
    update: (cache, {data: {addOperator}}) => {
      const {operators} = cache.readQuery({query: GET_OPERATORS});

      cache.writeQuery({
        query: GET_OPERATORS,
        data: {
          operators: operators.concat(addOperator),
        },
      });
    },
  });
  const [deleteOperator] = useMutation(DELETE_OPERATOR, {
    update: (cache, {data: {deleteOperator}}) => {
      const {operators} = cache.readQuery({query: GET_OPERATORS});

      cache.writeQuery({
        query: GET_OPERATORS,
        data: {
          operators: operators.filter(
            (operator) => operator.id !== deleteOperator.id,
          ),
        },
      });
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('operator:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {title: t('operator:E-mail'), field: 'email'},
    {
      title: t('operator:Password'),
      field: 'password',
      render: () => '*************',
    },
  ];

  return (
    <React.Fragment>
      <Table
        tableId="operators"
        title=""
        isLoading={loading}
        columns={columns}
        data={loading ? [] : data.operators}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addOperator({
              variables: {
                email: newData.email,
                password: newData.password,
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            await updateOperator({
              variables: {
                id: oldData.id,
                email: newData.email,
                password: newData.password || '',
              },
            });
          },
          onRowDelete: async (oldData) => {
            await deleteOperator({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default function Operators({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
