import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import fp from 'lodash/fp';
import gql from 'graphql-tag';
import {useSnackbar} from 'notistack';
import {useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import Table from '../../components/Table';
import {TypePermission} from '../../components/User/Type';

const useStyles = makeStyles((theme) => ({
  dataBox: {
    margin: '0 0 1rem 0',
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
  tableToolbar: {
    flexWrap: 'wrap-reverse',
  },
  newItemButtonWrapper: {
    flex: 1,
  },
}));

const ASSIGN_TENANT = gql`
  mutation assignTenant($permissionId: ID, $permissionData: JSON!) {
    assignTenant(permissionId: $permissionId, permissionData: $permissionData) {
      id
      start_date
      end_date
      user {
        id
        email
        profile {
          first_name
          last_name
          phone
          id
        }
      }
    }
  }
`;

const UPADTE_TENANT = gql`
  mutation updateTenant($permissionId: ID!, $permissionData: JSON!) {
    updateTenant(permissionId: $permissionId, permissionData: $permissionData) {
      id
      start_date
      end_date
      user {
        id
        email
        profile {
          first_name
          last_name
          phone
          id
        }
      }
    }
  }
`;

const REMOVE_TENANT = gql`
  mutation removeTenant($permissionId: ID!, $permissionData: JSON!) {
    removeTenant(permissionId: $permissionId, permissionData: $permissionData)
  }
`;
const Tenants = ({permissions, isLoading, apartmentId, onUpdate}) => {
  const classes = useStyles();
  const {t} = useTranslation(['uvi', 'building', 'user']);
  const [assignTenant] = useMutation(ASSIGN_TENANT, {});
  const [updateTenant] = useMutation(UPADTE_TENANT, {});
  const [removeTenant] = useMutation(REMOVE_TENANT, {});
  const {enqueueSnackbar} = useSnackbar();
  return (
    <React.Fragment>
      <div>
        <Alert severity="info">{t('uvis:USE_UVI_TENANT_INFO')}</Alert>
      </div>
      <div>
        <Alert severity="warning">{t('uvis:USE_UVI_TENANT_INFO_ALERT')}</Alert>
      </div>
      <Box>
        <Table
          title={
            <div>
              <PersonIcon className={classes.titleIcon} />
              {t('uvis:Tenants')}
            </div>
          }
          tableId={'tenants'}
          isLoading={isLoading}
          columns={[
            {
              title: t('uvis:ID'),
              field: 'user.id',
              type: 'numeric',
              editable: 'never',
            },
            {
              title: t('uvis:Moved In'),
              field: 'start_date',
              defaultSort: 'desc',
              type: 'date',
              render: (rowData) => {
                return fp.get('start_date', rowData)
                  ? moment.utc(rowData.start_date).format('DD MMMM YYYY')
                  : '';
              },
            },
            {
              title: t('building:Moved Out'),
              field: 'end_date',
              type: 'date',
              render: (rowData) => {
                return fp.get('end_date', rowData)
                  ? moment.utc(rowData.end_date).format('DD MMMM YYYY')
                  : '';
              },
            },
            {
              title: t('user:First Name'),
              field: 'user.profile.first_name',
            },
            {
              title: t('user:Last Name'),
              field: 'user.profile.last_name',
            },
            {
              title: t('user:Street 1'),
              field: 'user.profile.street1',
            },
            {
              title: t('user:Street 2'),
              field: 'user.profile.street2',
            },
            {
              title: t('user:ZIP code'),
              field: 'user.profile.postal_code',
            },
            {
              title: t('user:City'),
              field: 'user.profile.city',
            },
            {
              title: t('uvis:Email'),
              field: 'user.email',
              type: 'email',
              editable: 'onAdd',
            },
          ]}
          data={permissions}
          options={{
            sorting: true,
            search: true,
            toolbar: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25],
          }}
          editable={{
            onRowAdd: async (newData) => {
              try {
                const permission = await assignTenant({
                  variables: {
                    permissionData: {...newData, apartment_id: apartmentId},
                  },
                });
                if (onUpdate) {
                  onUpdate(permission);
                }
              } catch (e) {
                console.log(e);
                enqueueSnackbar(t(`uvis:Could not assign tenant`), {
                  variant: 'error',
                });
                return false;
              }
            },
            onRowUpdate: async (newData, oldData) => {
              const permission = await updateTenant({
                variables: {
                  permissionId: oldData.id,
                  permissionData: {...newData, apartment_id: apartmentId},
                },
              });
              if (onUpdate) {
                onUpdate(permission);
              }
            },
            onRowDelete: async (oldData) => {
              const permission = await removeTenant({
                variables: {
                  permissionId: oldData.id,
                  permissionData: {confirmed: true},
                },
              });
              if (onUpdate) {
                onUpdate(permission);
              }
            },
          }}
        />
      </Box>
    </React.Fragment>
  );
};

Tenants.propTypes = {
  permissions: PropTypes.arrayOf(TypePermission).isRequired,
  isLoading: PropTypes.bool,
  apartmentId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
};
export default Tenants;
