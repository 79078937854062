import {Grid, TextField } from "@material-ui/core";
import React from 'react';
import { useTranslation } from "react-i18next";
import useForm from 'react-hook-form';
import Button from '@material-ui/core/Button';
import TelegramModel from '../../model/telegram';
import Card from './Card';

export default function TelegramSearch() {
	const { t } = useTranslation(['dashboard']);
	const {register, handleSubmit} = useForm();

	const onSubmit = (data) => {
		const url = `/telegrams?${TelegramModel.serialNumberParameter}=${data.sensorSerialNumber}`;
		window.open(url, '_blank');
	};

	return (
		<Grid item xs={12} md={3}>
			<Card title={t("dashboard:Telegram Search")}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextField
					fullWidth
					type="number"
					id="sensorSerialNumber"
					name="sensorSerialNumber"
					label={t('dashboard:Sensor serial number')}
					margin="normal"
					defaultValue={null}
					inputRef={register}
				/>
				<Button
					fullWidth
					type="submit"
					variant="contained"
					color="primary">
					{t('dashboard:Search')}
				</Button>
				</form>
			</Card>
		</Grid>
	);
}
