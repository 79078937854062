// @format
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const LoadComponent = (props) => {
  if (props.loading) {
    return <CircularProgress />
  }
  return (
    <>
      {props.children}
    </>
  );
}
LoadComponent.propTypes = {
  children: PropTypes.element,
  loading: PropTypes.bool,
}
export default LoadComponent;