import React from 'react';
import Alert from '@material-ui/lab/Alert';
import ReportError from '../../model/report-error';
import ErrorSensorConflict from './ErrorAlert/ErrorSensorConflict';
import ErrorSensorSetDate from './ErrorAlert/ErrorSensorSetDate';
import ErrorSensorSwappedSerial from './ErrorAlert/ErrorSensorSwappedSerial';

const ErrorUnknown = ({error}) => {
  return (
    <Alert title={error.message} severity="error">
      {error.message}
    </Alert>
  );
};
ErrorUnknown.propTypes = {
  error: ReportError.propType,
};

const ErrorAlert = ({error}) => {
  switch (error.type) {
    case ReportError.ERROR_CONFLICT_SENSORS:
      return <ErrorSensorConflict error={error} />;
    case ReportError.ERROR_HVE_SET_DATE:
      return <ErrorSensorSetDate error={error} />;
    case ReportError.ERROR_CONFLICT_IDENTICAL_SENSORS:
      return <ErrorSensorConflict error={error} />;
    case ReportError.ERROR_SWAPPED_SERIAL_NUMBER:
      return <ErrorSensorSwappedSerial error={error}/>;
    default:
      return <ErrorUnknown error={error} />;
  }
};
ErrorAlert.propTypes = {
  error: ReportError.propType,
};

export default ErrorAlert;
