import PropTypes from 'prop-types';

export default {
  ERROR_UNKNOWN: 'ERROR_UNKNOWN',
  ERROR_CONFLICT_SENSORS: 'ERROR_CONFLICT_SENSORS',
  ERROR_HVE_SET_DATE: 'ERROR_HVE_SET_DATE',
  ERROR_CONFLICT_IDENTICAL_SENSORS: 'ERROR_CONFLICT_IDENTICAL_SENSORS',
  ERROR_SWAPPED_SERIAL_NUMBER: 'ERROR_SWAPPED_SERIAL_NUMBER',
  propType: PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    details: PropTypes.object.isRequired,
  }),
};
