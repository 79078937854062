import React from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useTranslation} from 'react-i18next';
import gql from 'graphql-tag';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '../../components/Table';

const GET_INTEGRATION_FTP_CONFIGS = gql`
  query getIntegrationFtpConfigs($ids: [ID], $type: Int) {
    integrationFtpConfigs(ids: $ids, type: $type) {
      id
      user
      host
      port
      type
      directory
    }
  }
`;

const ADD_INTEGRATION_FTP_CONFIG = gql`
  mutation addIntegrationFtpConfig(
    $user: String!
    $password: String!
    $host: String!
    $port: Int!
    $type: Int!
    $directory: String
  ) {
    addIntegrationFtpConfig(
      user: $user
      password: $password
      host: $host
      port: $port
      type: $type
      directory: $directory
    ) {
      id
      user
      host
      port
      type
      directory
    }
  }
`;

const UPDATE_INTEGRATION_FTP_CONFIG = gql`
  mutation updateIntegrationFtpConfig(
    $id: ID!
    $user: String!
    $password: String
    $host: String!
    $port: Int!
    $type: Int!
    $directory: String
  ) {
    updateIntegrationFtpConfig(
      id: $id
      user: $user
      password: $password
      host: $host
      port: $port
      type: $type
      directory: $directory
    ) {
      id
      user
      host
      port
      type
      directory
    }
  }
`;

const DELETE_INTEGRATION_FTP_CONFIG = gql`
  mutation deleteIntegrationFtpConfig($id: ID!) {
    deleteIntegrationFtpConfig(id: $id) {
      id
    }
  }
`;

const FtpViewTable = ({ match, configType, tableId }) => {
  const {t} = useTranslation(['integrationConfig']);
  const {loading, error, data} = useQuery(GET_INTEGRATION_FTP_CONFIGS, {
    variables: {
      type: configType,
    },
  });
  const [updateIntegrationFtpConfig] = useMutation(UPDATE_INTEGRATION_FTP_CONFIG);
  const [addIntegrationFtpConfig] = useMutation(ADD_INTEGRATION_FTP_CONFIG, {
    update: (cache, {data: {addIntegrationFtpConfig}}) => {
      const {integrationFtpConfigs} = cache.readQuery({
        query: GET_INTEGRATION_FTP_CONFIGS,
        variables: {
          type: configType,
        },
      });

      cache.writeQuery({
        query: GET_INTEGRATION_FTP_CONFIGS,
        variables: {
          type: configType,
        },
        data: {
          integrationFtpConfigs: integrationFtpConfigs.concat(
            addIntegrationFtpConfig,
          ),
        },
      });
    },
  });
  const [deleteIntegrationFtpConfig] = useMutation(DELETE_INTEGRATION_FTP_CONFIG, {
    update: (cache, {data: {deleteIntegrationFtpConfig}}) => {
      const {integrationFtpConfigs} = cache.readQuery({
        query: GET_INTEGRATION_FTP_CONFIGS,
        variables: {
          type: configType,
        },
      });

      cache.writeQuery({
        query: GET_INTEGRATION_FTP_CONFIGS,
        variables: {
          type: configType,
        },
        data: {
          integrationFtpConfigs: integrationFtpConfigs.filter(
            (integrationFtpConfig) =>
              integrationFtpConfig.id !== deleteIntegrationFtpConfig.id,
          ),
        },
      });
    },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('integrationConfig:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {title: t('integrationConfig:User'), field: 'user'},
    {
      title: t('integrationConfig:Password'),
      field: 'password',
      render: () => '*************',
    },
    {title: t('integrationConfig:Host'), field: 'host'},
    {title: t('integrationConfig:Port'), field: 'port'},
    {title: t('integrationConfig:Folder'), field: 'directory'},
  ];

  return (
    <React.Fragment>
      <Table
        tableId={tableId}
        title=""
        columns={columns}
        data={data.integrationFtpConfigs}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addIntegrationFtpConfig({
              variables: {
                user: newData.user,
                password: newData.password,
                host: newData.host,
                port: Number(newData.port),

                type: configType,
                directory: newData.directory,
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            await updateIntegrationFtpConfig({
              variables: {
                id: oldData.id,
                user: newData.user,
                host: newData.host,
                port: Number(newData.port),

                type: configType,
                ...(!!newData.password && {password: newData.password}),
                directory: newData.directory,
              },
            });
          },
          onRowDelete: async (oldData) => {
            await deleteIntegrationFtpConfig({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default FtpViewTable;
