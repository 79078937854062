// @format
export default {
	layout: {
		"/": "Anmeldung",
		"/login": "Anmeldung",
		"/reports": "Ablesungen",
		"/buildings": "Gebäude",
		"/sensors": "Sensoren",
		"/sensor-keys": "AES-Schlüssel",
		"/gateways": "Gateways",
		"/ftp-configs": "FTP-Konfigurationen",
		"/integration-configs": "Integrationen",
		"/operators": "Ableser",
		"/customers": "Nutzer",
		"/users": "Benutzer",
		"/settings": "Einstellungen",
		"/billing": "Billing", // FIXME:
		"/uvis": "UVI",
		"/dashboard": "Dashboard", // FIXME:
		"/telegrams": "Telegramme",
		"/devices": "Geräte",
		Menu: "Menu",
		Settings: "Einstellungen",
		Logout: "Logout",
		Administration: "Verwaltung",
	},
	table: {
		"No records to display": "Keine Datensätze anzeigen",
		Add: "Hinzufügen",
		Delete: "Löschen",
		Edit: "Bearbeiten",
		Filter: "Filter",
		"Are you sure delete this row?":
			"Sind Sie sicher, dass Sie diese Zeile löschen wollen?",
		Cancel: "Abbrechen",
		Save: "Speichern",
		placeholder: "Platzhalter",
		Actions: "Aktionen",
		"{from}-{to} of {count}": "{from}-{to} von {count}",
		rows: "Zeilen",
		"Rows per page": "Zeilen pro Seite:",
		"First Page": "Erste Seite",
		"Previous Page": "Vorherige Seite",
		"Next Page": "Nächste Seite",
		"Last Page": "Letzte Seite",
		"Add or remove columns": "Spalten hinzufügen oder entfernen",
		"{0} row(s) selected": "{0} Zeile(n) ausgewählt",
		"Show Columns": "Spalten anzeigen",
		Export: "Exportieren",
		"Export as CSV": "Als CSV exportieren",
		Search: "Suche",
	},
	errors: {
		"Invalid e-mail": "Ungültige E-Mail",
		"Invalid password": "Ungültiges Passwort",
	},
	login: {
		"E-mail Address": "E-mail Addresse",
		Password: "Passwort",
		"E-mail can't be blank": "E-Mail kann nicht leer sein",
		"Password can't be blank": "Passwort kann nicht leer sein",
		"Password must have more than 6 characters":
			"Das Passwort muss mehr als 6 Zeichen haben",
		"Log in": "Anmelden",
		Admin: "Admin",
	},
	reportErrors: {
		"Duplicate serial number": "Doppelte Seriennummer",
		"Identical sensors": "Identische Sensoren",
	},
	report: {
		"Main reports": "Ablesungen",
		New: "Neu",
		Open: "Offen",
		"In Progress": "In Bearbeitung",
		Submitted: "Eingereicht",
		Confirmed: "Importiert",
		"Marked Wrong": "Papierkorb",
		ID: "ID",
		ID1: "Gerätenummer 1",
		ID4: "ID4",
		Status: "Status",
		"Creation Date": "Erstellungsdatum",
		"Update Date": "Aktualisierungsdatum",
		"Reading Date": "Ablesedatum",
		"Submission Date": "Einreichungsdatum",
		"Set Date": "Stichtag",
		"Building Address": "Gebäudeadresse",
		"Alternate Address": "Alternative Adresse",
		"ZIP code": "PLZ",
		City: "Stadt",
		"Sensors Count": "Anzahl Geräte",
		"Meeters Count": "Anzahl Zähler",
		"Serials Count": "Anzahl Seriennummern",
		"New Report": "Neue Ablesung",
		"Report not found": "Ablesung nicht gefunden",
		"Report {{id}}": "Ablesung {{id}}",
		Report: "Ablesung",
		"After DATE": "Von DATE",
		"Before DATE": "Bis DATE",
		Yes: "Ja",
		No: "Nein",
		"less than 80%": "unter 80%",
		"less than 50%": "unter 50%",
		Operator: "Monteur",
		"Operator comment": "Kommentar des Ablesers",
		"Last Seen": "Last Seen",
		Comment: "Kommentar",
		Sensors: "Sensoren",
		Serials: "Seriennummern",
		Statistics: "Statistik",
		"Set date radio measurements": "Stichtag Messwerte Funk",
		"Reading date radio measurements": "Ablesedatum Messwerte Funk",
		"Reading date hand measurements": "Ablesedatum Messwerte Hand",
		"Sensors with telegram count": "Sensoren mit Telegramm",
		"Radio Collected Count": "Messwerte Funk",
		"Hand Collected Count": "Messwerte Hand",
		"{{colledCount}} of {{sensorsCount}}":
			"{{colledCount}} von {{sensorsCount}}",
		"{{radioCollectedCount}} of {{serialsCount}}":
			"{{radioCollectedCount}} von {{serialsCount}}",
		Download: "Herunterladen",
		Edit: "Bearbeiten",
		Confirm: "Status importiert",
		"Building sensors": "Gebäude-Sensoren",
		"Apartment sensors": "Wohnungssensoren",
		"Go to Building": "Gehen Sie zum Gebäude",
		"Open map": "Karte öffnen",
		"Refresh related Telegrams": "Aktualisieren Sie zugehörige Telegramme",
		"This operation will schedule re-processing all measurements of telegrams from this report. Are you sure?":
			"Diese Operation plant die erneute Verarbeitung aller Messungen von Telegrammen aus diesem Bericht. Bist du sicher?",
		Refresh: "Aktualisieren",
		"Add sensors to report": "Sensoren zum Ablesung hinzufügen",
		"All sensors": "Alle Sensoren",
		Readings: "Ablesungen",
		"Serial Number": "Seriennummer",
		"Radio Measurement Value": "Funk-Messwert",
		"Radio Measurement Date": "Datum der Funkmessung",
		"Hand Measurement Value": "Hand-Messwert",
		"Hand Measurement Date": "Datum der Handmessung",
		"Sensor ID1": "Sensor Gerätenummer 1",
		"Sensor Type": "Sensortyp",
		"AES Key": "AES-Schlüssel",
		"Is active sensor": "Ist aktiver Sensor",
		"Telegram exists": "Telegramm existiert",
		"Telegram decrypted": "Telegramm entschlüsselt",
		"Show telegrams": "Telegramme anzeigen",
		"Telegrams for": "Telegramme für",
		"Captured at": "Gesammelt bei",
		"Captured by": "Gesammelt von",
		"Gateway ID": "Gateway ID",
		Manufacturer: "Hersteller",
		"Device type": "Gerätetyp",
		"Select building": "Gebäude auswählen",
		"Select operator": "Ableser auswählen",
		"Select reading date": "Ablesedatum auswählen",
		"Select set date": "Stichtag auswählen",
		"Reading date": "Ablesedatum",
		"Set date": "Stichtag",
		Building: "Gebäude",
		Month: "Stichtag Monatsende",
		Year: "Stichtag Jahresende",
		"Automatic resolve": "Automatische Auflösung",
		"No conflicts which can be resolved automatically":
			"Keine Konflikte, die automatisch aufgelöst werden können",
		"Resolved conflicts for {{number}} sensors":
			"Konflikte für {{number}} Sensoren gelöst",
		"Building ID1": "Building External Number",
		"Sensor %": "Gesamt %",
		"Serial %": "Funk %",
		"Only with requirement / User permission":
			"Nur mit Anforderung / Benutzererlaubnis",
		RSSI: "RSSI",
		Location: "Standort",
		"User number": "Nutzernummer",
		"Telegram %": "Telegram Report %",
		'Telegrams Change (%)': 'Telegrams Change (%)',
		"Total Telegram %": "Total Telegram %",
		"{{telegramCollectedCount}} of {{serialsCount}}":
			"{{telegramCollectedCount}} von {{serialsCount}}",
		'Send it to Operator': 'An den Operator senden',
	},
	building: {
		ID4: "ID4",
		Street: "Straße",
		Number: "Nummer",
		City: "Stadt",
		Zipcode: "PLZ",
		"Alternate Address": "Alternative Addresse",
		"Sensors Count": "Anzahl Geräte",
		"Meeters Count": "Anzahl Zähler",
		"Serials Count": "Anzahl Seriennummern",
		Refresh: "Aktualisieren",
		Delete: "Löschen",
		Pricing: "Preisgestaltung",
		ID: "ID",
		Year: "Jahr",
		"Heat Variable": "Heizung Variablekosten",
		"Heat Fixed": "Heizung Fixkosten",
		"Water Variable": "Wasser Variablekosten",
		"Water Fixed": "Wasser Fixkosten",
		Sensors: "Sensoren",
		ID1: "Building External Number",
		"External Number": "External Nummer",
		"Set Date": "Stichtag",
		"Update Date": "Aktualisierungsdatum",
		Status: "Status",
		Name: "Name",
		Name2: "Name 2",
		Factor: "Faktor",
		Type: "Typ",
		"Serial Number": "Serialnummer",
		Comment: "Kommentar",
		"Installed Date": "Einbaudatum",
		"Uninstalled Date": "Ausbaudatum",
		Apartments: "Wohnungen",
		Location: "Standort",
		"Area Heating": "Heizungfläche",
		"Area Water": "Wasserfläche",
		"Could not upload the file": "Konnte die Datei nicht hochladen",
		"File uploaded successfully": "Datei erfolgreich hochgeladen",
		"Upload Routeml": "Routeml hochladen",
		"Import method": "Importmethode",
		"There are duplicate sensors in this file":
			"Es gibt doppelte Sensoren in dieser Datei",
		"Apartment guid": "Wohnung-guid",
		"Duplicate sensors guid": "Doppelte Sensor-guid",
		"Marked Wrong": "Papierkorb",
		"Main buildings": "Gebäude",
		"The building is going to be deleted": "Das Gebäude wird gelöscht",
		Proceed: "Fortfahren",
		Cancel: "Absagen",
	},
	sensorKey: {
		ID: "ID",
		"Serial Number": "Serialnummer",
		"AES Key": "AES-Schlüssel",
		"Upload CSV": "CSV Hochladen",
		"Upload CSV Help": "CSV Hochladen Hilfe",
		"upload-instructions":
			"Die csv-Datei muss den folgenden Header haben: serial_number;aes_key. Beachten Sie, dass es sich um durch Semikolon getrennte Werte handeln sollte",
		Understood: "Verstanden",
		Manufacturer: "Hersteller",
		"Individual keys": "Individuelle Schlüssel",
		"Global keys": "Globale Schlüssel",
	},
	gateway: {
		ID: "ID",
		ICCID: "ICCID",
		IMEI: "IMEI",
		"Serial Number": "Seriennummer",
		Manufacturer: "Hersteller",
		"Device type": "Gerätetyp",
		Model: "Modell",
		"Last Reading": "Letzte Ablesung",
		Online: "Online",
		"Building Address": "Gebäudeadresse",
		Comment: "Kommentar",
		"Choose a building": "Wählen Sie ein Gebäude",
		"ICCID is required": "ICCID ist erforderlich",
		"IMEI is required": "IMEI ist erforderlich",
		"Serial number is required": "Seriennummer ist erforderlich",
		"Manufacturer is required": "Manufacturer ist erforderlich",
		"Model is required": "Model ist erforderlich",
		"Select building": "Gebäude auswählen",
		Building: "Gebäude",
		Save: "Speichern",
		"Gateway not found": "Gateway nicht gefunden",
		"IMEI must have 15 numbers": "IMEI muss 15 Nummern haben",
		"ICCID must have 22 numbers": "ICCID muss 20-22 Nummern haben",
		"Could not save the gateway": "Konnte das Gateway nicht speichern",
		Organization: "Organisation",
		"Choose an organization": "Organisation auswählen",
		"Organization is required": "Organization ist erforderlich",
		"Select manufacturer": "Hersteller wählen",
		"telegrams for gateway": "Telegramme für Gateway",
		"Sensor serial number": "Sensor Serialnummer",
		"Captured at": "Gesammelt bei",
		"Telegram decrypted": "Telegramm entschlüsselt",
		"Show telegrams": "Telegramme anzeigen",
		"Telegrams for gateway": "Telegrams für Gateway",
		"Year and month": "Jahr und Monat",
		Battery: "Batterie",
		RSSI: "RSSI",
		Operator: "Monteur",
		"Building ID1": "Building External Number",
		"Upload CSV": "CSV Hochladen",
		"Upload CSV Help": "CSV Hochladen Hilfe",
		"upload-instructions":
			"Die csv-Datei muss den folgenden Header haben: serial_number;imei;manufacturer;customer_name;iccid. Beachten Sie, dass es sich um durch Semikolon getrennte Werte handeln sollte",
		Understood: "Verstanden",
		"Gateways in Operations": "Gateways im Betrieb",
		"Gateways Backlog": "Gateways Backlog",
		"Gateway updated successfully": "Gateway erfolgreich aktualisiert",
    'Gateway updated successfully': 'Gateway erfolgreich aktualisiert',
	},
	ftpConfig: {
		ID: "ID",
		User: "User",
		Password: "Passwort",
		Host: "Host",
		Port: "Port",
		Setup: "Setup",
		Folder: "Ordner",
	},
	integrationConfig: {
		ID: "ID",
		User: "User",
		Password: "Passwort",
		"Api Key": "Api Key",
		Host: "Host",
		Port: "Port",
		Type: "Typ",
	},
	operator: {
		ID: "ID",
		"E-mail": "E-mail",
		Password: "Passwort",
		"First Name": "Vorname",
		"Last Name": "Nachname",
		Phone: "Telefonnummer",
	},
	user: {
		ID: "ID",
		"E-mail": "E-mail",
		Password: "Passwort",
		"New password": "Neues Passwort",
		Role: "Rolle",
		"First Name": "Vorname",
		"Last Name": "Nachname",
		Phone: "Telefonnummer",
		Apartment: "Wohnung",
		Building: "Gebäude",
		"Choose an apartment": "Wähle eine Wohnung",
		"Choose a building": "Wähle ein Gebäude",
		"Choose a role": "Wähle eine Rolle",
		Save: "Speichern",
		Details: "Details",
		"Could not save user": "Konnte den Benutzer nicht speichern",
		"User info saved": "Benutzerinformationen gespeichert",
		"Could not add the apartment": "Konnte die Wohnung nicht hinzufügen",
		"Apartment added to user": "Wohnung zum Benutzer hinzugefügt",
		"Start date": "Start date",
		"End date": "End date",
		"Change date": "Datum ändern",
		"Street 1": "Strasse 1",
		"Street 2": "Strasse 2",
		"ZIP code": "PLZ",
		City: "Ort",
		roles: {
			1: "Nutzer",
			2: "Ableser",
			3: "Admin-Meister",
			4: "Admin-Benutzer",
			10: "Hausverwaltung",
		},
	},
	sensor: {
		types: {
			RM: "Rauchmelder",
			WWZ: "Warmwasserzähler",
			KWZ: "Kaltwasserzähler",
			WMZ: "Wärmemengenzähler",
			HKV: "Heizkostenverteiler",
			HVE: "Heizungszuteiler Elektronischer",
			HVV: "Heizungszuteiler Verdampfer",
		},
		statuses: {
			0: "Offline",
			1: "Online",
			10: "Deaktivierter",
			20: "Unbenutzter",
		},
	},
	settings: {
		Language: "Sprache",
	},
	organization: {
		ID: "ID",
		ID1: "Gerätenummer 1",
		Building: "Gebäude",
		Buildings: "Gebäude",
		Users: "Benutzer",
		Name: "Name",
		Street: "Straße",
		Number: "Nummer",
		City: "Stadt",
		"ZIP code": "PLZ",
		Phone: "Telefonnummer",
		"E-mail": "E-mail",
		"Update Date": "Aktualisierungsdatum",
	},
	telegram: {
		"Telegram is not decrypted": "Telegramm ist nicht entschlüsselt",
		Header: "Header",
		Key: "Key",
		Value: "Wert",
		Values: "Werte",
		Unit: "Maßeinheit",
		Type: "Typ",
	},
	uvis: {
		USE_UVIS_INFO:
			"Bitte klicken Sie auf die Liegenschaft, um Eigentümer und Bewohner für den Versand der UVI zu erfassen.",
		USE_UVIS_INFO_ALERT:
			"ACHTUNG: Für den Schutz personenbezogener Daten, müssen Sie die Empfänger der UVI stets auf dem aktuellen Stand halten.",
		USE_UVI_BUILDING_INFO:
			"Bitte geben Sie mindestens einen UVI Empfänger pro Nutzeinheit für den monatlichen Versand per E-Mail an.",
		USE_UVI_BUILDING_INFO_IMPORTANT:
			"Wichtig: Aus datenschutzrechtlichen Gründen müssen Sie die Empfänger der UVI stets auf dem aktuellen Stand halten.",
		"Missing UVI receivers": "Fehlende UVI Empfänger",
		"Number of UVI recipients": "Anzahl UVI Empfänger",
		USE_UVI_TENANT_INFO:
			"Bitte geben Sie mindestens einen UVI Empfänger pro Nutzeinheit für den monatlichen Versand per E-Mail oder Brief an. Der Versand per E-Mail erfolgt automatisiert. Für den Postversand können Sie die UVI mit der erfassten Adresse herunterladen.",
		USE_UVI_TENANT_INFO_ALERT:
			"Wichtig: Aus datenschutzrechtlichen Gründen müssen Sie die Empfänger der UVI stets auf dem aktuellen Stand halten.",
		"UVI Coverage": "Empfang",
		"Send reports": "Berichte senden",
		Scheduled: "Geplant",
		Error: "Fehler",
		Statistic: "Statistik",
	},
	dashboard: {
		"Sensor Conflicts": "Identische Gerätenummer Funk in Gebäuden",
		"Sensor Fails": "Gerät mit Fehlercode",
		"AMR Coverage": "Status Funkempfang",
		"Telegram Search": "Funktelegramm Suche",
		"Sensor serial number": "Gerätenummer",
		"Invalid Serials": "Ungültige Gerätenummer",
		"Missing AES Keys": "Fehlende AES Schlüssel",
		"Device Counters": "Anzahl Geräte",
		"AMR Report count": "Anzahl Ablesungen",
		"AMR Report Min coverage": "Funkempfang ab",
		"AMR Report Max coverage": "Funkempfang bis",
		Status: "Status",
		"Gateways (growth rate in last 6 months)":
			"Gateways (6 M Wachstumsrate)",
		"Wireless Devices (growth rate in last 6 months)":
			"Drahtlose Geräte (6 M Wachstumsrate)",
		"Building (Growth Rate in last 6 months)":
			"Gebäude (6 M Wachstumsrate)",
		"WE (Growth Rate in last 6 months)":
			"WE (6 M Wachstumsrate)",
		"Device Issues": "Geräteprobleme",
		"Number of Devices": "Anzahl der Geräte",
		'Number of Serials': 'Anzahl der Seriennr',
		"Devices with Error Code": "Geräte mit FehlerCode",
		"Devices not seen beyond treshold": "Probleme Geräteempfang",
		"Devices missing AES Key": "Fehlende AES Key",
		"Coverage Issues": "Abdeckungsprobleme",
		"Number of Buildings": "Anzahl der Gebäude",
		"Import Issues": "Importprobleme",
		"Double Serial Numbers": "Doppelte Seriennummern",
		"Double Serial Numbers Errors": "Fehler bei doppelten Seriennummern",
		'Buildings with Coverage below 90%': 'Gebäuden mit Funk unter 90%',
	},
	"billing": {
		"Include in billing report": "In Abrechnungsbericht aufnehmen",
		"Exclude from billing report": "Aus Abrechnungsbericht ausschließen",
		"Month": "Monat",
		"Total": "Gesamt",
		"Date": "Datum",
		"Download CSV": "CSV herunterladen",
		"Set date": "Datum festlegen",
		"Error": "Fehler",
		"Invoices": "Rechnungen",
		"Rate Values": "Preise",
		"Rate": "Artikel",
		"Description": "Beschreibung",
		"Price per item": "Preis pro Artikel",
		"Default price per item": "Standardpreis pro Artikel",
		"Fixed Price": "Festpreis",
		"Active Gates": "Aktive Gateways",
		"Active Sensors Radio Measured": "Aktive Sensoren(funk)",
		"Active Sensors Manual Measured": "Aktive Sensoren(manuell) ",
		"Active SIM Cards": "Aktive SIM-Karten",
	}
};
