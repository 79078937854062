const SIGNAL_LEVELS = [-112, -96, -81, -66, -51];
const SIGNAL_LABELS = [
  'No signal',
  'Very weak',
  'Weak',
  'Average',
  'Strong',
  'Excellent',
];

const getSignalStrengthLevel = (value) => {
  for (let level = 0; level < SIGNAL_LEVELS.length; level++) {
    if (SIGNAL_LEVELS[level] > value)
      return level;
  }
  return SIGNAL_LEVELS.length;
}

export default function getSignalStrengthLabel(value) {
  if (typeof value !== 'number' || value > 0) // rssi should always be negative
    return 'Wrong value';
  const signalLevel = getSignalStrengthLevel(value);
  return SIGNAL_LABELS[signalLevel];
}
