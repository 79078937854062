import { useQuery } from "@apollo/react-hooks";
import { Typography, makeStyles } from '@material-ui/core';
import gql from "graphql-tag";
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((_theme) => ({
  title: {
    flexGrow: 1,
  },
}));

const GET_ORGANIZATIONS = gql`
  query getOrganizations(
    $ids: [ID]
  ) {
    organizations(
      ids: $ids
    ) {
      id
      name
    }
  }
`;

export default function AppBarTitle({ location, organizationId }) {
  const classes = useStyles();
  const { t } = useTranslation(['translations', 'layout']);
  const { error, data } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      ids: [organizationId],
    },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    console.error(error);
  }

  const firstPath = location.pathname.split('/').filter((s) => !!s)[0];
  const title = t(`layout:/${firstPath}`);
  return <Typography variant="h6" noWrap className={classes.title}>
    {title + (
      data?.organizations.length === 1 ? ` - ${data.organizations[0].name}` : ''
    )}
  </Typography>
}
