// @format
import PropTypes from 'prop-types';

const OperatorType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});

export default {
  propType: OperatorType,
};
