// @format

const ROLE_CUSTOMER = 1;
const ROLE_OPERATOR = 2;
const ROLE_ADMIN_MASTER = 3;
const ROLE_ADMIN_USER = 4;
const ROLE_BUILDING_MANAGER = 10;

export default {
  roles: {
    ROLE_CUSTOMER,
    ROLE_OPERATOR,
    ROLE_ADMIN_MASTER,
    ROLE_ADMIN_USER,
    ROLE_BUILDING_MANAGER,
  },
  roleUser: (role) => [ROLE_ADMIN_USER, ROLE_ADMIN_MASTER].includes(role),
  roleAdmin: (role) => [ROLE_ADMIN_MASTER].includes(role),
  roleManager: (role) =>
    [ROLE_ADMIN_MASTER, ROLE_ADMIN_USER, ROLE_BUILDING_MANAGER].includes(role),
  userLabel: (user) => {
    const profile = user.profile || {};
    const {email} = user;
    const text = [profile.first_name, profile.last_name]
      .filter((a) => a)
      .join(' ');

    if (text) {
      return `${text} (${email})`;
    }

    return email;
  },
};
