import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import React from 'react';
import LoadComponent from '../components/LoadComponent';
import TelegramSearch from './Dashboard/TelegramSearch';
import Status from './Dashboard/Status';
import CoverageIssues from './Dashboard/CoverageIssues';
import DeviceIssues from './Dashboard/DeviceIssues';
import ImportIssues from './Dashboard/ImportIssues';

const GET_ORGANIZATION_STATS = gql`
	query getDashboardStats {
		organizationStats {
      sensor_conflicts_count
      sensor_fails_count
      amr_report_count
      amr_report_coverage_min
      amr_report_coverage_max
      invalid_serials_count
      sensors_missing_aes_count
      gateways_count,
      last_six_months_gateways_count
      sensors_count
      wireless_sensors_count
      last_six_months_wireless_sensors_count
      buildings_count
      last_six_months_buildings_count
      apartments_count
      last_six_months_apartments_count
      building_coverage_count
      sensors_not_seen_beyound_threshold_count
		}
	}
`;

export default function Dashboard() {
	const { data, loading, error } = useQuery(GET_ORGANIZATION_STATS);
	if (error) {
		return <div>Error</div>;
	}
	const organizationStats = data?.organizationStats ?? {};
	return (
		<LoadComponent>
			<Grid container spacing={3}>
				<Status data={organizationStats}/>
				<TelegramSearch />
				<CoverageIssues data={organizationStats}/>
				<DeviceIssues data={organizationStats}/>
				<ImportIssues data={organizationStats}/>
			</Grid >
		</LoadComponent>
	);
}
