// @format
import React from 'react';
import fp from 'lodash/fp';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Table from '../Table';

export default function CommentsTable({ comments, building, isLoading, reportId }) {
  const { t } = useTranslation(['building', 'report ']);
  return <Table
    title={
      <div>
        {t('report:Comments')}
      </div>
    }
    tableId={`report-${reportId}-comments`}
    isLoading={isLoading}
    columns={[
      {
        title: t('building:ID1'),
        field: "report_id",
        render: () => fp.get('external_id', building),
      },
      {
        title: t('report:Apartment'),
        field: "apartment.external_number",
        render: (row) => fp.get('sensor.apartment.external_number', row) || fp.get('apartment.external_number', row),
      },
      {
        title: t('report:Apartment Name'),
        field: "apartment.external_name",
        render: (row) => fp.get('sensor.apartment.external_name', row) || fp.get('apartment.external_name', row),
      },
      {
        title: t('report:Location'),
        field: "apartment.location",
        render: (row) => fp.get('sensor.apartment.location', row) || fp.get('apartment.location', row),
      },
      {
        title: t('report:Sensor'),
        field: "sensor.serial_number",
        render: (row) => fp.get('sensor.serial_number', row) || fp.get('sensor.external_number', row),
      },
      { title: t('report:Comment'), field: 'comment' },
      { title: t('report:Comment Operator'), field: 'comment_operator' },
    ]}
    data={comments || []}
    options={{
      sorting: false,
      search: false,
      toolbar: true,
      pageSize: 5,
      pageSizeOptions: [5, 10, 25],
    }}
  />;
}