import React from 'react';
import PropTypes from 'prop-types';
import ErrorAlert from './ErrorAlert';
import ReportError from '../../model/report-error';

const ErrorsList = ({errors}) => {
  const sortedErrors = errors.sort((e1, e2) => {
    if (e1.type === ReportError.ERROR_CONFLICT_IDENTICAL_SENSORS)
      return -1;
    if (e2.type === ReportError.ERROR_CONFLICT_IDENTICAL_SENSORS)
      return 1;
    return 0;
  });
  return (
    <>
      {sortedErrors.map((error, id) => (
        <ErrorAlert key={id} error={error} />
      ))}
    </>
  );
};

ErrorsList.propTypes = {
  errors: PropTypes.arrayOf(ReportError.propType).isRequired,
};

export default ErrorsList;
