// @format
import React from 'react';
import { Link } from 'react-router-dom';
import MaterialButton from '@material-ui/core/Button';

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

export default function Button(props) {
  return (
    <MaterialButton {...props} component={AdapterLink}>
      {props.children}
    </MaterialButton>
  );
}
