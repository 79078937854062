// @format
import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '../components/Table';
import { useMe } from '../reducers/me';

const GET_FTP_CONFIGS = gql`
  query getFtpConfigs($ids: [ID], $shared: Boolean) {
    ftpConfigs(ids: $ids, shared: $shared) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

const ADD_FTP_CONFIG = gql`
  mutation addFtpConfig(
    $user: String!
    $password: String!
    $host: String!
    $port: Int!
    $setup: String!
    $folder: String!
  ) {
    addFtpConfig(
      user: $user
      password: $password
      host: $host
      port: $port
      setup: $setup
      folder: $folder
    ) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

const UPDATE_FTP_CONFIG = gql`
  mutation updateFtpConfig(
    $id: ID!
    $user: String!
    $password: String
    $host: String!
    $port: Int!
    $setup: String!
    $folder: String!
  ) {
    updateFtpConfig(
      id: $id
      user: $user
      password: $password
      host: $host
      port: $port
      setup: $setup
      folder: $folder
    ) {
      id
      user
      host
      port
      setup
      folder
    }
  }
`;

export default function SharedFtpConfigs() {
  const { t } = useTranslation(['ftpConfig']);
  const [useMeData] = useMe();
  const { loading, error, data } = useQuery(GET_FTP_CONFIGS, {
    variables: { shared: true },
  });
  const [addFtpConfig] = useMutation(ADD_FTP_CONFIG, {
    update: (cache, {data: {addFtpConfig}}) => {
      const {ftpConfigs} = cache.readQuery({query: GET_FTP_CONFIGS});

      cache.writeQuery({
        query: GET_FTP_CONFIGS,
        data: {
          ftpConfigs: ftpConfigs.concat(addFtpConfig),
        },
      });
    },
  });
  const [updateFtpConfig] = useMutation(UPDATE_FTP_CONFIG);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('ftpConfig:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    { title: t('ftpConfig:User'), field: 'user' },
    {
      title: t('ftpConfig:Password'),
      field: 'password',
      render: () => '*************',
    },
    { title: t('ftpConfig:Host'), field: 'host' },
    { title: t('ftpConfig:Port'), field: 'port' },
    {
      title: t('ftpConfig:Setup'),
      field: 'setup',
      lookup: {
        'webdyn_shared': 'webdyn shared',
        'engelmann_shared': 'engelmann shared',
      },
    },
    { title: t('ftpConfig:Folder'), field: 'folder' },
  ];

  return (
    <React.Fragment>
      <Table
        tableId="ftpConfigs"
        title=""
        columns={columns}
        data={data.ftpConfigs}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addFtpConfig({
              variables: {
                user: newData.user,
                password: newData.password,
                host: newData.host,
                port: Number(newData.port),
                setup: newData.setup,
                folder: newData.folder || '',
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            await updateFtpConfig({
              variables: {
                id: oldData.id,
                user: newData.user,
                host: newData.host,
                port: Number(newData.port),
                setup: newData.setup,
                folder: newData.folder || '',
                ...(!!newData.password && { password: newData.password }),
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
}
