import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Card from "./Card"; // Adjust the path if necessary
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import RouterIcon from "@material-ui/icons/Router";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ApartmentIcon from "@material-ui/icons/Apartment";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";

const calculateGrowth = (total, lastSixMonths) => {
  return (lastSixMonths / total * 100).toFixed(1);
}

function isValidNumber(value) {
  return typeof value === 'number' && Number.isFinite(value);
}

export default function Status({ data }) {
  const { t } = useTranslation(["dashboard"]);
  const {
    gateways_count,
    last_six_months_gateways_count,
    sensors_count,
    wireless_sensors_count,
    last_six_months_wireless_sensors_count,
    buildings_count,
    last_six_months_buildings_count,
    apartments_count,
    last_six_months_apartments_count,
  } = data;

  const items = [
    {
      icon: <DeviceHubIcon />,
      text: `${t("dashboard:Gateways (growth rate in last 6 months)")}: 
      ${isValidNumber(gateways_count) ? gateways_count : 'N/A'}
      (${isValidNumber(gateways_count) && isValidNumber(last_six_months_gateways_count) ? calculateGrowth(gateways_count, last_six_months_gateways_count) : 'N/A'}%)`,
    },
    {
      icon: <RouterIcon />,
      text: `${t(
        "dashboard:Wireless Devices (growth rate in last 6 months)"
      )}: 
      ${isValidNumber(wireless_sensors_count) ? wireless_sensors_count : 'N/A'}
      (${isValidNumber(wireless_sensors_count) && isValidNumber(last_six_months_wireless_sensors_count) ? calculateGrowth(wireless_sensors_count, last_six_months_wireless_sensors_count) : 'N/A'}%)`,
    },
    {
      icon: <SettingsRoundedIcon />,
      text: `${t("dashboard:Devices Total")}: ${isValidNumber(sensors_count) ? sensors_count : 'N/A'}`,
    },
    {
      icon: <HomeWorkIcon />,
      text: `${t("dashboard:Building (Growth Rate in last 6 months)")}: 
      ${isValidNumber(buildings_count) ? buildings_count : 'N/A'}      
      (${isValidNumber(buildings_count) && isValidNumber(last_six_months_buildings_count) ? calculateGrowth(buildings_count, last_six_months_buildings_count) : 'N/A'}%)`,
    },
    {
      icon: <ApartmentIcon />,
      text: `${t("dashboard:WE (Growth Rate in last 6 months)")}: 
      ${isValidNumber(apartments_count) ? apartments_count : 'N/A'}      
      (${isValidNumber(apartments_count) && isValidNumber(last_six_months_apartments_count) ? calculateGrowth(apartments_count, last_six_months_apartments_count) : 'N/A'}%)`,
    },
  ];

  return (
    <Grid item xs={12} md={9}>
      <Card title={t("dashboard:Status")}>
        {/* <Grid container>
					<Grid item md={4}> */}
        {items.map((item, index) => (
          <Box display="flex" alignItems="center" mb={1} key={index}>
            {item.icon}
            <Typography variant="body4" style={{ marginLeft: 8 }}>
              {item.text}
            </Typography>
          </Box>
        ))}
        {/* </Grid>
					<Grid item md={8}>
						<LineChart />
					</Grid>
				</Grid> */}
      </Card>
    </Grid>
  );
}
