import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom';

import Table from '../components/Table';
import useSearchQuery from '../lib/search-query';
import TelegramModel from '../model/telegram';
import Telegram from './Telegram';
import data from '../lib/data';

const TELEGRAM_SEARCH = gql`
  mutation telegramSearch(
    $query: SearchQuery!
  ) {
    telegramSearch(
      query: $query
    ) {
      rows {
        id
        captured_at
        sensor_serial_number
        gateway {
          id
        }
        building {
          id
          external_id
        }
        error
        errorDescription
        current_measurement {
          value
          unit
        }
        date_time_record_value
      }
      count
    }
  }
`;

function Content() {
  const { t } = useTranslation(['telegrams']);
  const [telegramSearch] = useMutation(TELEGRAM_SEARCH);
  const query = useSearchQuery();
  const sensorSerialNumber = query.get(TelegramModel.serialNumberParameter);

  const [valueColumn] = useState({
    title: t('telegrams:Value'),
    field: 'current_measurement.value',
    render: (rowData) => {
      const { current_measurement } = rowData;
      return current_measurement ? `${current_measurement.value} ${current_measurement.unit}` : '';
    },
    sorting: false,
    filtering: false,
  }); // see: https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
  const [errorColumn] = useState({
    title: t('telegrams:Error ID'),
    field: 'error',
    sorting: false,
    filter: {
      fields: ['decrypted.header.status'],
      transform: (value) => '0x' + parseInt(value, 10).toString(16),
    },
  }); // see: https://github.com/mbrn/material-table/issues/491#issuecomment-541011677
  const [idColumn] = useState({
    title: t('telegrams:ID'),
    sorting: false,
    filtering: false,
    field: 'id',
    render: (rowData) => {
      const { id } = rowData;
      return <Link to={`/telegrams/${id}`} target="_blank">{id}</Link>;
    },
  }); // see: https://github.com/mbrn/material-table/issues/491#issuecomment-541011677

  const [firstLoad, setFirstLoad] = useState(false);

  return (
    <Table
      title={t('telegrams:Telegrams')}
      tableId="telegrams"
      columns={[
        idColumn,
        {
          title: t('telegrams:Sensor serial number'),
          field: 'sensor_serial_number',
          sorting: false,
          type: 'numeric',
          defaultFilter: sensorSerialNumber,
        },
        {
          title: t('telegrams:Date'),
          field: 'captured_at',
          type: 'date',
          defaultSort: 'desc',
          filter: {
            operator: 'day',
          },
        },
        {
          title: t('telegrams:Gateway ID or walkby'),
          field: 'gateway.id',
          sorting: false,
          type: 'numeric',
          filter: {
            fields: ['$Gateway.id$'],
          },
        },
        {
          title: t('telegrams:Related Building ID1'),
          field: 'building.external_id',
          sorting: false,
          type: 'numeric',
          filter: {
            fields: ['$Gateway.Building.external_id$', '$Report.Building.external_id$'],
          },
        },
        {
          title: t('telegrams:Date Time Record Value'),
          field: 'date_time_record_value',
          sorting: false,
          filtering: false,
        },
        valueColumn,
        errorColumn,
        {
          title: t('telegrams:Error Description'),
          field: 'errorDescription',
          sorting: false,
          filtering: false,
        },
      ]}
      data={data(
        {},
        telegramSearch,
        (result) => result.data.telegramSearch,
        null,
        [],
        (query) => {
          setFirstLoad(true);
          return !firstLoad && query.filters.length === 0
        })}
      options={{
        filtering: true,
        search: false,
      }}
    />
  );
}

export default function Telegrams({ match }) {
  return (
    <Switch>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/:id`} component={Telegram} />
    </Switch>
  );
}