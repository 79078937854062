import React, { useEffect, useRef, useState, useMemo } from 'react';
import moment from 'moment';
import fp from 'lodash/fp';
import { Link, Route, Switch } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { MTableToolbar } from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';

import i18n from '../lib/i18n';
import useSearchQuery from '../lib/search-query';
import Button from '../components/Button';
import Table from '../components/Table';
import Report from './Report';
import NewReport from './NewReport';
import ReportModel from '../model/report';
import data from '../lib/data';
import CommentsPreview from './Reports/CommentsPreview';
import { FormControlLabel, InputLabel, Radio, RadioGroup } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import useLocalStorage from 'react-use-localstorage';
import ToggleButton from '@material-ui/lab/ToggleButton';

const useStyles = makeStyles((theme) => ({
  tableToolbarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tableToolbar: {
    // flexGrow: 1,
  },
  newItemButtonWrapper: {
    // flex: 1,
  },
  filterBox: {
    alignSelf: 'start',
  },
  radioRed: {
    '&$radioChecked': {
      color: 'red',
    },
  },
  radioYellow: {
    '&$radioChecked': {
      color: '#FFC300',
    },
  },
  radioGreen: {
    '&$radioChecked': {
      color: 'green',
    },
  },
  radioChecked: {},
}));

const REPORT_SEARCH = gql`
  mutation reportSearch(
    $query: SearchQuery!
    $reportQuery: ReportSearchQuery
  ) {
    reportSearch(
      query: $query
      reportQuery: $reportQuery
    ) {
      rows {
        id
        status
        comment
        comment_operator
        created_at
        date_submitted
        date_end_collecting
        date_end_setdate
        sensors_count
        sensor_completion
        serials_count
        serial_completion
        radio_collected_count
        hand_collected_count
        sensors_with_telegrams_count
        sensors_with_telegrams_change
        sensors_with_total_telegrams_count
        telegram_completion
        total_telegram_completion
        memo
        operator {
          id
          email
        }
        building {
          id
          external_id
          street
          house_number
          postalcode
          city
          data_source_count
          address
        }
        errors_count
        comments_count
        report_export {
          id
          frequency
        }
        receiver
      }
      count
    }
  }
`;

const UPDATE_REPORT = gql`
  mutation updateReport(
    $id: ID!
    $status: Int
    $buildingId: ID!
    $operatorId: ID
    $setDate: DateTime
    $readingDate: DateTime!
    $memo: String
    $repeat: Repeat
    $receiver: Receiver
  ) {
    updateReport(
      id: $id
      status: $status
      buildingId: $buildingId
      operatorId: $operatorId
      setDate: $setDate
      readingDate: $readingDate
      memo: $memo
      repeat: $repeat
      receiver: $receiver
    ) {
      id
      status
      reading_date
      set_date
      operator {
        id
        email
      }
      building {
        id
        street
        house_number
        postalcode
        city
      }
    }
  }
`;

const IdLink = ({ id }) => (
  <Link to={`/reports/${id}`} target="_blank">
    {id}
  </Link>
);

const TableDatePicker = (props) => {
  const { rowData, field, onRowDataChange } = props;
  return (
    <TextField
      type="date"
      value={moment.utc(rowData[field]).format('YYYY-MM-DD')}
      onChange={(event) => {
        onRowDataChange({
          ...rowData,
          [field]: moment.utc(event.target.value).toISOString(),
        });
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

const getColumns = ({recycleBin, order, orderDirection}) => [
  {
    title: i18n.t('report:Building ID1'),
    field: 'building.external_id',
    editable: 'never',
    filter: {
      fields: ['$Building.external_id$'],
    },
    association: 'Building',
  },
  {
    title: i18n.t('report:Building Address'),
    field: 'building.address',
    editable: 'never',
    association: 'Building.BuildingStats',
    filter: {
      fields: ['$Building.BuildingStats.address$'],
    },
  },
  {
    title: i18n.t('report:ID'),
    field: 'id',
    type: 'numeric',
    ...(!order ? { defaultSort: 'desc' } : {}),
    editable: 'never',
    render: (rowData) => <IdLink id={rowData.id} />,
  },
  {
    title: i18n.t('report:Status'),
    field: 'status',
    lookup: {
      0: i18n.t('report:New'),
      1: i18n.t('report:Open'),
      5: i18n.t('report:In Progress'),
      10: i18n.t('report:Submitted'),
      20: i18n.t('report:Confirmed'),
      30: i18n.t('report:Marked Wrong'),
    },
  },
  {
    title: i18n.t('report:MEMO'),
    field: 'memo',
    ...(recycleBin && {editable: 'never'}),
  },
  {
    title: i18n.t('report:Repeat'),
    field: 'report_export.frequency',
    filtering: false,
    editable: 'never',
    render: (rowData) => {
      const repeat = ReportModel.EXPORT_FREQUENCY_TO_REPORT_REPEAT[rowData.report_export?.frequency];
      const labelKey = ReportModel.REPEAT_VALUE_TO_LABEL_KEY[repeat]
        || ReportModel.REPEAT_VALUE_TO_LABEL_KEY_UNSAFE[repeat];
      return labelKey ? i18n.t(labelKey) : '';
    },
    association: 'ReportExport',
  },
  {
    title: i18n.t('report:Set Date'),
    field: 'date_end_setdate',
    render: (rowData) => {
      return fp.get('date_end_setdate', rowData)
        ? moment.utc(rowData.date_end_setdate).format('MMMM YYYY')
        : '';
    },
    editable: recycleBin ? 'never' : 'onUpdate',
    editComponent: (props) => <TableDatePicker field="date_end_setdate" {...props} />,
    type: 'date',
    filter: {
      operator: 'month',
    },
  },
  {
    title: i18n.t('report:Reading Date'),
    field: 'date_end_collecting',
    render: (rowData) => moment.utc(rowData.date_end_collecting).format('MMMM YYYY'),
    editable: recycleBin ? 'never' : 'onUpdate',
    editComponent: (props) => (
      <TableDatePicker field="date_end_collecting" {...props} />
    ),
    type: 'date',
    filter: {
      operator: 'month',
    },
  },
  {
    title: i18n.t('report:Errors'),
    field: 'errors_count',
    type: 'numeric',
    filter: {
      fields: ['$ReportStats.errors_count$'],
    },
    editable: 'never',
    association: 'ReportStats',
    render: (rowData) =>
      rowData.errors_count ? `⚠️(${rowData.errors_count})` : '',
  },
  {
    title: i18n.t('report:Serials Count'),
    field: 'serials_count',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Radio Collected Count'),
    field: 'radio_collected_count',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Serial %'),
    field: 'serial_completion',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    render: (rowData) => (rowData.serial_completion * 100).toFixed(1) + '%',
    ...(order === 'serial_completion' ? { defaultSort: orderDirection, defaultSortOverride: true } : {}),
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Hand Collected Count'),
    field: 'hand_collected_count',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Comments'),
    field: 'comments_count',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    render: (rowData) => <CommentsPreview
      commentsCount={rowData.comments_count}
      reportId={rowData.id}
    />,
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Submission Date'),
    field: 'date_submitted',
    render: (rowData) =>
      rowData.date_submitted
        ? moment.utc(rowData.date_submitted).format('LL')
        : '',
    editable: 'never',
    type: 'date',
    filter: {
      operator: 'day',
    },
  },
  {
    title: i18n.t('building:Data Source Count'),
    field: 'building.data_source_count',
    type: 'numeric',
    editable: 'never',
    association: 'Building.OrganizationBuildingStats',
    filter: {
      fields: ['$Building.OrganizationBuildingStats.data_source_count$'],
    },
  },
  {
    title: i18n.t('report:Sensors Count'),
    field: 'sensors_count',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Sensor %'),
    field: 'sensor_completion',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    render: (rowData) => (rowData.sensor_completion * 100).toFixed(1) + '%',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Telegrams Count'),
    field: 'sensors_with_telegrams_count',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Telegrams Change (%)'),
    field: 'sensors_with_telegrams_change',
    type: 'numeric',
    ...(!order ? { defaultSort: 'desc' } : {}),
    editable: 'never',
    association: 'ReportStats',
    render: (rowData) =>{
       if(rowData.sensors_with_telegrams_change > 0){
         return <span style={{color: 'green'}}>{`+${rowData.sensors_with_telegrams_change}%`}</span>
       }else if(rowData.sensors_with_telegrams_change < 0){
         return <span style={{color: 'red'}}>{`${rowData.sensors_with_telegrams_change}%`}</span>
       }

       return `${rowData.sensors_with_telegrams_change}%`;
    },
  },
  {
    title: i18n.t('report:Total Telegrams Count'),
    field: 'sensors_with_total_telegrams_count',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Telegram %'),
    field: 'telegram_completion',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    render: (rowData) => (rowData.telegram_completion * 100).toFixed(1) + '%',
    association: 'ReportStats',
  },  
  {
    title: i18n.t('report:Total Telegram %'),
    field: 'total_telegram_completion',
    type: 'numeric',
    filtering: false,
    editable: 'never',
    render: (rowData) => (rowData.total_telegram_completion * 100).toFixed(1) + '%',
    association: 'ReportStats',
  },
  {
    title: i18n.t('report:Creation Date'),
    field: 'created_at',
    render: (rowData) => moment.utc(rowData.created_at).format('LL'),
    editable: 'never',
    type: 'date',
    filter: {
      operator: 'day',
    },
  },
  {
    title: i18n.t('report:ZIP code'),
    field: 'building.postalcode',
    editable: 'never',
    association: 'Building',
    filter: {
      fields: ['$Building.postalcode$'],
    },
  },
  {
    title: i18n.t('report:City'),
    field: 'building.city',
    editable: 'never',
    association: 'Building',
    filter: {
      fields: ['$Building.city$'],
    },
  },
  {
    title: i18n.t('report:Operator'),
    field: 'operator.email',
    editable: 'never',
    association: 'Operator',
    filter: {
      fields: ['$Operator.email$'],
    },
  },
];

const MainReportsTable = ({ classes }) => {
  const [reportSearch] = useMutation(REPORT_SEARCH);
  const [updateReport] = useMutation(UPDATE_REPORT);
  const { t } = useTranslation(['report']);
  const query = useSearchQuery();
  const order = query.get('order');
  const orderDirection = query.get('orderDirection');
  const columns = getColumns({order, orderDirection});

  const columnFieldToColumnMap = columns.reduce(
    (result, column) => ({ ...result, [column.field]: column }),
    {},
  );
  const [pageSize, setPageSize] = useState(10);

  const [storedSensorCompletionFilter, setStoredSensorCompletionFilter] = useLocalStorage(
    'reports-sensor-completion-filter',
    ReportModel.SENSOR_COMPLETION_GT_80,
  );
  const [sensorCompletionFilter, setSensorCompletionFilter] = useState(storedSensorCompletionFilter);
  const [storedSerialCompletionFilter, setStoredSerialCompletionFilter] = useLocalStorage(
    'reports-serial-completion-filter',
    ReportModel.SERIAL_COMPLETION_GT_80,
  );
  const [serialCompletionFilter, setSerialCompletionFilter] = useState(storedSerialCompletionFilter);

  const [storedDataSourceFilter, setStoredDataSourceFilter] = useLocalStorage(
    'reports-data-source-filter',
    "Yes",
  );
  const [dataSourceFilter, setDataSourceFilter] = useState(storedDataSourceFilter);
  const [storedReadingDateBefore, setStoredReadingDateBefore] = useLocalStorage(
    'reports-reading-date-before',
    null,
  );
  const [readingDateBefore, setReadingDateBefore] = useState(
    storedReadingDateBefore === 'null' ? null : storedReadingDateBefore,
  );
  const [storedReadingDateAfter, setStoredReadingDateAfter] = useLocalStorage(
    'reports-reading-date-after',
    null,
  );
  const [readingDateAfter, setReadingDateAfter] = useState(
    storedReadingDateAfter === 'null' ? null : storedReadingDateAfter,
  );
  const [storedLatest, setStoredLatest] = useLocalStorage('reports-latest', true);
  const [latest, setLatest] = useState(storedLatest === 'true');

  const tableRef = useRef();
  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [sensorCompletionFilter, serialCompletionFilter, dataSourceFilter, readingDateBefore, readingDateAfter, latest]);

  // fix to prevent remounting after typing something in one of filter inputs
  const components = useMemo(() => {
    return {
      Container: (props) => <Paper {...props} elevation={0} />,
      Toolbar: (props) => (
        <div className={classes.tableToolbarWrapper}>
          <Box ml={2} className={classes.newItemButtonWrapper}>
            <Button
              to="/reports/new"
              target="_blank"
              variant="contained"
              color="primary">
              {t('report:New Report')}
            </Button>
          </Box>
          <Box ml={4} pt={2} className={classes.filterBox}>
            <InputLabel>{t('report:Sensor %')}</InputLabel>
            <RadioGroup
              value={sensorCompletionFilter}
              onChange={({ currentTarget: { value } }) => {
                setSensorCompletionFilter(value);
                setStoredSensorCompletionFilter(value);
              }}
            >
              {ReportModel.SENSOR_COMPLETION_FILTERS.map(([value, color]) => (
                <FormControlLabel
                  onClick={() => {
                    if (sensorCompletionFilter === value) {
                      setSensorCompletionFilter(null);
                      setStoredSensorCompletionFilter(null);
                    }
                  }}
                  key={value}
                  control={<Radio classes={{root: classes[`radio${color}`], checked: classes.radioChecked}} />}
                  value={value}
                  label={t(`report:${value}`)}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box ml={2} pt={2} className={classes.filterBox}>
            <InputLabel>{t('report:Serial %')}</InputLabel>
            <RadioGroup
              value={serialCompletionFilter}
              onChange={({ currentTarget: { value } }) => {
                setSerialCompletionFilter(value); 
                setStoredSerialCompletionFilter(value);
              }}
            >
              {ReportModel.SERIAL_COMPLETION_FILTERS.map(([value, color]) => (
                <FormControlLabel
                  onClick={() => {
                    if (serialCompletionFilter === value) {
                      setSerialCompletionFilter(null);
                      setStoredSerialCompletionFilter(null);
                    }
                  }}
                  key={value}
                  control={<Radio classes={{root: classes[`radio${color}`], checked: classes.radioChecked}} />}
                  value={value}
                  label={t(`report:${value}`)}
                />
              ))}
            </RadioGroup>
          </Box>
          <Box ml={2} pt={2} className={classes.filterBox}>
            <InputLabel>{t('report:Data Source')}</InputLabel>
            <RadioGroup
              value={dataSourceFilter}
              onChange={({ currentTarget: { value } }) => {
                setDataSourceFilter(value);
                setStoredDataSourceFilter(value);
              }}
            >
              <FormControlLabel
                onClick={() => {
                  if (dataSourceFilter === "Yes") {
                    setDataSourceFilter(null);
                    setStoredDataSourceFilter(null);
                  }
                }}
                control={<Radio color='primary' />}
                value={"Yes"}
                label={t("report:Yes")}
              />
              <FormControlLabel
                onClick={() => {
                  if (dataSourceFilter === "No") {
                    setDataSourceFilter(null);
                    setStoredDataSourceFilter(null);
                  }
                }}
                control={<Radio color='primary' />}
                value={"No"}
                label={t("report:No")}
              />
            </RadioGroup>
          </Box>
          <Box ml={2} pt={2} className={classes.filterBox}>
            <InputLabel>{t('report:Report')}</InputLabel>
            <Box mt={1}>
              <DatePicker
                label={t("report:After DATE")}
                value={readingDateAfter}
                views={['year', 'month']}
                clearable={true}
                onChange={(value) => {
                  setReadingDateAfter(value);
                  setStoredReadingDateAfter(value ? value.toISOString() : null);
                }} />
            </Box>
            <Box mt={1}>
              <DatePicker
                label={t("report:Before DATE")}
                value={readingDateBefore}
                views={['year', 'month']}
                clearable={true}
                onChange={(value) => {
                  setReadingDateBefore(value);
                  setStoredReadingDateBefore(value ? value.toISOString() : null);
                }} />
            </Box>
          </Box>
          <MTableToolbar {...props} classes={{ root: classes.tableToolbar }} />
          <Box ml={2} className={classes.newItemButtonWrapper}>
            <ToggleButton
              onClick={() => {
                const value = !latest;
                setLatest(value);
                setStoredLatest(value);
              }}
              selected={latest}
            >
              {t('report:Show Latest Only')}
            </ToggleButton>
          </Box>
        </div>
      )};
  }, [sensorCompletionFilter, serialCompletionFilter, dataSourceFilter, readingDateBefore, readingDateAfter, latest]);

  return (
    <Table
      tableId="reports"
      title=""
      tableRef={tableRef}
      columns={columns}
      data={data(
        columnFieldToColumnMap,
        reportSearch,
        (response) => response.data.reportSearch,
        setPageSize,
        [
          {
            fields: ['status'],
            values: ReportModel.STATUS_ACTIVE.map((status) => status.toString()),
          },
        ],
        false,
        {
          reportQuery: {
            sensor_completion: sensorCompletionFilter,
            serial_completion: serialCompletionFilter,
            data_source: dataSourceFilter,
            reading_date_before: readingDateBefore,
            reading_date_after: readingDateAfter,
            latest,
          },
        },
      )}
      options={{
        sorting: true,
        pageSize,
        filtering: true,
        searchAutoFocus: true,
        columnsButton: true,
        compressed: true,
      }}
      editable={{
        onRowUpdate: async (newData, oldData) => {
          const reportExportFrequency = newData.report_export?.frequency;
          await updateReport({
            variables: {
              id: oldData.id,
              status: Number(newData.status),
              readingDate: newData.date_end_collecting,
              setDate: newData.date_end_setdate,
              buildingId: newData.building.id,
              operatorId: newData.operator?.id,
              memo: newData.memo,
              repeat: ReportModel.EXPORT_FREQUENCY_TO_REPORT_REPEAT[reportExportFrequency],
              receiver: newData.receiver,
            },
          });
        },
      }}
      components={components}
    />
  );
};

const RecycleBinTable = ({ classes }) => {
  const [reportSearch] = useMutation(REPORT_SEARCH);
  const [updateReport] = useMutation(UPDATE_REPORT);
  const columns = getColumns({recycleBin: true});

  const columnFieldToColumnMap = columns.reduce(
    (result, column) => ({ ...result, [column.field]: column }),
    {},
  );
  const [pageSize, setPageSize] = useState(10);

  // fix to prevent remounting after typing something in one of filter inputs
  const components = useMemo(() => {
    return {
      Container: (props) => <Paper {...props} elevation={0} />,
      Toolbar: (props) => (
        <div className={classes.tableToolbarWrapper}>
          <MTableToolbar {...props} classes={{ root: classes.tableToolbar }} />
        </div>
      ),
    };
  }, []);

  return (
    <Table
      title=""
      tableId="reports-recycle-bin"
      columns={columns}
      data={data(
        columnFieldToColumnMap,
        reportSearch,
        (response) => response.data.reportSearch,
        setPageSize,
        [
          {
            fields: ['status'],
            values: ReportModel.STATUS_DELETED.map((status) => status.toString()),
          },
        ],
      )}
      options={{
        sorting: true,
        pageSize,
        filtering: true,
        searchAutoFocus: true,
        columnsButton: true,
        compressed: true,
      }}
      editable={{
        onRowUpdate: async (newData, oldData) => {
          const reportExportFrequency = newData.report_export?.frequency;
          await updateReport({
            variables: {
              id: oldData.id,
              status: Number(newData.status),
              readingDate: newData.date_end_collecting,
              setDate: newData.date_end_setdate,
              buildingId: newData.building.id,
              operatorId: newData.operator?.id,
              memo: newData.memo,
              repeat: ReportModel.EXPORT_FREQUENCY_TO_REPORT_REPEAT[reportExportFrequency],
              receiver: newData.receiver,
            },
          });
        },
      }}
      components={components}
    />
  );
};

const Content = () => {
  const [visibleTabIndex, setVisibleTabIndex] = useState(0);
  const { t } = useTranslation(['report']);

  const onTabChange = (event, tabIndex) => {
    setVisibleTabIndex(tabIndex);
  };

  const classes = useStyles();

  return (
    <Paper>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={visibleTabIndex}
        onChange={onTabChange}>
        <Tab label={t('report:Main reports')} />
        <Tab label={t('report:Marked Wrong')} />
      </Tabs>

      {visibleTabIndex === 0 ? <MainReportsTable classes={classes} /> : null}
      {visibleTabIndex === 1 ? <RecycleBinTable classes={classes} /> : null}
    </Paper>
  );
};

export default function Reports({ match }) {
  return (
    <Switch>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/new`} component={NewReport} />
      <Route path={`${match.path}/:id`} component={Report} />
    </Switch>
  );
}
