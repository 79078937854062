// @format
import React, {forwardRef, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import MaterialTable from 'material-table';
import Box from '@material-ui/core/Box';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {makeStyles} from '@material-ui/core/styles';
import useLocalStorage from 'react-use-localstorage'; 
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import './Table.css';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const useTableStyles = makeStyles(() => ({
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
}));

const useLocalObjectStorage =  (key, initialValue) => {
  const [value, setValue] = useLocalStorage(key, JSON.stringify(initialValue));
  return [JSON.parse(value), (valueObject) => setValue(JSON.stringify(valueObject))];
}

export default function Table(props) {
  const {t} = useTranslation(['table']);
  const tableRef = useRef();

  const { tableId } = props;
  let {
    columns,
    onFilterChange,
    onOrderChange,
    onSearchChange,
    onColumnDragged,
    options,
    data,
    onChangeColumnHidden,
  } = props;
  const [tableState, setTableState] = useLocalObjectStorage(`${tableId}-table-state`, {});

  const actions = [
    ...(props.actions ? props.actions : []),
    ...(tableId ? [{
      icon: () => <SettingsBackupRestoreIcon />,
      isFreeAction: true,
      tooltip: t('table:Reset table settings'),
      onClick: () => {
        setTableState({});
        window.location.reload();
      },
    }] : []),
  ];

  if (tableId) {
    const fieldToFilterValue = tableState.filters?.reduce(
      (result, filter) => {
        result[filter.field] = filter.value;
        return result;
      },
      {},
    );
    const { orderBy, orderDirection } = tableState;
    const columnOrder = tableState.columnOrder || columns?.map((c) => c.field);
    const hiddenColumnsSet = new Set(tableState.hiddenColumns ?? []);
    const defaultSortOverride = columns.find((column) => column.defaultSortOverride);
    columns = columns?.sort((column1, column2) => {
      const index1 = columnOrder.indexOf(column1.field);
      const index2 = columnOrder.indexOf(column2.field);
      return (index1 > index2) ? 1 : -1;
    })?.map(
      (column, index) => {
        const { field } = column;
        const hidden = hiddenColumnsSet.has(field);
        return {
          ...column,
          ...(fieldToFilterValue
            && column.defaultFilter == null
            && { defaultFilter: fieldToFilterValue[field] }),
          ...(orderBy != null && !defaultSortOverride && {
            defaultSort: orderBy === index
              ? orderDirection
              : undefined,
          }),
          hidden,
          hiddenByColumnsButton: hidden,
        };
      },
    );
    const getHiddenAdjustedColumnIndex = (index) => {
      let hiddenCount = 0;
      for (let i = 0; i - hiddenCount <= index; i++) {
        const field = columns[i].field;
        if (hiddenColumnsSet.has(field)) {
          hiddenCount++;
        }
      }
      return index + hiddenCount;
    };
    onOrderChange = (orderBy, orderDirection) => {
      setTableState({
        ...tableState,
        orderBy,
        orderDirection,
      });
      props.onOrderChange && props.onOrderChange(orderBy, orderDirection);
    };
    onColumnDragged = (sourceIndex, destinationIndex) => {
      const sourceIndexAdjusted = getHiddenAdjustedColumnIndex(sourceIndex);
      const destinationIndexAdjusted = getHiddenAdjustedColumnIndex(destinationIndex);
      [
        columnOrder[sourceIndexAdjusted],
        columnOrder[destinationIndexAdjusted],
      ] = [
        columnOrder[destinationIndexAdjusted],
        columnOrder[sourceIndexAdjusted],
      ];

      const actualTableRef = tableRef.current || props.tableRef.current;

      let currentOrderBy = actualTableRef.dataManager.orderBy;
      if (currentOrderBy === sourceIndexAdjusted || currentOrderBy === destinationIndexAdjusted) {
        currentOrderBy = sourceIndexAdjusted === currentOrderBy ?
          destinationIndexAdjusted : sourceIndexAdjusted;
      }

      let isOrderByUpdated = false;
      if (orderBy === sourceIndexAdjusted || orderBy === destinationIndexAdjusted) {
        isOrderByUpdated = true;
      }

      setTableState({
        ...tableState,
        columnOrder,
        ...(isOrderByUpdated && {orderBy: currentOrderBy}),
      });

      if (actualTableRef.dataManager.orderBy >= 0) {
        // update sorted column but only if some column is already selected for sorting
        actualTableRef.dataManager.changeOrder(
          currentOrderBy,
          orderDirection || actualTableRef.dataManager.orderDirection,
        );
      }
      props.onColumnDragged && props.onColumnDragged(sourceIndex, destinationIndex);
    };
    onChangeColumnHidden = (column, hidden) => {
      const { field } = column;
      const hiddenColumns = [...tableState.hiddenColumns || []];
      if (hidden) {
        hiddenColumns.push(field);
      } else {
        hiddenColumns.splice(hiddenColumns.indexOf(field), 1);
      }
      setTableState({
        ...tableState,
        hiddenColumns,
      });
      props.onChangeColumnHidden && props.onChangeColumnHidden(column, hidden);
    };
    if (typeof data === 'function') {
      data = (query) => {
        setTableState({
          ...tableState,
          filters: query.filters.map((filter) => ({ field: filter.column.field, value: filter.value })),
          searchText: query.search,
        });
        return props.data(query);
      }
    } else {
      onFilterChange = (filters) => {
        setTableState({
          ...tableState,
          filters: filters.map((filter) => ({ field: filter.column.field, value: filter.value })),
        });
        props.onFilterChange && props.onFilterChange(filters);
      };
      onSearchChange = (searchText) => {
        setTableState({
          ...tableState,
          searchText,
        });
        props.onSearchChange && props.onSearchChange(searchText);
      };
    }
    options = { ...options, ...(options?.searchText == null && { searchText: tableState.searchText }) };
  }

  return (
    <Box mb={2}>
      <MaterialTable
        tableRef={tableRef} // for cases when it wasn't supplied in the props
        {...props}
        options={{
          ...options,
          addRowPosition: 'first',
        }}
        columns={(
          props.options?.compressed
            ? columns?.map((column) => ({
              ...column,
              headerStyle: { padding: "16px 8px", maxWidth: 34 },
              filterCellStyle: { padding: "16px 8px" },
              cellStyle: { padding: "16px 8px" },
            }))
            : columns
        ).map((column) => ({ ...column, align: 'left' }))}
        data={data}
        icons={tableIcons}
        actions={actions}
        localization={{
          body: {
            emptyDataSourceMessage: t('table:No records to display'),
            addTooltip: t('table:Add'),
            deleteTooltip: t('table:Delete'),
            editTooltip: t('table:Edit'),
            filterRow: {
              filterTooltip: t('table:Filter'),
            },
            editRow: {
              deleteText: t('table:Are you sure delete this row?'),
              cancelTooltip: t('table:Cancel'),
              saveTooltip: t('table:Save'),
            },
          },
          grouping: {
            placeholder: t('table:placeholder'),
          },
          header: {
            actions: t('table:Actions'),
          },
          pagination: {
            labelDisplayedRows: t('table:{from}-{to} of {count}'),
            labelRowsSelect: t('table:rows'),
            labelRowsPerPage: t('table:Rows per page'),
            firstAriaLabel: t('table:First Page'),
            firstTooltip: t('table:First Page'),
            previousAriaLabel: t('table:Previous Page'),
            previousTooltip: t('table:Previous Page'),
            nextAriaLabel: t('table:Next Page'),
            nextTooltip: t('table:Next Page'),
            lastAriaLabel: t('table:Last Page'),
            lastTooltip: t('table:Last Page'),
          },
          toolbar: {
            addRemoveColumns: t('table:Add or remove columns'),
            nRowsSelected: t('table:{0} row(s) selected'),
            showColumnsTitle: t('table:Show Columns'),
            showColumnsAriaLabel: t('table:Show Columns'),
            exportTitle: t('table:Export'),
            exportAriaLabel: t('table:Export'),
            exportName: t('table:Export as CSV'),
            searchTooltip: t('table:Search'),
            searchPlaceholder: t('table:Search'),
          },
        }}
        onFilterChange={onFilterChange}
        onOrderChange={onOrderChange}
        onSearchChange={onSearchChange}
        onColumnDragged={onColumnDragged}
        onChangeColumnHidden={onChangeColumnHidden}
      />
    </Box>
  );
}
