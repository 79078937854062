import React from 'react';
import Alert from '@material-ui/lab/Alert';

import ReportError from '../../../model/report-error';

const ErrorSensorSetDate = ({error}) => {
  return (
    <Alert title={error.message} severity="error">
      {error.message}
    </Alert>
  );
};

ErrorSensorSetDate.propTypes = {
  error: ReportError.propType,
};

export default ErrorSensorSetDate;