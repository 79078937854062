import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Route} from 'react-router-dom';

import {a11yProps, TabPanel} from '../components/TabPanel';
import Building from './Building';
import {Paper, Tab, Tabs} from '@material-ui/core';
import BuildingsTable from './Buildings/BuildingsTable';

const Content = ({match}) => {
  const {t} = useTranslation(['building']);
  const [activeType, setActiveType] = useState(0);
  return (
    <React.Fragment>
      <Paper position="static">
        <Tabs
          value={activeType}
          onChange={(event, newValue) => {
            setActiveType(newValue);
          }}>
          <Tab label={t('building:Main buildings')} {...a11yProps(0)} />
          <Tab label={t('building:Marked Wrong')} {...a11yProps(1)} />
        </Tabs>
      </Paper>

      <TabPanel value={activeType} index={0}>
        <BuildingsTable match={match} deleted={false} upload={true} />
      </TabPanel>
      <TabPanel value={activeType} index={1}>
        <BuildingsTable match={match} deleted={true} upload={false} />
      </TabPanel>
    </React.Fragment>
  );
};

export default function Buildings({match}) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/:id`} component={Building} />
    </React.Fragment>
  );
}
