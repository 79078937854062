import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import {Box, Popover, Typography} from '@material-ui/core';

const RESOLVE_CONFLICTS = gql`
  mutation resolveConflicts($reportId: ID!) {
    resolveConflicts(reportId: $reportId)
  }
`;

const ResolveButton = ({reportId}) => {
  const {t} = useTranslation(['report']);
  const [resolveConflicts] = useMutation(RESOLVE_CONFLICTS);
  const [processedElements, setProcessedElements] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const id = isOpen ? 'data-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        size="small"
        color="primary"
        onClick={async () => {
          const exec = await resolveConflicts({
            variables: {
              reportId,
            },
          });
          const result = exec.data?.resolveConflicts;
          if (!result || !result.success || !result.results.length) {
            setProcessedElements(0);
            setIsOpen(true);
            return;
          }
          setProcessedElements(result.results.length);
          setIsOpen(true);
        }}>
        {t('report:Automatic resolve')}
      </Button>
      <Popover
        id={id}
        open={isOpen}
        anchorReference="anchorPosition"
        anchorPosition={{top: 999, left: 20}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setIsOpen(false);
        }}>
        <Box p={2}>
          <Typography>
            {processedElements
              ? t('report:Resolved conflicts for {{number}} sensors', {
                  number: processedElements,
                })
              : t('report:No conflicts which can be resolved automatically')}
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};
ResolveButton.propTypes = {
  reportId: PropTypes.number.isRequired,
};
export default ResolveButton;
