import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from 'react';
import { useTranslation } from "react-i18next";
import Table from "../components/Table";
import { TelegramDetailPanel } from "../components/TelegramDetailPanel";

const GET_TELEGRAM = gql`
  query getTelegram($id: ID!) {
    telegram(id: $id) {
      id
      measurements {
        date
        value
        unit
      }
      content
      decrypted
      errorDescription
      header {
        L
        C
        CI
        serial
        manufacturer
        status
        version
        accNr
        decryption
        device
        device_text
        configuration
      }
    }
  }
`;

export default function Telegram({ match }) {
  const { t } = useTranslation(['telegram', 'measurement']);
  const { loading, error, data } = useQuery(GET_TELEGRAM, {
    variables: {
      id: match.params.id,
    },
  });

  if (error) {
    return <div>Error</div>;
  }

  const telegram = loading ? {} : data.telegram;

  if (!telegram) {
    return <div>{t('telegram:Telegram not found')}</div>;
  }

  return (
    <>
      <Table
        title={t('telegram:Measurements')}
        tableId={'telegram-measurements'}
        isLoading={loading}
        columns={[
          {
            title: t('measurement:Date'),
            field: 'date',
            defaultSort: 'desc',
          },
          {
            title: t('measurement:Value'),
            field: 'value',
          },
          {
            title: t('measurement:Unit'),
            field: 'unit',
          },
        ]}
        data={loading ? [] : telegram.measurements}
      />
      <TelegramDetailPanel telegram={telegram} t={t}/>
    </>
  );
}