import React from 'react';
import moment from 'moment';
import {Dialog, DialogTitle, makeStyles} from '@material-ui/core';
import MaterialTable from 'material-table';
import {PaperLineChart} from 'react-materialui-charts';

const useStyles = makeStyles((theme) => ({
  dataBox: {
    margin: '1rem 4rem',
    border: '1px solid #FAFAFA',
  },
  dialog: {maxWidth: '98%'},
}));
const r = (value) => Math.round(value * 100) / 100;
const DataBox = ({
  type,
  stats,
  prevMonthStats,
  prevYearStats,
  buildingStats,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.dataBox}>
      Type: {type}
      <hr />
      Total: {r(stats.totalDelta)}{' '}
      {Object.values(stats.entities)[0].measurements[0].unit}
      <hr />
      Building AVG: {r(
        buildingStats.totalDelta / buildingStats.apartments,
      )}{' '}
      {Object.values(stats.entities)[0].measurements[0].unit}
      <hr />
      <MaterialTable
        columns={[
          {title: 'Sensor name', field: 'name'},
          {title: 'Consumption', field: 'normalizedDelta'},
          {title: 'Consumption Last Month', field: 'lastMonth'},
          {title: 'Consumption Last Year', field: 'lastYear'},
        ]}
        data={Object.values(stats.entities).map((entity) => {
          return {
            name: entity.Sensor.name || entity.Sensor.external_number,
            normalizedDelta: entity.normalizedDelta,
            lastMonth:
              prevMonthStats.entities[entity.Sensor.id].normalizedDelta,
            lastYear: prevYearStats.entities[entity.Sensor.id].normalizedDelta,
          };
        })}
        options={{
          sorting: false,
          search: false,
          toolbar: false,
          paging: false,
        }}
      />
      <div>
        {Object.values(stats.entities).map((rowData) => {
          return (
            <div key={rowData.Sensor.id}>
              <PaperLineChart
                height={150}
                title={rowData.Sensor.name || rowData.Sensor.external_number}
                value={`${r(rowData.normalizedDelta)} ${rowData.measurements[0].unit}`}
                data={rowData.measurements}
                dataLines={['value']}
                chartColor={['#f50057']}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default function ReportPreview(props) {
  const {onClose, open, report} = props;
  const handleClose = () => {
    onClose();
  };
  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open} classes={{paper: classes.dialog}}>
      <DialogTitle>
        Report {moment.utc(report.start).format('MMMM YYYY')}
      </DialogTitle>
      {Object.keys(report.stats || {}).map((key) => (
        <DataBox
          key={key}
          type={key}
          stats={report.stats[key]}
          prevMonthStats={report.prevMonthStats[key]}
          prevYearStats={report.prevYearStats[key]}
          buildingStats={report.buildingStats[key]}
        />
      ))}
    </Dialog>
  );
}
