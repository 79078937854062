import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from '../../components/Table';

const GET_SONEXA_API_CONFIGS = gql`
  query getIntegrationSonexaConfigs($ids: [ID], $type: Int!) {
    integrationSonexaConfigs(ids: $ids, type: $type) {
      id
      type
    }
  }
`;

const ADD_SONEXA_API_CONFIG = gql`
  mutation addIntegrationSonexaConfig(
    $value: String!
    $type: Int!
  ) {
    addIntegrationSonexaConfig(
      value: $value
      type: $type
    ) {
      id
      type
    }
  }
`;

const UPDATE_SONEXA_API_CONFIG = gql`
  mutation updateIntegrationSonexaConfig(
    $id: ID!
    $value: String
    $type: Int!
  ) {
    updateIntegrationSonexaConfig(
      id: $id
      value: $value
      type: $type
    ) {
      id
      type
    }
  }
`;

const DELETE_SONEXA_API_CONFIG = gql`
  mutation deleteIntegrationSonexaConfig($id: ID!) {
    deleteIntegrationSonexaConfig(id: $id) {
      id
    }
  }
`;

const SonexaViewTable = ({ configType, tableId }) => {
  const { t } = useTranslation(['integrationConfig']);
  let type;
  switch (configType) {
    case 'sonexa':
      type = 0;
      break;
    case 'ei':
      type = 1;
      break;
    default: throw new Error('Unexpected Sonexa config type');
  }
  const { loading, error, data } = useQuery(GET_SONEXA_API_CONFIGS, {
    variables: {
      type,
    },
  });
  const [updateIntegrationSonexaConfig] = useMutation(UPDATE_SONEXA_API_CONFIG);
  const [addIntegrationSonexaConfig] = useMutation(ADD_SONEXA_API_CONFIG, {
    update: (cache, { data: { addIntegrationSonexaConfig: add } }) => {
      const { integrationSonexaConfigs } = cache.readQuery({
        query: GET_SONEXA_API_CONFIGS,
        variables: {
          type,
        },
      });

      cache.writeQuery({
        query: GET_SONEXA_API_CONFIGS,
        variables: {
          type,
        },
        data: {
          integrationSonexaConfigs: integrationSonexaConfigs.concat(
            add,
          ),
        },
      });
    },
  });
  const [deleteIntegrationSonexaConfig] = useMutation(DELETE_SONEXA_API_CONFIG, {
    update: (cache, { data: { deleteIntegrationSonexaConfig: del } }) => {
      const { integrationSonexaConfigs } = cache.readQuery({
        query: GET_SONEXA_API_CONFIGS,
        variables: {
          type,
        },
      });

      cache.writeQuery({
        query: GET_SONEXA_API_CONFIGS,
        variables: {
          type,
        },
        data: {
          integrationSonexaConfigs: integrationSonexaConfigs.filter(
            (integrationFtpConfig) =>
              integrationFtpConfig.id !== del.id,
          ),
        },
      });
    },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('integrationConfig:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {
      title: t('integrationConfig:Api Key'),
      field: 'value',
      render: () => '*************',
    },
  ];

  return (
    <React.Fragment>
      <Table
        tableId={tableId}
        title=""
        columns={columns}
        data={data.integrationSonexaConfigs}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addIntegrationSonexaConfig({
              variables: {
                value: newData.value,
                type,
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            await updateIntegrationSonexaConfig({
              variables: {
                id: oldData.id,
                value: newData.value,
                type,
              },
            });
          },
          onRowDelete: async (oldData) => {
            await deleteIntegrationSonexaConfig({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default SonexaViewTable;
