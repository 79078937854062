import React from 'react';
import IfenaLink from '../Link';
import TypeUser from './Type';

const Link = (props) => {
  const {user} = props;
  const {first_name, last_name} = user.profile || {};
  const {email, id} = user;
  const text = [first_name, last_name].filter((a) => a).join(' ') || email;
  return (
    <IfenaLink title={email} to={`/users/${id}`}>
      {text}
    </IfenaLink>
  );
};

Link.propTypes = {
  user: TypeUser.isRequired,
};
export default Link;
