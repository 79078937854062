import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import React from "react";

import Table from "../../components/Table";

export default function BuildingApartments({ classes, building, t }) {
  return building.id ? (
    <Table
      title={
        <div>
          <MeetingRoomIcon className={classes.titleIcon} />
          {t('building:Apartments')}
        </div>
      }
      tableId={`building-${building.id}-apartments`}
      columns={[
        {
          title: t('building:ID'),
          field: 'id',
          type: 'numeric',
          defaultSort: 'desc',
        },
        { title: t('building:External Number'), field: 'external_number' },
        { title: t('building:Name'), field: 'external_name' },
        { title: t('building:Name2'), field: 'external_name2' },
        { title: t('building:Location'), field: 'location' },
        {
          title: t('building:Area Heating'),
          field: 'area_heating',
          type: 'numeric',
        },
        {
          title: t('building:Area Water'),
          field: 'area_water',
          type: 'numeric',
        },
        { title: t('building:Comment'), field: 'comment' },
      ]}
      data={building.apartments}
      options={{
        sorting: true,
        search: true,
        toolbar: true,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25],
      }}
    />
  ) : null;
}