import React from 'react';
import useReactRouter from 'use-react-router';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const DELETE_BUILDING = gql`
  mutation deleteBuilding($buildingId: ID!) {
    deleteBuilding(buildingId: $buildingId)
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tableToolbar: {
    flexGrow: 1,
  },
  titleIcon: {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  refreshButton: {
    marginRight: 20,
  },
  deleteButton: {
    marginRight: 20,
    backgroundColor: '#BB3030',
    '&:hover': {
      backgroundColor: 'rgb(130,33,33)',
    },
  },
}));
const DeleteBuilding = ({building}) => {
  const {t} = useTranslation(['building']);
  const classes = useStyles();
  const {history} = useReactRouter();
  const [state, setState] = React.useState({open: false, error: null});
  const [deleteBuilding] = useMutation(DELETE_BUILDING);

  const handleClickOpen = () => {
    setState({open: true, error: null});
  };
  const handleClose = () => {
    setState({open: false, error: null});
  };
  const handleSubmit = async () => {
    try {
      await deleteBuilding({
        variables: {
          buildingId: building.id,
        },
      });
    } catch (err) {
      setState({open: true, error: err.message});
      return;
    }
    history.push('/buildings');
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.deleteButton}
        onClick={handleClickOpen}>
        {t('building:Delete')}
      </Button>
      <Dialog open={state.open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {state.error || t('building:The building is going to be deleted')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" autoFocus>
            {t('building:Cancel')}
          </Button>
          {!state.error && (
            <Button autoFocus onClick={handleSubmit} color="primary">
              {t('building:Proceed')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DeleteBuilding;
