import React, {useState, useEffect} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {ApolloProvider} from '@apollo/react-hooks';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {LocalStorageWrapper, CachePersistor} from 'apollo3-cache-persist';
import {HttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {ApolloLink} from 'apollo-link';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/styles';
import {createTheme} from '@material-ui/core/styles';
import {SnackbarProvider} from 'notistack';

import './lib/i18n';
import config from './config';
import {MeProvider, useMe, useMeQuery} from './reducers/me';
import history from './lib/history';
import Layout from './components/Layout';
import Login from './pages/Login';
import Reports from './pages/Reports';
import Buildings from './pages/Buildings';
import SensorKeys from './pages/SensorKeys';
import Gateways from './pages/Gateways';
import FtpConfigs from './pages/FtpConfigs';
import IntegrationConfigs from './pages/IntegrationConfigs';
import Operators from './pages/Operators';
import Customers from './pages/Customers';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Organizations from './pages/Organizations';
import Uvis from './pages/Uvis';
import OrganizationBilling from './pages/Organization/Billing';
import Dashboard from './pages/Dashboard';
import Telegrams from './pages/Telegrams';
import Devices from './pages/Devices';
import SharedTransferConfigs from './pages/SharedTransferConfigs';
import PasswordRecovery from './pages/PasswordRecovery';
import RecoveryCode from './pages/RecoveryCode';
import ChangePassword from './pages/ChangePassword';

const cache = new InMemoryCache();
const cachePersistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});
cachePersistor.restore();

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({message, locations, path, extensions}) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`,
          );

          if (extensions && extensions.code === 'UNAUTHENTICATED') {
            if (window.location.pathname !== '/login') {
              client.resetStore();
              // This route will clear some global state, then redirect to the Sign In route
              history.push('/login');
            }
          }
        });
      } else if (networkError) {
        console.error(`[Network error]: ${networkError}`);

        switch (networkError.statusCode) {
          case 401:
            client.resetStore();
            history.push('/login');
            break;
          default:
            break;
        }
      }
    }),
    new HttpLink({
      uri: config.apiUri,
      credentials: 'include',
    }),
  ]),
  cache: cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const originalCacheWrite = cache.write;
cache.write = (...args) => {
  // Intercepting write function call is needed to catch those
  // types of errors because onError link ignores them
  try {
    return originalCacheWrite(...args);
  } catch (err) {
    if (err.type === 'WriteError') {
      // Clear the cache if error occured during writing to it because
      // those types of errors can block displaying new data until the cache is cleared
      cachePersistor.purge().then(() => {
        window.location.reload();
      });
    }
    throw err;
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#3075BB',
    },
  },
});

// This component exists only because there is a bug with onCompleted
// and calling the dispatch inside causing an infinite loop.
// So the workaround is to save in a state and then saving the state into
// the reducer.
const Me = () => {
  const [state, setData] = useState({});
  const [skipQuery, setSkipQuery] = useState(false);
  const [, meDispatch] = useMe();
  useMeQuery(meDispatch, {
    skip: skipQuery,
    onCompleted: (meData) => {
      setSkipQuery(true);
      setData(meData);
    },
  });

  useEffect(() => {
    if (state && state.me) {
      meDispatch({
        type: 'SAVE_ME',
        payload: state.me,
      });
    }
  }, [state, meDispatch]);

  return null;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MeProvider>
            <SnackbarProvider>
              <Router history={history}>
                <Switch>
                  <Route exact path={['/', '/login', '/password_recovery',
                                      '/recovery_code', '/change_password']}>
                    <Layout showDrawer={false} showAppBar={false}>
                    <Route exact path='/password_recovery' component={PasswordRecovery} />
                    <Route exact path='/recovery_code' component={RecoveryCode} />
                    <Route exact path='/change_password' component={ChangePassword} />
                      <Route exact path={['/', '/login']} component={Login} />
                    </Layout>
                  </Route>
                  <Route
                    path={[
                      '/dashboard',
                      '/reports',
                      '/organizations',
                      '/buildings',
                      '/sensor-keys',
                      '/gateways',
                      '/ftp-configs',
                      '/integration-configs',
                      '/operators',
                      '/uvis',
                      '/customers',
                      '/users',
                      '/settings',
                      '/billing',
                      '/telegrams',
                      '/devices',
                      '/shared-transfer-configs',
                    ]}>
                    <Me />
                    <Layout showDrawer showAppBar>
                      <Route path="/dashboard" component={Dashboard} />
                      <Route path="/reports" component={Reports} />
                      <Route path="/uvis" component={Uvis} />
                      <Route path="/organizations" component={Organizations} />
                      <Route path="/buildings" component={Buildings} />
                      <Route path="/sensor-keys" component={SensorKeys} />
                      <Route path="/gateways" component={Gateways} />
                      <Route path="/ftp-configs" component={FtpConfigs} />
                      <Route
                        path="/integration-configs"
                        component={IntegrationConfigs}
                      />
                      <Route path="/operators" component={Operators} />
                      <Route path="/customers" component={Customers} />
                      <Route path="/users" component={Users} />
                      <Route path="/settings" component={Settings} />
                      <Route path="/billing" component={OrganizationBilling} />
                      <Route path="/telegrams" component={Telegrams} />
                      <Route path="/devices" component={Devices} />
                      <Route path="/shared-transfer-configs" component={SharedTransferConfigs} />
                    </Layout>
                  </Route>
                </Switch>
              </Router>
            </SnackbarProvider>
          </MeProvider>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;
