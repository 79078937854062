import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@material-ui/core';
import IntegrationFtpConfigModel from '../model/integration-ftp-config';

import { a11yProps, TabPanel } from '../components/TabPanel';
import FtpViewTable from './IntegrationConfigs/FtpViewTable';
import SonexaViewTable from './IntegrationConfigs/SonexaViewTable';
import ApiViewTable from './IntegrationConfigs/ApiViewTable';


const allowedConfigs = [
  {
    id: IntegrationFtpConfigModel.TYPE_SK_SOFT_EXPORT,
    label: 'SK-Soft Export',
    ConfigTable: FtpViewTable,
    tableId: "sk-soft-export-ftp-configs",
  },
  {
    id: IntegrationFtpConfigModel.TYPE_SK_SOFT_IMPORT,
    label: 'SK-Soft Import',
    ConfigTable: FtpViewTable,
    tableId: "sk-soft-import-ftp-configs",
  },
  {
    id: "sonexa",
    label: 'Sonexa Key Import',
    ConfigTable: SonexaViewTable,
    tableId: "sonexa-key-import-ftp-configs",
  },
  {
    id: "ei",
    label: 'EI Key Import',
    ConfigTable: SonexaViewTable,
    tableId: "ei-key-import-ftp-configs",
  },
  {
    id: "1nce",
    label: '1NCE API',
    ConfigTable: ApiViewTable,
    tableId: "1nce-api-integration-configs",
  },
];

const Content = ({ match }) => {
  const { t } = useTranslation(['integrationConfig']);
  const [activeType, setActiveType] = useState(0);

  return (
    <React.Fragment>
      <Paper position="static">
        <Tabs
          value={activeType}
          onChange={(event, newValue) => {
            setActiveType(newValue);
          }}>
          {allowedConfigs.map(({ label }, index) => (
            <Tab label={t(label)} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </Paper>
      {allowedConfigs.map(({ id, ConfigTable, tableId }, index) => (
        <TabPanel value={activeType} index={index} key={index}>
          <ConfigTable configType={id} tableId={tableId} />
        </TabPanel>
      ))}
    </React.Fragment>
  );
};

export default function IntegrationConfigs({ match }) {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Content} />
    </React.Fragment>
  );
}
