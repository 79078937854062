import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Switch} from 'react-router-dom';
import { Paper, Tabs, Tab } from '@material-ui/core';

import Gateway from './Gateway';
import GatewaysBacklog from './Gateways/GatewaysBacklog';
import GatewaysInOperation from './Gateways/GatewaysInOperation';

const Content = ({match}) => {
  const [visibleTabIndex, setVisibleTabIndex] = useState(0);
  const {t} = useTranslation(['gateway']);

  const onTabChange = (event, tabIndex) => {
    setVisibleTabIndex(tabIndex);
  };

  return (
    <Paper>
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={visibleTabIndex}
        onChange={onTabChange}>
        <Tab label={t('gateway:Gateways in Operations')} />
        <Tab label={t('gateway:Gateways Backlog')} />
      </Tabs>

      {visibleTabIndex === 0 ? <GatewaysInOperation match={match} /> : null}
      {visibleTabIndex === 1 ? <GatewaysBacklog match={match}  /> : null}
    </Paper>
  );
};

export default function Gateways({ match }) {
  return (
    <Switch>
      <Route exact path={match.path} component={Content} />
      <Route path={`${match.path}/new`} component={Gateway} />
      <Route path={`${match.path}/:id`} component={Gateway} />
    </Switch>
  );
}
