import React, {useState} from 'react';
import {useApolloClient} from '@apollo/react-hooks';
import clsx from 'clsx';
import useReactRouter from 'use-react-router';
import {useTranslation} from 'react-i18next';
import CssBaseline from '@material-ui/core/CssBaseline';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import config from '../config';
import history from '../lib/history';
import Link from '../components/Link';
import {useMe} from '../reducers/me';
import UserModel from '../model/user';

import LayoutCommon from './Layout/Common';
import LayoutAdmin from './Layout/Admin';
import LayoutManager from './Layout/Manager';
import AppBarTitle from './AppBarTitle';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuItem: {
    color: 'inherit',
    'text-decoration': 'none',
    '&:hover': {
      'text-decoration': 'none',
    },
  },
}));

export default function Layout({
  children,
  showDrawer = true,
  showAppBar = true,
}) {
  const client = useApolloClient();
  const [useMeData, meDispatch] = useMe();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const {location} = useReactRouter();
  const {t} = useTranslation(['translations', 'layout']);
  const me = useMeData || {status: null};
  const anchorRef = React.useRef(null);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleMenuToggle() {
    setMenuOpen((prevOpen) => !prevOpen);
  }

  function handleMenuClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setMenuOpen(false);
  }

  const onLogoutClick = (client, dispatch) => async (event) => {
    const response = await fetch(`${config.apiUri}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      console.error('Could not logout', await response.json());
      return;
    }

    handleMenuClose(event);
    client.cache.reset();
    dispatch({type: 'RESET'});
    history.push('/login');
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(menuOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && menuOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [menuOpen, open]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {showAppBar && (
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}>
              <MenuIcon />
            </IconButton>
            <AppBarTitle location={location} organizationId={me.organizationId} />
            <div>
              <Button
                color="inherit"
                ref={anchorRef}
                aria-controls={menuOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleMenuToggle}>
                {t('layout:Menu')}
              </Button>
              <Popper
                open={menuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({TransitionProps, placement}) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleMenuClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                          <Link
                            to="/settings"
                            onClick={handleMenuClose}
                            className={clsx(classes.menuItem)}>
                            <MenuItem>{t('layout:Settings')}</MenuItem>
                          </Link>
                          <MenuItem onClick={onLogoutClick(client, meDispatch)}>
                            {t('layout:Logout')}
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Toolbar>
        </AppBar>
      )}
      {showDrawer && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}>
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            <Divider />
            {UserModel.roleUser(me.role) && <LayoutCommon />}
            {UserModel.roleManager(me.role) && <LayoutManager />}
            {UserModel.roleAdmin(me.role) && <LayoutAdmin />}
          </List>
        </Drawer>
      )}
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </div>
  );
}
