import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import useForm from 'react-hook-form';
import useReactRouter from 'use-react-router';

import logo from '../assets/smarterbuilding_logo_web.png';
import config from '../config';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 300,
  },
  backBtn: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
	infoText: {
	margin: theme.spacing(2, 0, 1),
	},
}));

const onSubmit = (t, setLoading, history) => async (data) => {
	setLoading(true);
	const response = await fetch(`${config.apiUri}/password-recovery-request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    credentials: 'include',
  });

  if (!response.ok) {
		setLoading(false);
    return;
  }

  setLoading(false);
  history.push('/recovery_code', data);
};


export default function PasswordRecovery() {
  const classes = useStyles();
  const {t} = useTranslation(['passwordRecovery']);
  const {register, handleSubmit, errors} = useForm();
  const {history} = useReactRouter();
  const [isLoading, setLoading] = useState(false);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box mb={1}>
          <img className={classes.logo} src={logo} alt="Logo" />
        </Box>
        <Typography component="h1" variant="h5">
          {t('passwordRecovery:Password recovery')}
        </Typography>
        <div className={classes.infoText}>
          {t('passwordRecovery:EMAIL_FORM_INFO')}
        </div>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleSubmit(onSubmit(t, setLoading, history))}>
          <TextField
            required
            fullWidth
            autoFocus
            variant='outlined'
            margin='normal'
            id='email'
            label={t('login:E-mail Address')}
            name='email'
            autoComplete='email'
            inputRef={register({required: t("login:E-mail can't be blank")})}
          />
          {errors.email && errors.email.message}
          <Button
            fullWidth
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}>
            {t('passwordRecovery:Send recovery code')}
          </Button>
        </form>
      </div>
    </Container>
  );
}
