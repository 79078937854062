import PropTypes from 'prop-types';
export const TypeUser = PropTypes.shape({
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    postal_code: PropTypes.string,
    city: PropTypes.string,
  }),
});

export const TypePermission = PropTypes.shape({
  id: PropTypes.string.isRequired,
  user: TypeUser,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
});

export default TypeUser;
