// @format
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';

const getApartments = (building) => building.apartments || [];

const getTenants = createSelector([getApartments], (apartments) =>
  apartments.filter((apartment) => (apartment.permissions || []).length),
);

const countMissingTenants = createSelector(
  [getApartments, getTenants],
  (apartments, tenants) => apartments.length - tenants.length,
);

const fullAddress = (street, number, zipCode, city) => {
  return `${street} ${number}, ${zipCode} ${city}`;
};

const label = (building) => {
  const full = `${fullAddress(
    building.street,
    building.house_number,
    building.postalcode,
    building.city,
  )}`;
  const external = building.external_id ? ` (${building.external_id})` : '';
  return `${building.id}${external} | ${full}`;
};

const BuildingType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  street: PropTypes.string.isRequired,
  house_number: PropTypes.string.isRequired,
  postalcode: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
});

export default {
  label,
  fullAddress,
  countMissingTenants,
  getTenants,
  getApartments,
  propType: BuildingType,
};
