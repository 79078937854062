import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  cardHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  paragraph: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
}));

export default function DashboardCard({ title, children }) {
  const classes = useStyles();

  return (
    <Card className={classes.card} style={{height: '100%'}}>
      <CardHeader title={title} className={classes.cardHeader} />
      <CardContent className={classes.cardContent}>
          {children}
      </CardContent>
    </Card>
  );
}
