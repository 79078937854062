import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Table from '../components/Table';

const useStyles = makeStyles((theme) => ({
  rawTelegram: {
    wordBreak: 'break-all',
  },
}));

const getParsedValue = (row) => {
  let { value, unit, type } = row;

  if (unit.startsWith("MeasureUnit.")) {
    unit = unit.slice(unit.indexOf(".") + 1);
  }

  if (
    type.startsWith("VIFUnit.") ||
    type.startsWith("VIFUnitExt.") ||
    type.startsWith("VIFUnitSecExt.")
  ) {
    type = type.slice(type.indexOf(".") + 1);
  }

  return {
    value,
    unit,
    type,
  };
};

const getHeaderField = (header, key, errorDescription) => {
  let value = header[key];
  if (!value) {
    return;
  }

  if (key === 'status') {
    const statusDescription = ` (${errorDescription ? errorDescription : 'OK'})`;
    return {
      key,
      value: value + statusDescription,
    };
  }

  return {
    key,
    value,
  };
};

const getHeaderFields = (telegram) => {
  const { header, errorDescription } = telegram;
  const headerKeys = Object.keys(header).filter(
    (key) => {
      return key !== '__typename'; // ignore key added by graphql
    },
  );

  return headerKeys.map((key) => {
    return getHeaderField(header, key, errorDescription);
  }).filter((row) => !!row);
};

/** 
 * Some telegrams have some header fields stored not inside header but inside of
 * manufacturer specific record, for example Qundis PDW telegram. Fields in 
 * telegram.header contain only fields from the header. Other fields can be extracted
 * from telegram.decrypted object which contains decrypted telegram.
 */
const getManufacturerHeaderFields = (telegram) => {
  const { decrypted, errorDescription } = telegram;
  const { header } = decrypted;

  if (header.manufacturer === 'QDS' && header.type === '0x78') {
    const fields = [];
    const status = getHeaderField(header, 'status', errorDescription);
    if (status?.value?.startsWith('0x')) {
      status.value = status.value.slice(2);
    }
    if (status) {
      fields.push(status);
    }

    const access_no = getHeaderField(header, 'access_no');
    if (access_no) {
      access_no.value = parseInt(access_no.value).toString(16).padStart(2, '0');
      fields.push(access_no);
    }

    return fields;
  }
};

export const TelegramDetailPanel = ({ telegram, t }) => {
  const classes = useStyles();

  if (!telegram.decrypted) {
    return (
      <Box
        mt={4}
        mb={4}
        display="flex"
        justifyContent="center"
        alignItems="center">
        {t('telegram:Telegram is not decrypted')}
      </Box>
    );
  }

  const values = telegram.decrypted?.records?.map((row) => {
    return getParsedValue(row);
  }) || [];

  const header = getHeaderFields(telegram);
  const manufacturerHeader = getManufacturerHeaderFields(telegram);

  return (
    <Box>
      <Table
        title={t('telegram:Header')}
        tableId={'telegram-header'}
        data={header}
        options={{
          toolbar: true,
          paging: false,
          filtering: false,
          search: false,
        }}
        columns={[
          {
            title: t('telegram:Key'),
            field: 'key',
          },
          {
            title: t('telegram:Value'),
            field: 'value',
          },
        ]}
      />
      {
        manufacturerHeader &&
        <Table
          title={t('telegram:Manufacturer-Specific Header')}
          tableId={'telegram-manufacturer-header'}
          data={manufacturerHeader}
          options={{
            toolbar: true,
            paging: false,
            filtering: false,
            search: false,
          }}
          columns={[
            {
              title: t('telegram:Value'),
              field: 'value',
            },
            {
              title: t('telegram:Key'),
              field: 'key',
            },
          ]}
        />
      }
      <Table
        title={t('telegram:Values')}
        tableId={'telegram-values'}
        data={values}
        options={{
          toolbar: true,
          filtering: false,
          pageSize: 10,
          search: false,
        }}
        columns={[
          {
            title: t('telegram:Value'),
            field: 'value',
          },
          {
            title: t('telegram:Type'),
            field: 'type',
          },
          {
            title: t('telegram:Unit'),
            field: 'unit',
          },
        ]}
      />
      <Box mb={2} className={classes.rawTelegram}>
        Raw: {telegram.content}
      </Box>
    </Box>
  );
};
