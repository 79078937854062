import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import useForm from 'react-hook-form';
import useReactRouter from 'use-react-router';
import {useSnackbar} from 'notistack';

import logo from '../assets/smarterbuilding_logo_web.png';
import config from '../config';

const MIN_PASSWORD_LEN = 6;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 300,
  },
  backBtn: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
	infoText: {
	margin: theme.spacing(2, 0, 1),
	},
}));

const onSubmit = (t, email, code, setLoading, enqueueSnackbar, history) => async (data) => {
	setLoading(true);
  const {password1: password} = data;
	const response = await fetch(`${config.apiUri}/change-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email, code, password}),
    credentials: 'include',
  });

  const json = await response.json();

  if (!json.success) {
		setLoading(false);
    enqueueSnackbar(t('passwordRecovery:PASSWORD_CHANGE_ERROR'), {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
    return;
  }

	setLoading(false);

  enqueueSnackbar(t('passwordRecovery:Password successfully changed'), {
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  });

	history.push('/login');
};

const validatePasswords = (t, password1, password2) => {
  if (password1 !== password2)
    return t("passwordRecovery:Passwords doesn't match");

  if (password1.length < 6)
    return t('login:Password must have more than 6 characters');

  return true;
};

export default function ChangePassword({location}) {
  const classes = useStyles();
  const {t} = useTranslation(['passwordRecovery']);
  const {register, handleSubmit, errors, watch} = useForm();
  const {history} = useReactRouter();
  const [isLoading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const {password1, password2} = watch(['password1', 'password2']);

	const {code, email} = location.state;

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Box mb={1}>
          <img className={classes.logo} src={logo} alt="Logo" />
        </Box>
        <Typography component="h1" variant="h5">
          {t('passwordRecovery:Password recovery')}
        </Typography>
				<div className={classes.infoText}>
					{t('passwordRecovery:CHANGE_PASSWORD_FORM_INFO').replace('{length}', MIN_PASSWORD_LEN)}
				</div>
				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit(onSubmit(t, email, code, setLoading, enqueueSnackbar, history))}>
					<TextField
						required
						fullWidth
						autoFocus
						variant="outlined"
						margin="normal"
						id="password1"
						label={t('passwordRecovery:New password')}
						name="password1"
						type="password"
						inputRef={
							register({
								validate: (val) => validatePasswords(t, password1, password2),
							})
						}
					/>
					<TextField
						required
						fullWidth
						variant="outlined"
						margin="normal"
						id="password2"
						label={t('passwordRecovery:Repeat new password')}
						name="password2"
						type="password"
						inputRef={
							register({
								validate: (val) => validatePasswords(t, password1, password2),
							})
						}
					/>
					{errors.password2 && errors.password2.message}
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						className={classes.submit}>
						{t('passwordRecovery:Change password')}
					</Button>
				</form>
      </div>
    </Container>
  );
}
