import { useMutation, useQuery } from '@apollo/react-hooks';
import LinearProgress from '@material-ui/core/LinearProgress';
import gql from 'graphql-tag';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../components/Table';

const GET_API_CREDENTIALS = gql`
  query getIntegrationApiCredentials($ids: [ID], $type: String!) {
    integrationApiCredentials(ids: $ids, type: $type) {
      id
      username
      type
    }
  }
`;

const ADD_API_CREDENTIALS = gql`
  mutation addIntegrationApiCredentials(
    $username: String!
    $password: String!
    $type: String!
  ) {
    addIntegrationApiCredentials(
      username: $username
      password: $password
      type: $type
    ) {
      id
      username
      type
    }
  }
`;

const UPDATE_API_CREDENTIALS = gql`
  mutation updateIntegrationApiCredentials(
    $id: ID!
    $username: String!
    $password: String
    $type: String!
  ) {
    updateIntegrationApiCredentials(
      id: $id
      username: $username
      password: $password
      type: $type
    ) {
      id
      username
      type
    }
  }
`;

const DELETE_API_CREDENTIALS = gql`
  mutation deleteIntegrationApiCredentials($id: ID!) {
    deleteIntegrationApiCredentials(id: $id) {
      id
    }
  }
`;

const ApiViewTable = ({ configType, tableId }) => {
  const { t } = useTranslation(['integrationConfig']);
  let type;
  switch (configType) {
    case '1nce':
      type = '1nce';
      break;
    default: throw new Error('Unexpected API config type');
  }
  const { loading, error, data } = useQuery(GET_API_CREDENTIALS, {
    variables: {
      type,
    },
  });
  const [updateIntegrationApiCredentials] = useMutation(UPDATE_API_CREDENTIALS);
  const [addIntegrationApiCredentials] = useMutation(ADD_API_CREDENTIALS, {
    update: (cache, { data: { addIntegrationApiCredentials: add } }) => {
      const { integrationApiCredentials } = cache.readQuery({
        query: GET_API_CREDENTIALS,
        variables: {
          type,
        },
      });

      cache.writeQuery({
        query: GET_API_CREDENTIALS,
        variables: {
          type,
        },
        data: {
          integrationApiCredentials: integrationApiCredentials.concat(
            add,
          ),
        },
      });
    },
  });
  const [deleteIntegrationApiCredentials] = useMutation(DELETE_API_CREDENTIALS, {
    update: (cache, { data: { deleteIntegrationApiCredentials: del } }) => {
      const { integrationApiCredentials } = cache.readQuery({
        query: GET_API_CREDENTIALS,
        variables: {
          type,
        },
      });

      cache.writeQuery({
        query: GET_API_CREDENTIALS,
        variables: {
          type,
        },
        data: {
          integrationApiCredentials: integrationApiCredentials.filter(
            (integrationFtpConfig) =>
              integrationFtpConfig.id !== del.id,
          ),
        },
      });
    },
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <div>Error</div>;
  }

  const columns = [
    {
      title: t('integrationConfig:ID'),
      field: 'id',
      type: 'numeric',
      defaultSort: 'desc',
      editable: 'never',
    },
    {
      title: t('integrationConfig:Username'),
      field: 'username',
    },
    {
      title: t('integrationConfig:Password'),
      field: 'password',
      render: () => '*************',
    },
  ];

  return (
    <React.Fragment>
      <Table
        tableId={tableId}
        title=""
        columns={columns}
        data={data.integrationApiCredentials}
        options={{
          sorting: true,
          pageSize: 10,
        }}
        editable={{
          onRowAdd: async (newData) => {
            await addIntegrationApiCredentials({
              variables: {
                username: newData.username,
                password: newData.password,
                type,
              },
            });
          },
          onRowUpdate: async (newData, oldData) => {
            await updateIntegrationApiCredentials({
              variables: {
                id: oldData.id,
                username: newData.username,
                password: newData.password,
                type,
              },
            });
          },
          onRowDelete: async (oldData) => {
            await deleteIntegrationApiCredentials({
              variables: {
                id: oldData.id,
              },
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default ApiViewTable;
