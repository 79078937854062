// @format
import React from 'react';
import { Link } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

export default function IfenaLink(props) {
  return (
    <MaterialLink {...props} component={AdapterLink}>
      {props.children}
    </MaterialLink>
  );
}
