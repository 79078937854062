import React from 'react';
import {useTranslation} from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeviceUnknownIncon from '@material-ui/icons/DeviceUnknown';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VPNKeyIcon from '@material-ui/icons/VpnKey';
import RouterIcon from '@material-ui/icons/Router';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DnsIcon from '@material-ui/icons/Dns';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PeopleIcon from '@material-ui/icons/People';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MessageIcon from '@material-ui/icons/Message';

import Link from '../../components/Link';

const Common = () => {
  const {t} = useTranslation(['translations', 'layout']);

  return (
    <React.Fragment>
      <Link to="/dashboard">
        <ListItem button key={t('layout:/dashboard')}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/dashboard')} />
        </ListItem>
      </Link>
      <Link to="/reports">
        <ListItem button key={t('layout:/reports')}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/reports')} />
        </ListItem>
      </Link>
      <Link to="/buildings">
        <ListItem button key={t('layout:/buildings')}>
          <ListItemIcon>
            <LocationCityIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/buildings')} />
        </ListItem>
      </Link>
      <Link to="/ftp-configs">
        <ListItem button key={t('layout:/ftp-configs')}>
          <ListItemIcon>
            <DnsIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/ftp-configs')} />
        </ListItem>
      </Link>
      <Link to="/integration-configs">
        <ListItem button key={t('layout:/integration-configs')}>
          <ListItemIcon>
            <CloudDownloadIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/integration-configs')} />
        </ListItem>
      </Link>
      <Link to="/sensor-keys">
        <ListItem button key={t('layout:/sensor-keys')}>
          <ListItemIcon>
            <VPNKeyIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/sensor-keys')} />
        </ListItem>
      </Link>
      <Link to="/gateways">
        <ListItem button key={t('layout:/gateways')}>
          <ListItemIcon>
            <RouterIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/gateways')} />
        </ListItem>
      </Link>
      <Link to="/operators">
        <ListItem button key={t('layout:/operators')}>
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/operators')} />
        </ListItem>
      </Link>
      <Link to="/customers">
        <ListItem button key={t('layout:/customers')}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/customers')} />
        </ListItem>
      </Link>
      <Link to="/billing">
        <ListItem button key={t('layout:/billing')}>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/billing')} />
        </ListItem>
      </Link>
      <Link to="/telegrams">
        <ListItem button key={t('layout:/telegrams')}>
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/telegrams')} />
        </ListItem>
      </Link>
      <Link to="/devices">
        <ListItem button key={t('layout:/devices')}>
          <ListItemIcon>
            <DeviceUnknownIncon />
          </ListItemIcon>
          <ListItemText primary={t('layout:/devices')} />
        </ListItem>
      </Link>
    </React.Fragment>
  );
};
export default Common;
