import React from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {MTableToolbar} from 'material-table';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';

import Table from './Table';
import { Card, CardContent } from '@material-ui/core';

const GET_WHITELIST_DATA = gql`
  query getWhitelistData(
    $gatewayId: ID!
  ) {
    whitelistData(
      gatewayId: $gatewayId
    ) {
      sensors {
        serialNumber
        manufacturer
      }
      uploaded_at
      status
    }
  }
`;

const RESET_WHITELIST = gql`
  mutation resetWhitelist(
    $gatewayId: ID!
  ) {
    resetWhitelist(
      gatewayId: $gatewayId
    )
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tableToolbarWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonsWrapper: {
    flex: 1,
  },
  controlButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const getWhitelistStatus = (t, whitelistData) => {
  switch (whitelistData?.status) {
    case 'uploaded':
      return t('gateway:uploaded to the ftp server until gateway will download and apply it');
    case 'uploaded_empty':
      return t('gateway:empty whitelist uploaded to the ftp server and waiting until gateway will download it to reset currect whitelist');
    case 'applied':
      return t('gateway:applied to the gateway');
    case 'reset':
      return t('gateway:empty whitelist applied to the gateway; waiting for grace period to pass');
    case 'pending':
      return t('gateway:pending whitelist upload');
    default:
      return t('gateway:not created');
  }
};

export const WhitelistDialog = ({gateway, onClose}) => {
  const {t} = useTranslation(['gateway']);
  const classes = useStyles();

  const {loading, error, data} = useQuery(GET_WHITELIST_DATA, {
    variables: {
      gatewayId: gateway.id,
    },
  });
  const refetchQueries = [
    {
      query: GET_WHITELIST_DATA,
      variables: {
        gatewayId: gateway.id,
      },
    },
  ];
  const [resetWhitelist, {loading: removeLoading}] = useMutation(RESET_WHITELIST, {
    refetchQueries,
  });

  if (loading) {
    return <LinearProgress />;
  }
  
  const handleResetWhitelist = () => {
    resetWhitelist({
      variables: {
        gatewayId: gateway.id,
      },
    });
  };    

  const {whitelistData} = data;
  const sensors = whitelistData?.sensors ?? [];

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={onClose}
      TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {`${gateway.id} ${t('gateway:Gateway whitelist')}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box m={2}>
        <Card p={2}>
          <CardContent>
            <Typography paragraph={true}>
              {t('gateway:Whitelist status')}: {getWhitelistStatus(t, whitelistData)}
            </Typography>
            {whitelistData?.status === '' && !!whitelistData?.pending_whitelist_serials?.length && (
              <div>
                <Alert title='Sensors to be added' severity='info'>
                  Sensors to be added: {whitelistData.pending_whitelist_serials.join(', ')}
                </Alert>
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
      <Box m={2}>
        <Table
            title=""
            columns={[
              {
                title: t('gateway:Sensor serial number'),
                field: 'serialNumber',
              },
              {
                title: t('gateway:Sensor manufacturer'),
                field: 'manufacturer',
              },
            ]}
            options={{
              toolbar: true,
              filtering: false,
              pageSize: 10,
            }}
            isLoading={loading || removeLoading}
            data={sensors}
            components={{
              Toolbar: (props) => (
                <div className={classes.tableToolbarWrapper}>
                  <Box
                    ml={2}
                    mt={4}
                    mb={2}
                    className={classes.buttonsWrapper}>
                      <Button
                        disabled={!whitelistData?.status}
                        color='primary'
                        variant='contained'
                        size='small'
                        className={classes.controlButton}
                        onClick={handleResetWhitelist}
                        >
                        {t('gateway:Remove whitelist')}
                      </Button>
                  </Box>


                  <MTableToolbar
                    {...props}
                    classes={{root: classes.tableToolbar}}
                  />
                </div>
              ),
            }}            
          />
      </Box>
    </Dialog>
  );
};
